import {
  Button,
  Icon,
  Modal,
  Row,
  SearchBar,
  Spinner,
  Text,
} from "app/components";
import { allBlocks, getPixels, truncateText } from "app/utils/utils";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, snakeCase } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AdminWrapper from "../home/AdminWrapper";
import { Label } from "../home/Pages";
import ShowExamples from "./Examples";
import { Tooltip } from "react-tooltip";
import { apiRequest } from "app/utils/apiRequests";
import availableBlocks from "./availableBlocks.json";
import availableFeatures from "./availableFeatures.json";
import { colors } from "app/utils/theme";
import moment from "moment";
import styled from "styled-components";

const Planner = () => {
  const params = useParams();
  const planId = get(params, "id");

  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  const [showPast, setShowPast] = useState(false);

  const [appPlans, setAppPlans] = useState([]);

  useEffect(() => {
    apiRequest.get("/app_plan/").then((r) => {
      const plans = get(r, "data", []);
      setAppPlans(plans);
    });
  }, []);

  const plan = appPlans.find((p) => p.id === parseInt(planId));
  const planData = get(plan, "response");

  return (
    <AdminWrapper>
      {showPast && (
        <PastProjectPlans
          appPlans={appPlans}
          hide={() => setShowPast(false)}
          onSelect={(planId) => navigate(`/planner/${planId}`)}
        />
      )}

      <Row justifyContent="space-between">
        <Label>{plan ? get(planData, "name") : "App Planner"}</Label>

        <Row justifyContent="space-between" gap="10px">
          {plan && (
            <Button
              data={{
                text: "Create Another Plan",
                onClick: () => {
                  setDescription("");
                  navigate("/planner");
                },
                type: "basic",
                icon: "FiRefreshCcw",
              }}
            />
          )}

          <Button
            data={{
              text: "View Previous Plans",
              onClick: () => setShowPast(true),
              type: "basic",
            }}
          />
        </Row>
      </Row>

      {planData && <RenderPlan plan={planData} />}
      {!planData && (
        <Setup
          description={description}
          setDescription={setDescription}
          setAppPlans={setAppPlans}
          appPlans={appPlans}
        />
      )}
    </AdminWrapper>
  );
};

export const PastProjectPlans = ({ hide, onSelect, appPlans }) => {
  const [search, setSearch] = useState("");

  return (
    <Modal
      minWidth="1000px"
      header={{
        title: "Previously Created App Plans",
        description: "Click on a plan to view it.",
      }}
      hide={hide}
      background={colors.grey1}
    >
      <SearchBar
        data={{
          value: search,
          placeholder: "Search app plans by name",
          margin: "10px 0 20px 0",
          onChange: (v) => setSearch(v),
        }}
      />

      <PlansGrid>
        {appPlans
          .filter(
            (p) =>
              search === "" ||
              get(p, ["response", "name"])
                .toLowerCase()
                .includes(search.toLowerCase())
          )
          .map((p) => (
            <PlanBubble
              onClick={() => {
                hide();
                onSelect(p.id);
              }}
            >
              <Text
                data={{
                  text: truncateText(get(p, ["response", "name"]), 30),
                  fontStyle: "headingMd",
                  cursor: "pointer",
                }}
              />
              <Text
                data={{
                  text: moment(get(p, "date")).fromNow(),
                  fontStyle: "bodyMd",
                  cursor: "pointer",
                }}
              />
            </PlanBubble>
          ))}
      </PlansGrid>
    </Modal>
  );
};

const Setup = ({ description, setDescription, setAppPlans, appPlans }) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const charCount = get(description, "length", 0);

  const overLimit = charCount > 1500;

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (charCount < 10) {
      errorNotification("Please enter a longer prompt.");
    } else {
      setIsGenerating(true);
      if (window.ws && window.ws.readyState === WebSocket.OPEN) {
        window.ws.send(
          JSON.stringify({
            action: "generateAppPlan",
            description,
          })
        );
      } else {
        console.error("WebSocket is not open.");
      }
    }
  };

  const wsURL =
    "wss://o0v9pmvz0g.execute-api.ca-central-1.amazonaws.com/production";

  useEffect(() => {
    const ws = new WebSocket(wsURL);

    ws.onopen = () => {};

    ws.onmessage = (event) => {
      const messageData = JSON.parse(event.data);

      const response = get(messageData, "response");
      const description = get(messageData, "description");

      if (response) {
        // Save plan to API
        apiRequest
          .post("/app_plan/", {
            response,
            description,
          })
          .then((r) => {
            const newPlan = get(r, "data");
            const newId = get(newPlan, "id");

            if (newId) {
              setAppPlans([...appPlans, newPlan]);
              navigate(`/planner/${newId}`);
              setIsGenerating(false);
              successNotification("App Plan Generated Successfully!");
            }
          });
      }
    };

    window.ws = ws;

    return () => {
      ws.close();
    };
  }, []);

  // Generating App
  if (isGenerating) {
    return (
      <>
        <Text
          data={{
            text: "Generating your app plan...",
            margin: "10px 0 0 0",
            fontStyle: "bodyLg",
          }}
        />
        <Spinner size={40} padding="30px 0 0 0" />
        {/* <LoadingAnimation spinner dots color={"dark"} /> */}
      </>
    );
  }

  // Main AI Prompt Screen
  return (
    <>
      <Text
        data={{
          text: "Use AI to generate a Frontly app project plan based on your description",
          margin: "10px 0 0 0",
          fontStyle: "bodyLg",
        }}
      />
      <SearchContainer>
        <SearchInput
          autoFocus
          height={() => getPixels(charCount / 2)}
          placeholder="I run a marketing agency. I need a login portal for my clients to check on the status of our ongoing projects. This app should include a Kanban board for projects and also have an analytics dashboard..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <SearchButton disabled={charCount < 10} onClick={handleSubmit}>
          <SearchText>Submit</SearchText>
          <Icon data={{ icon: "HiSparkles", color: "white", hover: true }} />
        </SearchButton>
        <CharCountMessage overLimit={overLimit}>
          {charCount} / 1500
        </CharCountMessage>
        <ShowExamples
          promptExamples={[
            {
              label: "Client Portal",
              text: "I run a marketing agency. I need a login portal for my clients to check on the status of our ongoing projects. This app should include a Kanban board for projects and also have an analytics dashboard for a high level view of statistics.",
            },
            {
              label: "Analytics Dashboard",
              text: "I need an analytics dashboard for my landscaping business. I need some Charts and Stats showing important metrics like active project count and total revenue, and a Table showing all my contracts.",
            },
            {
              label: "Inventory Management",
              text: "I need an inventory management tool for my shoe store. I'd like a Grid showing all my shoe images and inventory, and a Table showing my order history with my suppliers.",
            },
          ]}
          setPrompt={(p) => {
            setDescription(p);
          }}
        />
      </SearchContainer>
    </>
  );
};

export default Planner;

export const featureNames = availableFeatures.map((f) => f.name);
export const blockNames = availableBlocks.map((f) => f.type);
export const featureMap = {
  CRUD: "Create / Edit Records",
};

export const RenderPlan = ({ plan }) => {
  const requirements = [...get(plan, "requirements", [])].sort((a, b) => {
    // If a is supported and b is not, a comes last
    if (a.supported && !b.supported) {
      return -1;
    }
    // If b is supported and a is not, b comes last
    if (!a.supported && b.supported) {
      return 1;
    }
    // Otherwise, keep their original order
    return 0;
  });

  return (
    <>
      <Text
        data={{
          text: "Review your AI-generated project development plan:",
          fontStyle: "bodyLg",
        }}
      />
      <PlanContainer>
        {requirements.map((r, requirementIndex) => {
          const blocks = get(r, "required_blocks", []).filter((f) =>
            blockNames.includes(f)
          );

          const features = get(r, "required_features", [])
            .filter((f) => featureNames.includes(f))
            .map((f) => get(featureMap, f, f));

          return (
            <RequirementContainer>
              <Row justifyContent="space-between">
                <Text
                  data={{
                    text: r.name,
                    fontStyle: "headingLg",
                    allowSelect: true,
                  }}
                />

                <Icon
                  data={{
                    icon: r.supported ? "FiCheckCircle" : "FiXCircle",
                    color: r.supported ? "green" : "red",
                  }}
                />
              </Row>

              <Text
                data={{
                  text: r.description,
                  allowSelect: true,
                  margin: "0 0 10px 0",
                }}
              />

              {(features.length > 0 || blocks.length > 0) && (
                <RequirementSection>
                  <Text
                    data={{
                      text: "Features & Blocks",
                      fontStyle: "headingMd",
                      margin: "0 0 5px 0",
                      allowSelect: true,
                      color: colors.primary,
                    }}
                  />
                  <Row gap="8px" style={{ flexWrap: "wrap" }}>
                    {features.map((f) => (
                      <>
                        <Tooltip
                          anchorSelect={`.${snakeCase(f)}-${requirementIndex}`}
                          place="bottom"
                          style={{ zIndex: 9999 }}
                        >
                          Click to learn about the {f} feature
                        </Tooltip>
                        <BlockContainer
                          onClick={() =>
                            window.open(
                              `https://help.frontly.ai/en/?q=${encodeURIComponent(
                                f
                              )}`
                            )
                          }
                          className={`${snakeCase(f)}-${requirementIndex}`}
                        >
                          <Text
                            data={{
                              text: f,
                              cursor: "pointer",
                              fontStyle: "bodyMd",
                              allowSelect: true,
                            }}
                          />
                        </BlockContainer>
                      </>
                    ))}
                    {blocks.map((b) => (
                      <>
                        <Tooltip
                          anchorSelect={`.${b}-${requirementIndex}`}
                          place="bottom"
                          style={{ zIndex: 9999 }}
                        >
                          Click to learn about the {b} block
                        </Tooltip>
                        <BlockContainer
                          onClick={() =>
                            window.open(
                              `https://help.frontly.ai/en/?q=${encodeURIComponent(
                                b
                              )}`
                            )
                          }
                          className={`${b}-${requirementIndex}`}
                        >
                          <Icon
                            data={{
                              size: 18,
                              hover: true,
                              color: colors.grey4,
                              icon: get(
                                allBlocks.find((bl) => bl.type === b),
                                "icon"
                              ),
                            }}
                          />
                          <Text
                            data={{
                              text: b,
                              cursor: "pointer",
                              fontStyle: "bodyMd",
                              allowSelect: true,
                            }}
                          />
                        </BlockContainer>
                      </>
                    ))}
                  </Row>
                </RequirementSection>
              )}

              <RequirementSection>
                <Text
                  data={{
                    text: r.supported ? "Usage" : "Not Supported",
                    fontStyle: "headingMd",
                    margin: "0 0 5px 0",
                    allowSelect: true,
                    color: colors.primary,
                  }}
                />
                <Text data={{ text: r.additional_info, allowSelect: true }} />
              </RequirementSection>
            </RequirementContainer>
          );
        })}
      </PlanContainer>
    </>
  );
};

const RequirementSection = styled.div`
  border-top: 1px solid ${colors.divider};
  padding: 10px 0 10px 0;
`;

const BlockContainer = styled.div`
  background: ${colors.grey1};
  border: 1px solid ${colors.grey2};
  border-radius: 8px;
  padding: 5px;
  display: flex;
  gap: 5px;
  cursor: pointer;
  &:hover {
    background: ${colors.grey2};
  }
`;

const PlansGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const PlanBubble = styled.div`
  background: white;
  border: 1px solid ${colors.grey2};
  padding: 15px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background: ${colors.grey1};
  }
`;

const PlanContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const RequirementContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: white;
  border: 1px solid ${colors.grey2};
  padding: 15px;
  border-radius: 15px;
  width: 100%;
`;

const SearchContainer = styled.div`
  background: white;
  border-radius: 15px;
  display: flex;
  padding: 8px 15px 8px 8px;
  align-items: center;
  border: 1px solid ${colors.grey2};
  margin-top: 30px;
  width: 100%;
  position: relative;
  @media screen and (max-width: 800px) {
    outline: 5px solid #ffffff50;
  }
`;

const SearchText = styled.div`
  color: white;
  font-weight: 500;
  font-size: 16px;
`;

const SearchButton = styled.div`
  background: rgb(0, 85, 255);
  color: white;
  font-weight: 500;
  font-size: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  pading: 15px;
  width: 150px;
  height: 50px;
  cursor: pointer;
  ${(p) => p.disabled && "cursor: not-allowed;"}
  &:hover {
    filter: brightness(90%);
  }
`;

const CharCountMessage = styled.div`
  margin: 5px 0 0 3px;
  color: ${(p) => (p.overLimit ? "red" : colors.grey4)};
  position: absolute;
  font-size: 12px;
  bottom: 10px;
  right: 20px;
`;

const SearchInput = styled.textarea`
  padding: 15px;
  width: 100%;
  border: 0px;
  outline: none;
  resize: none;
  font-size: 20px;
  min-height: 100px;
  height: ${(p) => p.height};
  border-radius: 20px;
  &::placeholder {
    color: ${colors.grey3};
  }
  @media screen and (max-width: 800px) {
    padding: 12px;
  }
`;
