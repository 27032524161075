import { get, startCase } from "lodash";
import { rActiveBlockId, rActiveDetailViewId } from "./recoil";

import { Text } from "app/components";
import { spreadsheetSelect } from "app/adminApp/blockConfigs/shared";
import { useRecoilValue } from "recoil";

const useActions = (
  spreadsheets,
  pageBlocks,
  pageActions,
  activeAppObject,
  setPage
) => {
  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);
  const activeBlockId = useRecoilValue(rActiveBlockId);

  const getStepTypes = ({
    step,
    onChange,
    disabledTypes = [],
    actionId = null,
  }) => {
    const currentBlockComponent = get(
      pageBlocks.find(
        (b) => b.id === activeDetailViewId || b.id === activeBlockId
      ),
      "componentId"
    );

    let stepTypes = [
      navigateToPage(step),
      googleSheet(
        step,
        actionId,
        pageBlocks,
        currentBlockComponent,
        onChange,
        spreadsheets,
        setPage,
        pageActions
      ),
      notification(),
      sendEmail(),
      webhook(),
      openAiRequest(step, activeAppObject),
      updateLocalState(),
      logicAction(step),
      updateActiveUser(),
      timeDelay(),
      refreshBlocks(pageBlocks),
      copyToClipboard(),
      csvDownload(step, pageBlocks, spreadsheets),
      webScraper(),
    ];

    const hasInputBlocks = pageBlocks.some(
      (b) => b.componentId === "Input" || b.componentId === "Select"
    );

    if (hasInputBlocks) {
      stepTypes.push(formValidation());
    }

    if (get(activeAppObject, "show_make_action")) {
      stepTypes.push(makeAction(activeAppObject));
    }

    if (get(activeAppObject, "show_zapier_action")) {
      stepTypes.push(zapierAction());
    }

    return stepTypes.filter((t) => !disabledTypes.includes(t.type));
  };

  return {
    getStepTypes,
  };
};

export default useActions;

// if (get(activeApp, "ai_context_beta")) {
//   dsContext = [
//     {
//       id: "dataSourceContext",
//       label: "Data Source Context",
//       componentId: "SpreadsheetSelect",
//       preventExtras: true,
//       description:
//         "Add a data source as context for your request, allowing the AI to answer qualitative and quantitative questions about your data.",
//       displayCondition: () => get(step, "model", "").includes("gpt-4"),
//     },
//     {
//       id: "contextMaxCharacters",
//       label: "Context Max Characters",
//       componentId: "Input",
//       hint: "",
//       preventExtras: true,
//       description:
//         "The maximum number of characters to include in the context for the AI. This sets an upper limit for the cost of the request.",
//       displayCondition: () => get(step, "dataSourceContext"),
//     },
//   ];
// }

const formValidation = () => ({
  label: "Form Validation",
  description: "Run validation on a custom form",
  type: "FORM_VALIDATION",
  icon: "BsClipboard",
  category: "other",
  fields: [
    {
      id: "showFieldErrors",
      label: "Show Field Errors",
      componentId: "Switch",
    },
  ],
});

const webScraper = () => ({
  label: "Web Scraper",
  description: "Scrape a website for it's text content",
  type: "WEB_SCRAPER",
  icon: "FiGlobe",
  category: "other",
  fields: [
    {
      id: "url",
      label: "URL",
      componentId: "DynamicString",
    },
  ],
});

const copyToClipboard = () => ({
  label: "Copy to Clipboard",
  description: "Copy text to clipboard",
  type: "COPY_TO_CLIPBOARD",
  icon: "BsClipboard",
  category: "other",
  fields: [
    {
      id: "value",
      label: "Value",
      componentId: "DynamicString",
    },
  ],
});

// SEND EMAIL
const sendEmail = () => ({
  label: "Send Email",
  description: "Send an email from your app",
  type: "EMAIL",
  icon: "FiMail",
  category: "other",
  fields: [
    {
      id: "recipientEmails",
      label: "Recipient Email",
      componentId: "DynamicString",
    },
    {
      id: "senderName",
      label: "Sender Name",
      componentId: "DynamicString",
    },
    {
      id: "senderEmail",
      label: "Sender Email",
      componentId: "DynamicString",
    },
    {
      id: "subject",
      label: "Subject",
      componentId: "DynamicString",
    },
    {
      id: "body",
      label: "Body",
      componentId: "RichTextEditor",
    },
  ],
});

// NOTIFICATION
const notification = () => ({
  label: "Notification",
  description: "Trigger a popup notification",
  type: "NOTIFICATION",
  icon: "FiBell",
  category: "other",
  fields: [
    {
      id: "text",
      label: "Notification Text",
      componentId: "DynamicString",
    },
    {
      id: "notificationType",
      label: "Type",
      componentId: "Select",
      defaultValue: "SUCCESS",
      hideEmptyItem: true,
      options: [
        { label: "Success", value: "SUCCESS" },
        { label: "Error", value: "ERROR" },
      ],
    },
    // TODO - Zalde to finish this
    // {
    //   id: "notificationStyle",
    //   label: "Style",
    //   componentId: "Select",
    //   defaultValue: "default",
    //   hideEmptyItem: true,
    //   options: [
    //     { label: "Style1", value: "STYLE1" },
    //     // { label: "Style2", value: "STYLE2" },
    //   ],
    // },
  ],
});

// WEBHOOK
const webhook = () => ({
  label: "Webhook",
  description: "Send a POST request to an external server",
  type: "WEBHOOK",
  icon: "FiGlobe",
  category: "automation",
  fields: [
    {
      id: "runLocation",
      label: "Run Location",
      componentId: "Select",
      defaultValue: "frontEnd",
      hideEmptyItem: true,
      options: [
        { label: "Front-End", value: "frontEnd" },
        { label: "Back-End", value: "backEnd" },
      ],
    },
    {
      id: "url",
      label: "URL",
      componentId: "DynamicString",
    },
    {
      id: "headers",
      label: "Headers",
      componentId: "KeyValuePairs",
    },
    {
      id: "body",
      label: "Body",
      componentId: "KeyValuePairs",
    },
    {
      id: "customBody",
      label: "Custom Body JSON (Advanced)",
      componentId: "TextArea",
    },
    {
      id: "waitForResponse",
      label: "Wait For Response",
      hint: "Wait for the webhook to return a response before continuing",
      componentId: "Switch",
      defaultValue: false,
    },
    {
      id: "waitForRealtimeWebhook",
      label: "Wait For Realtime Webhook",
      hint: "Wait for a response via the realtime webhook endpoint to return a response before continuing",
      componentId: "Switch",
      defaultValue: false,
    },
  ],
});

// OPEN AI REQUEST
const openAiRequest = (step, activeAppObject) => {
  let trainingFields = [];
  const objSchema = get(step, "objectSchema", []);
  trainingFields =
    get(step, "responseType", "text") === "object"
      ? objSchema.map((f) => ({
          key: get(f, "key"),
          label: startCase(get(f, "key", "")),
          componentId: "TextArea",
          minHeight: "100px",
        }))
      : [
          {
            key: "response",
            label: "Response",
            componentId: "TextArea",
            minHeight: "100px",
          },
        ];

  return {
    label: "OpenAI Request",
    description: "Trigger a request to OpenAI's ChatGPT",
    type: "OPEN_AI",
    icon: "SiOpenai",
    category: "ai",
    fields: [
      {
        id: "prompt",
        label: "Prompt",
        componentId: "TextArea",
        maxLength: get(activeAppObject, "open_ai_api_key_configured")
          ? 5000
          : 2000,
        minHeight: "150px",
      },
      {
        id: "model",
        label: "Model",
        componentId: "OpenAiModelSelect",
      },
      {
        id: "responseType",
        label: "Response Type",
        componentId: "Select",
        defaultValue: "text",
        hideEmptyItem: true,
        hint: "Choose the type of response formatting to expect from the AI.",
        options: [
          {
            label: "Text",
            value: "text",
          },
          {
            label: "Object",
            value: "object",
          },
        ],
      },
      {
        id: "objectSchema",
        label: "Response Object Fields",
        hint: "Define custom fields for the AI to respond with, which can be used in the next steps",
        componentId: "KeyValuePairs",
        valueLabel: "Description",
        displayCondition: () => get(step, "responseType", "text") === "object",
      },
      {
        id: "examples",
        width: "400px",
        componentId: "MultiForm",
        orientation: "vertical",
        label: "Training Examples",
        labelSingular: "Example",
        hideEmptyItem: true,
        fields: [
          {
            key: "prompt",
            label: "Prompt",
            componentId: "TextArea",
            minHeight: "100px",
          },
          ...trainingFields,
        ],
      },
    ],
  };
};

// GOOGLE SHEET
const googleSheet = (
  step,
  actionId,
  pageBlocks,
  currentBlockComponent,
  onChange,
  spreadsheets,
  setPage,
  pageActions
) => {
  // This ended up being more annoying than I hoped. It only works for the Form right now
  // The edit and create detail view submit is done in a different way, so I'd need to override it there too.
  const googleSheetOnChange =
    pageBlocks === undefined || currentBlockComponent !== "Form"
      ? null
      : (key, newSheetId) => {
          const matchingSheet = spreadsheets.find((s) => s.id === newSheetId);
          const headers = get(matchingSheet, "headers", []);

          let preFill = false;

          pageBlocks.forEach((b) => {
            if (b.componentId === "Form") {
              if (b.submitAction === actionId) {
                preFill = true;
              }
            } else {
              if (
                b.detailCreateSubmitAction === actionId ||
                b.detailEditSubmitAction === actionId
              ) {
                preFill = true;
              }
            }
          });

          let formData = {};
          headers.forEach((h) => {
            formData[h] = `{{ form.${h} }}`;
          });

          setPage({
            actions: pageActions.map((a) => {
              if (a.id === actionId) {
                return {
                  ...a,
                  steps: a.steps.map((s) => {
                    if (s.id === step.id) {
                      // If the action is a form submission, pre-fill the form data
                      if (preFill) {
                        return {
                          ...s,
                          spreadsheet: newSheetId,
                          formData,
                        };
                      } else {
                        return {
                          ...s,
                          spreadsheet: newSheetId,
                        };
                      }
                    }
                    return s;
                  }),
                };
              }
              return a;
            }),
          });
        };

  return {
    label: "Google Sheet",
    description: "Create, update or delete a Google Sheet row",
    type: "GOOGLE",
    category: "data",
    icon: "BsGoogle",
    fields: [
      {
        ...spreadsheetSelect,
        preventExtras: true,
        onChange: googleSheetOnChange,
      },
      {
        id: "actionType",
        hideEmptyItem: true,
        label: "Action Type",
        componentId: "Select",
        defaultValue: "create",
        options: [
          {
            label: "Get",
            value: "get",
          },
          {
            label: "Create",
            value: "create",
          },
          {
            label: "Edit",
            value: "edit",
          },
          {
            label: "Delete",
            value: "delete",
          },
        ],
        displayCondition: () => step.spreadsheet,
      },
      {
        id: "rowId",
        label: "Row ID",
        hint: "The frontly_id of the row to update",
        componentId: "DynamicString",
        displayCondition: () =>
          ["get", "edit", "delete"].includes(get(step, "actionType", "create")),
      },
      {
        id: "rowIdColumn",
        label: "Row Column",
        componentId: "Select",
        hideEmptyItem: true,
        options: get(
          spreadsheets.find((s) => s.id === step.spreadsheet),
          "headers",
          []
        ).map((h) => ({ label: h, value: h })),
        defaultValue: "frontly_id",
        hint: "The column to use in the row finding condition. Defaults to frontly_id.",
        displayCondition: () =>
          ["get", "edit", "delete"].includes(get(step, "actionType", "create")),
      },
      {
        id: "formData",
        columnSpan: 2,
        hint: "Enter the values to submit to the Google Sheet row. Empty values will be omitted.",
        componentId: "GoogleForm",
        section: "requestFields",
        step,
        onChange,
        displayCondition: () =>
          step.spreadsheet &&
          ["edit", "create"].includes(get(step, "actionType", "create")),
      },
      {
        id: "disableSuccessNotification",
        label: "Disable Success Notification",
        hint: "Disable the success notification popup",
        componentId: "Switch",
        displayCondition: () =>
          !["get"].includes(get(step, "actionType", "create")),
      },
      {
        id: "successNotificationText",
        label: "Success Notification Text",
        hint: "Disable the success notification popup",
        componentId: "DynamicString",
        displayCondition: () =>
          !step.disableSuccessNotification &&
          !["get"].includes(get(step, "actionType", "create")),
      },
    ],
  };
};

// NAVIGATE TO PAGE
const navigateToPage = (step) => ({
  label: "Navigate To Page",
  description: "Send the user to another page",
  type: "NAVIGATE",
  icon: "BsArrowRightCircle",
  category: "other",
  fields: [
    {
      id: "destination",
      label: "Destination",
      componentId: "SelectToggle",
      defaultValue: "page",
      tabs: [
        { label: "Page", value: "page" },
        { label: "Form", value: "form" },
        { label: "Url", value: "url" },
      ],
    },
    {
      id: "page",
      label: "Page",
      componentId: "PageSelect",
      showExternalLink: true,
      displayCondition: () => get(step, "destination", "page") === "page",
    },
    {
      id: "form",
      label: "Form",
      componentId: "FormSelect",
      displayCondition: () => get(step, "destination", "page") === "form",
    },
    {
      id: "externalLink",
      label: "External Link",
      hint: "Send a user to a page outside of your app",
      componentId: "DynamicString",
      displayCondition: () =>
        step.page === "externalLink" || step.destination === "url",
    },
    {
      id: "openNewWindow",
      label: "Open New Window",
      componentId: "Switch",
      orientation: "horizontal",
    },
    {
      id: "urlParams",
      label: "URL Params",
      componentId: "DataGrid",
      columns: [
        {
          key: "key",
          componentId: "Input",
          width: "100%",
        },
        {
          key: "value",
          componentId: "DynamicString",
          width: "100%",
        },
      ],
    },
  ],
});

// UPDATE LOCAL STATE
const updateLocalState = () => ({
  label: "Update Local State",
  description: "Set local state values for dynamic rendering",
  type: "LOCAL_STATE",
  icon: "BsArrowUpCircle",
  category: "data",
  fields: [
    {
      id: "values",
      label: "Values",
      componentId: "KeyValuePairs",
    },
  ],
});

// LOGIC ACTION
const logicAction = (step) => ({
  label: "Logic",
  description:
    "Choose from a variety of data manipulations and logic operations",
  type: "LOGIC",
  icon: "FiGitPullRequest",
  category: "data",
  fields: [
    {
      id: "operation",
      label: "Operation",
      componentId: "Select",
      defaultValue: "page",
      hideEmptyItem: true,
      options: [
        {
          label: "Add to comma-separated string",
          value: "addToCommaSeparatedString",
        },
        {
          label: "Remove from comma-separated string",
          value: "removeFromCommaSeparatedString",
        },
      ],
    },
    ...getLogicFieldsByType(step),
  ],
});

// UPDATE ACTIVE USER
const updateActiveUser = () => ({
  label: "Update Active User",
  description: "Update fields stored on the Active User's account",
  type: "UPDATE_USER",
  icon: "BsPersonUp",
  category: "data",
  fields: [
    {
      id: "values",
      componentId: "UserActionEditor",
      section: "Values",
    },
  ],
});

// TIME DELAY
const timeDelay = () => ({
  label: "Time Delay",
  description: "Wait a specified amount of time before continuing",
  type: "TIME_DELAY",
  icon: "BsClock",
  category: "other",
  fields: [
    {
      id: "seconds",
      label: "Seconds",
      componentId: "Input",
      type: "number",
      defaultValue: 1,
    },
  ],
});

// MAKE
const makeAction = (app) => ({
  label: "'Make' Scenario",
  description: "Trigger a Scenario in the 'Make' automation platform",
  disabledMessage:
    (!get(app, "make_api_token") || !get(app, "make_organization_id")) &&
    "Configure your Make account connection on the Integration Settings page in your admin to enable this action",
  type: "MAKE",
  icon: "MakeLogo",
  category: "automation",
  fields: [
    {
      id: "scenario",
      label: "Scenario",
      componentId: "MakeScenarioSelect",
      columnSpan: 2,
    },
    {
      id: "values",
      label: "Values",
      componentId: "KeyValuePairs",
      columnSpan: 2,
    },
    {
      id: "waitForResponse",
      label: "Wait For Response",
      hint: "Wait for the webhook to return a response before continuing",
      componentId: "Switch",
      defaultValue: false,
    },
  ],
});

// REFRESH BLOCKS
const refreshBlocks = (blocks) => ({
  label: "Refresh Blocks",
  description: "Refresh the data in specific blocks on the page",
  type: "REFRESH_BLOCKS",
  icon: "FiRefreshCcw",
  category: "other",
  fields: [
    {
      id: "blocks",
      label: "Blocks",
      componentId: "MultiSelect",
      options: blocks
        .filter((b) => b.spreadsheet)
        .map((b) => ({
          label: get(b, "label", "Block"),
          value: b.id.toString(),
        })),
    },
  ],
});

// CSV DOWNLOAD
const csvDownload = (step, blocks, spreadsheets) => {
  const blockId = get(step, "block");
  const block = blocks.find((b) => b.id === parseInt(blockId));
  const sheet = spreadsheets.find((s) => s.id === get(block, "spreadsheet"));

  const headers = get(sheet, "headers", []).filter(
    (h) => !["frontly_data"].includes(h)
  );

  return {
    label: "CSV Download",
    description: "Download the data from an active block as a CSV file",
    type: "CSV_DOWNLOAD",
    icon: "FiDownload",
    category: "other",
    fields: [
      {
        id: "block",
        label: "Block",
        hint: "Select the block to download the data from",
        componentId: "Select",
        options: blocks
          .filter(
            (b) =>
              b.spreadsheet &&
              ["Table", "Kanban", "Grid", "Calendar", "Map"].includes(
                b.componentId
              )
          )
          .map((b) => ({
            label: get(b, "label", "Block"),
            value: b.id.toString(),
          })),
      },
      {
        id: "fileName",
        label: "File Name",
        componentId: "Input",
        hint: "The name of the file to download. Defaults to 'data'. Don't include the .csv extension in the filename.",
      },
      {
        id: "headers",
        label: "Columns",
        hint: "The columns to include in the CSV download. Leave empty to include all columns.",
        componentId: "MultiSelect",
        options: headers.map((h) => ({
          label: h,
          value: h,
        })),
      },
    ],
  };
};

// ZAPIER
const zapierAction = () => ({
  label: "Zapier Trigger",
  description: "Trigger a Zap in 'Zapier' automation platform",
  type: "ZAPIER",
  icon: "ZapierIcon",
  category: "automation",
  otherContent: (
    <div>
      <Text
        data={{
          text: "Note: This action requires a paid Zapier plan to function",
        }}
      />
    </div>
  ),
  fields: [
    {
      id: "webhookUrl",
      label: "Webhook URL",
      componentId: "Input",
      columnSpan: 2,
    },
    {
      id: "values",
      label: "Values",
      componentId: "KeyValuePairs",
      columnSpan: 2,
    },
    {
      id: "waitForResponse",
      label: "Wait For Response",
      hint: "Wait for the webhook to return a response before continuing",
      componentId: "Switch",
      defaultValue: false,
    },
  ],
});

// A helper function to get the fields for the logic action based on the operation type
const getLogicFieldsByType = (step) => {
  if (
    ["addToCommaSeparatedString", "removeFromCommaSeparatedString"].includes(
      step.operation
    )
  ) {
    return [
      {
        id: "list",
        label: "List",
        componentId: "DynamicString",
      },
      {
        id: "value",
        label: "Value",
        componentId: "DynamicString",
      },
      {
        id: "uniqueValues",
        label: "Unique Values",
        componentId: "Switch",
        hint: "Only add unique values to the list. If the value is already in the list, it will not be added.",
        displayCondition: () => step.operation === "addToCommaSeparatedString",
      },
    ];
  }
  return [];
};
