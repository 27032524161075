import { Button, Icon, Input, Text } from "app/components";
import { get, isArray } from "lodash";
import { rOrganization, rSetAppConfig } from "app/utils/recoil";
import { useEffect, useState } from "react";

import { SideContainer } from "./SideContainer";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

const steps = {
  0: {
    key: "experience",
    question: "What is your experience level with no-code tools?",
    options: [
      {
        value: "None",
        label: "None",
      },
      {
        value: "Beginner",
        label: "Beginner",
      },
      {
        value: "Intermediate",
        label: "Intermediate",
      },
      {
        value: "Expert",
        label: "Expert",
      },
    ],
  },
  1: {
    key: "automation",
    question: "Which no-code automation platforms do you use?",
    multi: true,
    showOther: true,
    options: [
      {
        value: "Zapier",
        label: "Zapier",
      },
      {
        value: "Make",
        label: "Make",
      },
      {
        value: "Pabbly",
        label: "Pabbly",
      },
      {
        value: "ActivePieces",
        label: "ActivePieces",
      },
      {
        value: "None",
        label: "None",
      },
    ],
  },
  2: {
    key: "role",
    question: "Which option best describes you?",
    options: [
      {
        value: "I'm just looking around",
        label: "I'm just looking around",
      },
      {
        value: "Business Owner",
        label: "Business Owner",
      },
      {
        value: "Employee",
        label: "Employee",
      },
      {
        value: "Marketing Agency",
        label: "Marketing Agency",
      },
      {
        value: "Software Agency / Contractor",
        label: "Software Agency / Contractor",
      },
    ],
  },
};

// THIS IS NOT BEING USED RIGHT NOW
const OnboardingSurvey = () => {
  // const [step, setStep] = useState(0);
  // const currentStep = get(steps, step);
  // const currentStepKey = get(currentStep, "key");
  // const question = get(currentStep, "question");
  // const description = get(currentStep, "description");
  // const options = get(currentStep, "options", []);
  // const [appConfig, setAppConfig] = useRecoilState(rSetAppConfig);
  // const organization = useRecoilValue(rOrganization);
  // const onboardingSurvey = get(organization, "onboarding_survey", {}) || {};
  // const currentValue = get(onboardingSurvey, currentStepKey);
  // const setResponseData = (k, v) => {
  //   setAppConfig({
  //     ...appConfig,
  //     organization: {
  //       ...appConfig.organization,
  //       onboarding_survey: {
  //         ...onboardingSurvey,
  //         [k]: v,
  //       },
  //     },
  //   });
  // };
  // useEffect(() => {
  //   // FIND AND SET CURRENT STEP
  //   let firstIncompleteStep = null;
  //   Object.keys(steps).forEach((k, i) => {
  //     const match = get(onboardingSurvey, k);
  //     if (!match && firstIncompleteStep === null) {
  //       firstIncompleteStep = i;
  //     }
  //   });
  //   if (firstIncompleteStep !== null) {
  //     // SET STEP TO FIRST INCOMPLETE STEP
  //     setStep(firstIncompleteStep);
  //   } else {
  //     // COMPLETE - NAVIGATE TO DASHBOARD
  //     // navigate("/home");
  //   }
  // }, []);
  // const navigate = useNavigate();
  // const save = () => {
  //   if (step < Object.keys(steps).length - 1) {
  //     // INCOMPLETE - GO TO NEXT STEP
  //     setStep(step + 1);
  //     mixpanel.track(`Onboarding Survey Step ${step + 1}`);
  //   } else {
  //     // COMPLETE - NAVIGATE TO DASHBOARD
  //     navigate("/chat/new");
  //     mixpanel.people.set(onboardingSurvey);
  //   }
  //   // SAVE CHOICE
  //   apiRequest.post("/org_settings/", {
  //     onboarding_survey: onboardingSurvey,
  //   });
  // };
  // return (
  //   <SideContainer descriptionText={"Get started today"}>
  //     <SurveyContainer>
  //       <div>
  //         <Text
  //           data={{
  //             text: "Account Setup",
  //             fontStyle: "heading2xl",
  //             margin: "0 0 5px 0",
  //           }}
  //         />
  //         <Text
  //           data={{
  //             text: "To get started, complete this short setup flow",
  //             fontStyle: "bodyLg",
  //           }}
  //         />
  //       </div>
  //       <ProgressRow>
  //         {Object.keys(steps).map((s, i) => (
  //           <ProgressBar key={i} active={step >= i}>
  //             &nbsp;
  //           </ProgressBar>
  //         ))}
  //       </ProgressRow>
  //       <div style={{ height: "24px" }}>
  //         {step > 0 && (
  //           <Icon
  //             data={{
  //               icon: "FiArrowLeft",
  //               color: colors.primary,
  //               hover: true,
  //               onClick: () => setStep(step - 1),
  //             }}
  //           />
  //         )}
  //       </div>
  //       <Text data={{ text: question, fontStyle: "headingLg" }} />
  //       {description && (
  //         <Text data={{ text: description, fontStyle: "bodyLg" }} />
  //       )}
  //       {options.length > 0 && (
  //         <OptionsContainer>
  //           {options.map((option, index) => {
  //             const currentValueArray = isArray(currentValue)
  //               ? currentValue
  //               : [];
  //             const isActive = currentStep.multi
  //               ? currentValueArray.includes(option.value)
  //               : currentValue === option.value;
  //             const optionClick = () => {
  //               let newStepData = null;
  //               if (currentStep.multi) {
  //                 if (isActive) {
  //                   newStepData = currentValueArray.filter(
  //                     (v) => v !== option.value
  //                   );
  //                 } else {
  //                   newStepData = [...currentValueArray, option.value];
  //                 }
  //               } else {
  //                 newStepData = option.value;
  //               }
  //               setResponseData(currentStepKey, newStepData);
  //             };
  //             return (
  //               <Option
  //                 key={index}
  //                 text={option.label}
  //                 active={isActive}
  //                 onClick={optionClick}
  //               />
  //             );
  //           })}
  //           {currentStep?.showOther && (
  //             <Input
  //               data={{
  //                 placeholder: "Other",
  //                 border: `1px solid ${colors.grey3}`,
  //                 borderRadius: "6px",
  //                 fontStyle: "bodyLg",
  //                 padding: "15px",
  //                 onChange: (v) => setResponseData(currentStepKey, v),
  //               }}
  //             />
  //           )}
  //         </OptionsContainer>
  //       )}
  //       {currentValue === "other" && (
  //         <OtherInput
  //           placeholder="Type 'Other' answer here"
  //           value={get(onboardingSurvey, `${currentStepKey}_other`)}
  //           onChange={(e) =>
  //             setResponseData(`${currentStepKey}_other`, e.target.value)
  //           }
  //         />
  //       )}
  //       <Button
  //         data={{
  //           text: step < Object.keys(steps).length - 1 ? "Next" : "Complete",
  //           disabled: false,
  //           onClick: save,
  //           padding: "16px",
  //           width: "110px",
  //           styles: {
  //             fontSize: 16,
  //           },
  //         }}
  //       />
  //     </SurveyContainer>
  //   </SideContainer>
  // );
};

export default OnboardingSurvey;

const Option = ({ text, onClick, active }) => (
  <OptionContainer onClick={onClick} active={active}>
    <Text data={{ text, fontStyle: "bodyLg", cursor: "pointer" }} />
    <Icon
      data={{
        icon: active ? "FiCheckCircle" : "FiCircle",
        color: active ? colors.primary : "#706b67",
        hover: true,
      }}
    />
  </OptionContainer>
);

const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 80%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ProgressRow = styled.div`
  display: flex;
  width: 100%;
`;

const ProgressBar = styled.div`
  height: 8px;
  width: 100%;
  background: ${(p) => (p.active ? colors.primary : colors.grey2)};
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  gap: 15px;
  width: 100%;
  overflow-y: auto;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  justify-content: space-between;
  border: 1px solid ${(p) => (p.active ? colors.primary : colors.grey3)};
  background: ${(p) => (p.active ? "#e6efff" : "transparent")};
  border-radius: 6px;
  padding: 15px;
  cursor: pointer;
  gap: 20px;
`;

export const OtherInput = styled.input`
  border: 1px solid ${colors.primary};
  padding: 20px;
  cursor: pointer;
  font-size: 16px;
  background: white;
  border-radius: 6px;
  width: 500px;
`;
