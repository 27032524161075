import { Text } from "app/components";
import { colors } from "app/utils/theme";
import styled from "styled-components";

const SettingsSection = ({ title, description, children, width }) => {
  return (
    <SettingsSectionContainer width={width}>
      <TextSection>
        <Text
          data={{
            text: title,
            fontStyle: "headingLg",
            margin: "0 0 10px 0",
          }}
        />
        <Text
          data={{
            text: description,
            fontStyle: "bodyMd",
            color: colors.grey3,
          }}
        />
      </TextSection>

      <CardSection>{children}</CardSection>
    </SettingsSectionContainer>
  );
};

export default SettingsSection;

const SettingsSectionContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: ${(p) => p.width || "500px"};
`;

const TextSection = styled.div`
  flex: 1;
`;

export const CardSection = styled.div`
  background: white;
  border: 1px solid ${colors.divider};
  border-radius: 12px;
  flex: 1;
  padding: 20px;
`;
