import { allBlocks, getBlocksWithPlaceholder, getHighest } from "./utils";
import {
  rActiveBlockId,
  rActiveDetailViewId,
  rActiveEditField,
  rApp,
} from "./recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { blockConfigs } from "app/adminApp/blockConfigs";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import useActiveBlock from "./useActiveBlock";
import usePage from "./usePage";
import useSetPageNew from "./useSetPageNew";

const useAddBlock = () => {
  const { setPage } = useSetPageNew();
  const app = useRecoilValue(rApp);

  const styling = get(app, "styling", {});
  const darkMode = get(styling, "theme", {}) === "dark";

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);
  const activeBlockId = useRecoilValue(rActiveBlockId);
  const activeBlock = useActiveBlock();
  const setActiveBlockId = useSetRecoilState(rActiveBlockId);
  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const page = usePage();

  const blocks = get(page, "blocks", []);

  const addBlock = ({
    type,
    layoutPosition = null,
    layoutParent = null,
    returnInsteadOfAdd = false,
    callback = null,
    placeholderId = null,
    latestBlocks = null,
  }) => {
    const currentBlocks = latestBlocks || blocks;
    const newId = getHighest(currentBlocks, "id") + 1;

    const defaults = getDefaultBlockData(
      type,
      darkMode,
      get(styling, "blockBackgroundColor", "#FFFFFF")
    );

    let newBlock = {
      id: newId,
      componentId: type,
      label: type, // This should be inferred, but not a big deal
      ...defaults,
    };

    if (layoutParent) {
      newBlock.layoutParent = layoutParent;
    }

    if (layoutPosition) {
      newBlock.layoutPosition = layoutPosition;
    }

    if (activeDetailViewId) {
      newBlock.parent = activeDetailViewId;
    }

    // If there's an active container block, set the new block's layoutParent to the container, and set parent to null
    if (
      activeBlockId &&
      ["Layout", "Row", "Column"].includes(get(activeBlock, "componentId"))
    ) {
      if (!["Button", "Text"].includes(type)) {
        newBlock["fillRemainingSpace"] = true;
      }

      const blockConfig = allBlocks.find((b) => b.type === type);

      newBlock["layoutWidth"] = blockConfig.defaultLayoutWidth;
      newBlock["layoutParent"] = activeBlockId;

      delete newBlock["parent"];
      setActiveEditField(null);
    } else {
      setActiveBlockId(newId);
      setActiveEditField(null);
    }

    let newBlocks = [...currentBlocks, newBlock];

    if (placeholderId) {
      // Replace placeholder block with new block data
      newBlocks = getBlocksWithPlaceholder(
        newBlocks,
        placeholderId,
        newBlock.id
      );
    }

    mixpanel.track("Add Block", { type });

    if (returnInsteadOfAdd) {
      return newBlock;
    }

    setPage({ blocks: newBlocks });

    if (callback) {
      callback();
    }
  };

  return addBlock;
};

// GET DEFAULT BLOCK DATA
const getDefaultBlockData = (type, darkMode, blockBgColor) => {
  const config = get(blockConfigs, type);
  const blockConfig = allBlocks.find((b) => b.type === type);

  let defaultObj = {};

  const defaultBgColor = darkMode
    ? "white" // default to white because we override in dark mode
    : blockBgColor;

  // Add background color if card block
  if (get(blockConfig, "card") !== "disabled") {
    defaultObj["backgroundColor"] = defaultBgColor;
  }

  get(config, "resources", [])
    .filter((r) => r.defaultValue)
    .forEach((r) => {
      defaultObj[r.id] = r.defaultValue;
    });

  if (get(blockConfig, "padding")) {
    defaultObj["padding"] = get(blockConfig, "padding");
  }

  // Apply default resource values - only used in Chart block right now
  get(blockConfig, "defaults", []).forEach((c) => {
    defaultObj[c.id] = c.value;
  });

  return defaultObj;
};

export default useAddBlock;
