import { useEffect, useState } from "react";

import { apiRequest } from "app/utils/apiRequests";
import { isFrontlyAdmin } from "app/utils/utils";
import styled from "styled-components";

export const initGoogleLogin = (callback, googleReady) => {
  // LOAD GOOGLE BUTTON
  window.google.accounts.id.initialize({
    client_id: googleReady,
    callback,
    prompt_parent_id: "g_id_onload",
  });
  window.google.accounts.id.renderButton(
    document.getElementById("google-button"),
    { theme: "outline", size: "large", type: "standard" } // customization attributes
  );
  window.google.accounts.id.prompt(); // also display the One Tap dialog
};

export const useGoogleLogin = (setData, callback) => {
  const [googleReady, setGoogleReady] = useState(false);

  const enableGoogleSignup = isFrontlyAdmin;

  useEffect(() => {
    if (enableGoogleSignup) {
      if (window.google && process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_ID) {
        setGoogleReady(process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_ID);
      }
    }

    return () => {};
  }, [window.google, process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_ID]);

  useEffect(() => {
    if (enableGoogleSignup) {
      if (!googleReady) return;

      // set initial access token
      initGoogleLogin((r) => handleJWT(r, setData, callback), googleReady);
    }

    return () => {};
  }, [googleReady]);
};

export const handleJWT = (r, setData, callback) => {
  setData({ isFetching: true });
  apiRequest
    .post(`/google_auth/`, {
      token: r.credential,
      is_frontly_admin: isFrontlyAdmin,
    })
    .then((r) => callback(r));
};

export const GoogleOneTapContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const GoogleSignInButtonPlaceholder = styled.div`
  margin: 20px 0 0 0;
`;

export const GoogleLoginButton = () => {
  return (
    <GoogleOneTapContainer
      id="g_id_onload"
      data-prompt_parent_id="g_id_onload"
      data-cancel_on_tap_outside="false"
    />
  );
};
