import { SimpleDropdown, Spinner } from "app/components";
import { boxShadow, colors } from "app/utils/theme";

import { apiRequest } from "app/utils/apiRequests";
import { rReports } from "app/utils/recoil";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useState } from "react";

const CardContainer = styled.div`
  background: white;
  width: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  box-shadow: ${boxShadow.card};
  overflow-x: auto;
  position: relative;
`;

const CardHeader = styled.div`
  border-bottom: 1px solid ${colors.divider};
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardContent = styled.div`
  width: 100%;
  padding: ${(p) => p.padding || "25px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
`;

const Card = ({ label, children, reportId, type }) => {
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState();

  const [reports, setReports] = useRecoilState(rReports);

  // SAVE REPORT TO API
  const deleteReport = () => {
    const newReports = reports.filter((r) => r.id !== reportId);

    setIsDeleting(true);
    apiRequest
      .post("/app_settings/", {
        reports: newReports,
      })
      .then(() => {
        navigate("/reports");
        setReports(newReports);
        setIsDeleting(false);
        successNotification("Report Deleted");
      });
  };

  const getIcon = () => {
    if (type === "Table") {
      return "BsTable";
    } else if (type === "Chart") {
      return "BsBarChart";
    } else if (type === "Stat") {
      return "Bs1Square";
    }
  };

  return (
    <CardContainer>
      <CardHeader>
        <Label onClick={() => navigate(`/report/${reportId}`)}>{label}</Label>
        {/* <Row gap="15px" alignItems="center"> */}
        {/* <Icon data={{ icon: getIcon(), color: colors.grey3, size: 20 }} /> */}
        {/* </Row> */}
        {reportId && (
          <SimpleDropdown
            data={{
              options: [
                {
                  label: "Delete",
                  onClick: deleteReport,
                },
                {
                  label: "Edit Report",
                  onClick: () => navigate(`/report/${reportId}`),
                },
              ],
              icon: {
                icon: "FiMoreVertical",
                size: 25,
                hover: true,
                color: colors.grey3,
              },
            }}
          />
        )}
      </CardHeader>
      <CardContent padding={type === "Table" && "10px 25px 25px 25px"}>
        {isDeleting ? <Spinner color={colors.grey3} /> : children}
      </CardContent>
    </CardContainer>
  );
};

export default Card;

const Label = styled.div`
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: ${colors.primary};
  }
`;
