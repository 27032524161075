import React from 'react';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import './styles.css';

const RadioGroup = () => (
  <form>
    <RadixRadioGroup.Root className="RadioGroupRoot" defaultValue="default" aria-label="View density">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <RadixRadioGroup.Item className="RadioGroupItem" value="default" id="r1">
          <RadixRadioGroup.Indicator className="RadioGroupIndicator" />
        </RadixRadioGroup.Item>
        <label className="Label" htmlFor="r1">
          Default
        </label>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <RadixRadioGroup.Item className="RadioGroupItem" value="comfortable" id="r2">
          <RadixRadioGroup.Indicator className="RadioGroupIndicator" />
        </RadixRadioGroup.Item>
        <label className="Label" htmlFor="r2">
          Comfortable
        </label>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <RadixRadioGroup.Item className="RadioGroupItem" value="compact" id="r3">
          <RadixRadioGroup.Indicator className="RadioGroupIndicator" />
        </RadixRadioGroup.Item>
        <label className="Label" htmlFor="r3">
          Compact
        </label>
      </div>
    </RadixRadioGroup.Root>
  </form>
);

export default RadioGroup;