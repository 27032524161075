import { Icon, Spinner } from "app/components";
import React, { memo } from "react";
import {
  getPixels,
  isFrontlyAdmin,
  isObjectEqualFromKeys,
} from "app/utils/utils";
import { rApp, rFetchingBlockIds } from "app/utils/recoil";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import { paddingObjToString } from "./Text";
import styled from "styled-components";
import useActionResolver from "../useActionResolver";
import useDynamicText from "../useDynamicText";
import { useRecoilValue } from "recoil";

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0px;
  cursor: pointer;
  border: 1px;
  gap: 5px;
  height: ${(p) => getPixels(p.height)};
  flex-direction: ${(p) => (p.flippedIcon ? "row-reverse" : "row")};
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  padding: ${(p) => p.padding};
  font-weight: ${(p) => p.fontWeight || 500};
  font-size: ${(p) => getPixels(p.fontSize || 14)};
  color: ${(p) => p.fontColor || "white"};
  background: ${(p) => p.backgroundColor};

  width: ${(p) => (p.width ? getPixels(p.width) : "fit-content")};

  ${(p) => p.fillRemainingSpace && "width: 100%;"}

  ${(p) =>
    p.type === "secondary" &&
    `
    background: white;
    border: 1px solid ${colors.grey2};
    color: ${colors.grey4};
  `}

  &:hover {
    filter: brightness(90%);
  }
  ${(p) => p.customCss}
`;

const Button = ({ block, page }) => {
  const app = useRecoilValue(rApp);
  const { actionResolver } = useActionResolver(page);
  const { processDynamicText } = useDynamicText();
  const appStyling = get(app, "styling", {});
  const primaryColor = get(app, "primary_color", colors.primary);

  const repeatingRecord = get(block, "repeatingRecord");
  const repeatingRecordId = get(repeatingRecord, "frontly_id");

  const buttonText = processDynamicText({
    text: get(block, "text"),
    reusableBlockId: block.reusableBlockId,
    context: { repeatingRecord },
  });

  let blockId = get(block, "id");
  if (repeatingRecordId) {
    blockId = `${blockId}-${repeatingRecordId}`;
  }

  const onClick = (e) => {
    if (!isFrontlyAdmin) {
      // For some reason having this broke the admin for button clicking
      e.stopPropagation();
    }

    const rawAction = get(block, ["actionMap", "clickAction"]);
    const actionId = get(block, "clickAction");

    actionResolver({
      rawAction,
      actionId,
      reusableBlockId: block.reusableBlockId,
      blockId: blockId,
      context: { repeatingRecord },
    });
  };

  const fetchingBlockIds = useRecoilValue(rFetchingBlockIds);

  const borderRadius =
    get(block, "borderRadius") || get(appStyling, "buttonBorderRadius");

  const type = get(block, "type", "primary");

  const isFetching = fetchingBlockIds.includes(blockId);

  const icon = get(block, "icon");

  const fontSize = get(block, "fontSize", 14);

  const customPadding = paddingObjToString(block.padding, null);

  const fontSizeMinus2 = parseInt(fontSize) - 2;

  const padding = customPadding || getPixels(fontSizeMinus2 || 12);

  const height = customPadding ? "auto" : 30 + fontSizeMinus2;

  const customCss = get(block, "customCss", {});

  return (
    <StyledButton
      type={type}
      onClick={onClick}
      fillRemainingSpace={get(block, "fillRemainingSpace")}
      width={get(block, "layoutWidth")}
      borderRadius={borderRadius}
      flippedIcon={get(block, "flippedIcon")}
      fontColor={get(block, "fontColor")}
      fontWeight={get(block, "fontWeight")}
      fontSize={fontSize}
      padding={padding}
      height={height}
      backgroundColor={get(block, "buttonColor") || primaryColor}
      customCss={processDynamicText({
        text: get(customCss, "buttonContainer"),
        reusableBlockId: block.reusableBlockId,
        context: { repeatingRecord: get(block, "repeatingRecord") },
      })}
    >
      {isFetching && (
        <Spinner
          color={type === "primary" ? "white" : colors.grey4}
          size={20}
        />
      )}
      {!isFetching && buttonText}
      {icon && !isFetching && (
        <Icon
          data={{
            icon,
            hover: true,
            size: fontSize,
          }}
        />
      )}
    </StyledButton>
  );
};

// Custom comparison function
const areEqual = (prevProps, nextProps) => {
  const arePropsEqual = isObjectEqualFromKeys(prevProps, nextProps, [
    "block",
    "primaryColor",
    "appStyling",
  ]);

  return arePropsEqual;
};

// Memoize the Button component with custom comparison function
const MemoizedButton = memo(Button, areEqual);

export default MemoizedButton;
