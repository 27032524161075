import { Button, Icon, Row, Text } from "app/components";
import Report, { ReportContent } from "./Report";
import { errorNotification, successNotification } from "app/utils/Notification";
import { rReports, rSavedSpreadsheets } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import LoadingAnimation from "../generate/LoadingAnimation";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getHighest } from "app/utils/utils";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";

const ReportDetails = () => {
  const testMode = false;

  const params = useParams();

  const reportId =
    get(params, "id") && get(params, "id") !== "new"
      ? parseInt(get(params, "id"))
      : null;

  const navigate = useNavigate();

  const [reports, setReports] = useRecoilState(rReports);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const spreadsheets = savedSpreadsheets.map((s) => ({
    id: s.id,
    tab_name: s.tab_name,
    headers: s.headers,
    data: s.data,
    field_data: s.field_data,
  }));

  const report = reports.find((r) => r.id === parseInt(reportId));

  const [prompt, setPrompt] = useState("");
  const [data, setData] = useState(null);

  const [isFetching, setIsFetching] = useState(false);
  const [changes, setChanges] = useState(false);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const finalData = report || data;

  const [view, setView] = useState("list");

  const handleAiResponse = (messageData) => {
    console.log("messageData", messageData);

    const result = get(messageData, "result");

    if (result) {
      apiRequest
        .post("/magic_metrics/process_query/", {
          result,
        })
        .then((r) => {
          const responseData = get(r, "data");
          const errorMessage = get(responseData, "error_message");

          if (errorMessage) {
            setError(errorMessage || "There was an unexpected error.");
            errorNotification(errorMessage);
          } else {
            setData(get(r, "data"));
            setChanges(false);
          }

          setIsFetching(false);
        });
    }
  };

  useEffect(() => {
    if (!reportId && !testMode) {
      const ws = new WebSocket(
        "wss://o0v9pmvz0g.execute-api.ca-central-1.amazonaws.com/production"
      );

      ws.onmessage = (event) => {
        const messageData = JSON.parse(event.data);
        handleAiResponse(messageData);
      };

      ws.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      window.ws = ws;

      return () => {
        ws.close();
      };
    }
  }, [reportId]);

  // RUN AI QUERY
  const runQuery = () => {
    if (testMode) {
      // RUN ON FRONTLY API FOR TESTING
      setIsFetching(true);
      apiRequest
        .post("/magic_metrics/result/", {
          prompt,
          spreadsheets,
        })
        .then((r) => {
          const responseData = get(r, "data");
          handleAiResponse(responseData);
        });
    } else {
      // RUN IN CLOUD
      if (window.ws && window.ws.readyState === WebSocket.OPEN) {
        // Set 30 second timer to prevent infinite loading

        setIsFetching(true);
        setQuery(prompt);

        window.ws.send(
          JSON.stringify({
            action: "magic_metrics",
            prompt,
            spreadsheets,
          })
        );
      } else {
        console.error("WebSocket is not open.");
      }
    }

    // mixpanel.track("Run Report");
  };

  // DELETE REPORT IN API
  const deleteReport = () => {
    // If new report, add to list
    const newReports = reports.filter((r) => r.id !== reportId);
    mixpanel.track("Delete Report");
    setReports(newReports);
    reset();
    successNotification("Deleted");
    apiRequest.post("/app_settings/", {
      reports: newReports,
    });
  };

  // SAVE REPORT TO API
  const saveReport = () => {
    const newId = getHighest(reports, "id") + 1;
    // If new report, add to list
    const newReports = reportId
      ? reports
      : [
          ...reports,
          {
            ...data,
            label: prompt,
            id: newId,
          },
        ];

    if (reportId) {
      mixpanel.track("Edit Report");
    } else {
      mixpanel.track("Save Report");
    }

    setIsSaving(true);
    apiRequest
      .post("/app_settings/", {
        reports: newReports,
      })
      .then(() => {
        setReports(newReports);
        setIsSaving(false);
        successNotification("Saved");
        navigate(`/magicmetrics/${newId}`);
      });
  };

  const reset = () => {
    setData(null);
    setError(false);
    setQuery(null);
    setPrompt("");
    navigate("/magicmetrics/new");
  };

  if (view === "grid") {
    return (
      <DashboardGrid count={reports.length}>
        {reports.map((report) => (
          <Report data={report} />
        ))}
      </DashboardGrid>
    );
  }

  return (
    <>
      <SidebarContainer>
        <Row justifyContent="space-between" margin="0px 15px 20px 15px">
          <Text
            data={{
              text: "Saved Reports",
              fontStyle: "headingLg",
            }}
          />
          <Icon
            data={{
              icon: "FiEdit",
              color: colors.grey4,
              hover: true,
              onClick: reset,
            }}
          />
        </Row>
        <StepsContainer>
          {reports.map((r) => {
            const active = reportId === r.id;
            return (
              <StepContainer
                onClick={() => navigate(`/magicmetrics/${r.id}`)}
                active={active}
              >
                <Text
                  data={{
                    text: r.label,
                    cursor: "pointer",
                    fontStyle: active ? "headingMd" : "bodyLg",
                  }}
                />
              </StepContainer>
            );
          })}
        </StepsContainer>
      </SidebarContainer>

      <Container>
        {isFetching && <LoadingAnimation dots color="black" spinner />}

        {!isFetching && (
          <>
            <Text
              data={{
                text: error
                  ? "Error"
                  : get(report, "label") ||
                    query ||
                    "Ask anything about your sheets",
                fontStyle: "heading3xl",
                margin: "0 0 30px 0",
              }}
            />

            {!error && !finalData && (
              <StyledTextArea
                autoFocus
                placeholder={"Show project revenue by category"}
                value={prompt}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    runQuery();
                  }
                }}
                onChange={(e) => {
                  setPrompt(e.target.value);
                  setChanges(true);
                }}
              />
            )}
          </>
        )}

        {error && (
          <ErrorWrapper>
            <Text
              data={{
                text: error,
                fontStyle: "bodyXl",
                margin: "10px 0 20px 0",
              }}
            />
            <Text
              data={{
                text: "Please try another query, ensuring you are only referencing valid spreadsheets and column names",
                fontStyle: "bodyXl",
                margin: "10px 0 20px 0",
              }}
            />
            <Button
              data={{
                text: "Try Again",
                onClick: reset,
                margin: "10px 0 0 0",
              }}
            />
          </ErrorWrapper>
        )}
        {!error && finalData && (
          <>
            <ReportContent data={finalData} reset={reset} />
            <Row gap="15px">
              {!report && (
                <Button
                  data={{
                    text: "Save",
                    onClick: saveReport,
                    isFetching: isSaving,
                    size: "large",
                    type: "basic",
                    margin: "30px 0 0 0",
                  }}
                />
              )}
              {report && (
                <Button
                  data={{
                    text: "Delete",
                    onClick: deleteReport,
                    isFetching: isSaving,
                    size: "large",
                    type: "basic",
                    margin: "30px 0 0 0",
                  }}
                />
              )}
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default ReportDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: white;
  position: fixed;
  top: 0;
  left: 250px;
  bottom: 0;
  right: 0;
`;

const StepsContainer = styled.div``;

const StepContainer = styled.div`
  padding: 15px;
  border-top: 1px solid ${colors.grey2};
  :last-child {
    border-bottom: 1px solid ${colors.grey2};
  }
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  opacity: ${(p) => (p.disabled ? "0.7" : "1.0")};
  &:hover {
    background: ${colors.grey1};
  }
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 250px;
  background: white;
  padding: 20px 0 20px 0;
  border-right: 1px solid ${colors.grey2};
`;

const ErrorWrapper = styled.div`
  width: 600px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 15px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTextArea = styled.textarea`
  resize: none;
  height: fit-content;
  border: 0px;
  text-align: center;
  width: 100%;
  padding: 0px;
  font-size: 30px;
  outline: none;
  font-weight: 400;
  color: ${colors.default};
  background: transparent;
  padding: 0px;
  width: 100%;
  cursor: pointer;
  &::placeholder {
    color: ${colors.grey3};
  }
  @media screen and (max-width: 800px) {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    font-size: 14px;
  }
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  padding: 30px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  ${(p) => p.count && p.count < 4 && "grid-template-columns: 1fr 1fr;"}
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;
