import { BlockTile, BlocksContainer } from "../BlockManager/NewBlock";
import {
  Button,
  Icon,
  Input,
  Modal,
  Row,
  SelectToggle,
  Tabs,
  Text,
} from "app/components";
import { badges, colors, spacing } from "app/utils/theme";
import { getHighest, getPreviousSteps } from "app/utils/utils";
import {
  rActiveBlockId,
  rActiveVersionMap,
  rApp,
  rConfirmationModalData,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import ActionBubble from "./ActionBubble";
import { AdminForm } from "..";
import { Tooltip } from "react-tooltip";
import { get } from "lodash";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import useActions from "app/utils/useActions";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import usePage from "app/utils/usePage";
import useSetPage from "app/utils/useSetPage";

export const migrateActionSteps = (data) => {
  // Check if any item in the data has a 'parent' property
  if (data.some((item) => "parent" in item)) {
    // Some data is already in the new format, return as is
    return data;
  } else {
    // Convert data to the new format
    let new_data = [];
    let previous_id = null;
    for (let item of data) {
      let new_item = { ...item, parent: previous_id };
      new_data.push(new_item);
      previous_id = item.id;
    }
    return new_data;
  }
};

const Action = ({
  data,
  multiResourceId,
  titleIsEditable = false,
  customAction = null,
  customOnChange = null,
  disabledTypes = [],
}) => {
  const referenceLabelIsEditable = get(data, "referenceLabelIsEditable");

  const [showEditor, setShowEditor] = useState(false);

  const { activeCustomBlock } = useActiveCustomBlock();

  // const page = useRecoilValue(rPage);
  const page = usePage();

  const actions = get(page, "actions", []);
  const { setPage } = useSetPage();

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const activeApp = useRecoilValue(rApp);

  const activeVersionMap = useRecoilValue(rActiveVersionMap);

  const pageActions = get(page, "actions", []);
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);
  const pageBlocks = get(page, "blocks", []);

  const useActionsMemoObject = {
    spreadsheets: savedSpreadsheets,
    pageBlocks,
    pageActions,
    activeApp,
    setPage,
  };

  // console.log("useActionsMemoObject TEST", useActionsMemoObject);

  const actionId = get(data, "value");
  const action =
    customAction ||
    get(data, "customAction") ||
    actions.find((a) => a.id === actionId);

  useEffect(() => {
    if (showEditor && !action) {
      createNewAction();
    }
  }, [showEditor]);

  const steps = migrateActionSteps(get(action, "steps", []));

  const multiFormItemId = get(data, "multiFormItemId");

  const createNewAction = () => {
    const newId = getHighest(actions, "id") + 1;
    const newAction = {
      id: newId,
      label: `Action ${newId}`,
      steps: [
        {
          id: 1,
          type: null,
        },
      ],
    };

    const updatedBlocks = get(page, "blocks", []).map((b) => {
      if (b.id === activeBlockId) {
        if (data.isMenuBlock) {
          const items = get(b, "items", []).map((m) => {
            if (m.id === multiFormItemId) {
              return { ...m, clickAction: newId };
            }
            return m;
          });

          return { ...b, items };
        } else {
          const activeVersionId = get(activeVersionMap, activeBlockId);

          // If active block version, add action to version data
          if (activeVersionId) {
            return {
              ...b,
              versions: get(b, "versions", []).map((v) => {
                if (get(v, "id") === activeVersionId) {
                  return {
                    ...v,
                    data: { ...get(v, "data", {}), [data.id]: newId },
                  };
                }
                return v;
              }),
            };
          }

          return { ...b, [data.id]: newId };
        }
      }
      return b;
    });

    setPage({
      actions: [...actions, newAction],
      blocks: updatedBlocks,
    });
  };

  const updateData = (data, k, v) =>
    data.map((a) => (a.id === actionId ? { ...a, [k]: v } : a));

  const updateAction = (k, v) => {
    const customChange = customOnChange || get(data, "customOnChange");

    if (customChange) {
      customChange(v);
    } else {
      setPage({ actions: updateData(actions, k, v) });
    }
  };

  const updateStep = (k, v, stepId) =>
    updateAction(
      "steps",
      steps.map((s, i) => {
        if (s.id === stepId) {
          return { ...s, [k]: v };
        }
        return s;
      })
    );

  const addStep = (data) => {
    const { parent, child } = data;

    const newId = getHighest(steps, "id") + 1;

    if (child) {
      // Add new intermediate step above the given 'child' step

      let matchingStep = steps.find((s) => s.id === child);

      let modifiedSteps = steps.map((s) => {
        if (s.parent === matchingStep.parent) {
          return { ...s, parent: newId };
        }
        return s;
      });

      let newSteps = [
        ...modifiedSteps,
        { id: newId, type: null, parent: matchingStep.parent },
      ];

      updateAction("steps", newSteps);
    } else {
      // Add new child to a parent
      updateAction("steps", [...steps, { id: newId, type: null, parent }]);
    }
  };

  const clearStepType = (sId) => {
    const newSteps = steps.map((s) => {
      if (s.id === sId) {
        return { id: sId, type: null };
      }
      return s;
    });

    updateAction("steps", newSteps);
  };

  const deleteStep = (sId) => {
    const matchingStep = steps.find((s) => s.id === sId);

    const newSteps = steps
      .filter((st) => st.id !== sId)
      .map((s) => {
        if (s.parent === sId) {
          return { ...s, parent: matchingStep.parent };
        }
        return s;
      });

    updateAction("steps", newSteps);
  };

  let actionLabel = data.label;
  if (multiResourceId) {
    actionLabel = get(action, "label");
  } else if (referenceLabelIsEditable) {
    actionLabel = get(action, "referenceLabel");
  }

  const rootSteps = steps.filter((s) => !s.parent);

  const getStepData = (s) => {
    return {
      disabledTypes,
      actionId,
      data: s,
      dynamicSources: get(data, "dynamicSources", []),
      excludedSources: get(data, "excludedSources", []),
      deleteStep: () => deleteStep(s.id),
      clearStepType: () => clearStepType(s.id),
      onChange: (k, v) => updateStep(k, v, s.id),
      addStep: (v) => addStep(v),
    };
  };

  const rootStepsWithoutConditions = rootSteps.filter(
    (s) => !get(s, "conditions")
  );
  const rootConditionsWarning =
    rootSteps.length > 1 && rootStepsWithoutConditions.length > 0;

  return (
    <div className="drag-item">
      {showEditor && (
        <FullScreenEditor>
          <Header>
            {titleIsEditable || referenceLabelIsEditable ? (
              <Row alignItems={"center"}>
                <Icon
                  data={{
                    icon: "FiEdit",
                    color: colors.grey3,
                    size: 18,
                  }}
                />
                <Input
                  data={{
                    value: actionLabel,
                    minimal: true,
                    border: "0px",
                    placeholder: "Action Label",
                    padding: "5px",
                    fontStyle: "headingLg",
                    onChange: (v) =>
                      updateAction(
                        referenceLabelIsEditable ? "referenceLabel" : "label",
                        v
                      ),
                  }}
                />
              </Row>
            ) : (
              <Text
                data={{
                  text: actionLabel || "Action Label",
                  fontStyle: "headingLg",
                }}
              />
            )}

            <Row gap="15px" alignItems="center">
              {activeCustomBlock && get(activeCustomBlock, "reusable") && (
                <SelectToggle
                  data={{
                    width: "280px",
                    margin: "0 10px 0 0",
                    onChange: (v) => {
                      setPage({
                        actions: updateData(actions, "editableByUser", v),
                      });
                    },
                    tabs: [
                      {
                        label: "Not Editable",
                        value: false,
                        active: !get(action, "editableByUser"),
                      },
                      {
                        label: "Editable By User",
                        value: true,
                        active: get(action, "editableByUser"),
                      },
                    ],
                  }}
                />
              )}

              <Button
                data={{
                  text: "Add Root Step",
                  type: "basic",
                  icon: "FiPlus",
                  onClick: () => addStep({ parent: null }),
                }}
              />

              <Icon
                data={{
                  icon: "FiX",
                  size: 26,
                  onClick: () => setShowEditor(false),
                  hover: true,
                }}
              />
            </Row>
          </Header>

          <StepsContainer>
            {get(action, "editableByUser") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                  maxWidth: "600px",
                  background: "white",
                  border: `1px solid ${colors.divider}`,
                  borderRadius: "12px",
                  padding: "20px",
                }}
              >
                <Text
                  data={{
                    text: "This action is set to 'Editable By User'",
                    fontStyle: "heading2xl",
                    textAlign: "center",
                  }}
                />
                <Text
                  data={{
                    text: "This action is configurable by the user when they use this block, so it's not editable here. This allows more customization because the action is not pre-defined.",
                    fontStyle: "bodyXl",
                    textAlign: "center",
                  }}
                />
              </div>
            )}

            {!get(action, "editableByUser") && (
              <>
                <Row>
                  {rootSteps.map((r) => (
                    <NewStep
                      stepId={r.id}
                      steps={steps}
                      getStepData={getStepData}
                      conditionWarning={rootConditionsWarning}
                      useActionsMemoObject={useActionsMemoObject}
                    />
                  ))}
                </Row>

                {steps.length === 0 && (
                  <StepContainer
                    onClick={() => addStep({ parent: null })}
                    style={{ width: "fit-content" }}
                  >
                    <Row
                      justifyContent="space-between"
                      alignItems="center"
                      gap="10px"
                    >
                      <Icon
                        data={{
                          icon: "FiPlus",
                          color: colors.grey3,
                          hover: true,
                        }}
                      />
                      <Text
                        data={{
                          text: "Add Step",
                          fontStyle: "headingMd",
                          cursor: "pointer",
                        }}
                      />
                    </Row>
                  </StepContainer>
                )}
              </>
            )}
          </StepsContainer>
        </FullScreenEditor>
      )}

      <ActionBubble
        multiResourceId={multiResourceId}
        resourceId={data.label || data.id}
        action={action}
        onClick={() => setShowEditor(true)}
      />
    </div>
  );
};

export default Action;

const Step = ({
  actionId,
  data,
  onChange,
  clearStepType,
  conditionWarning,
  addStep,
  previousSteps,
  deleteStep,
  activeStepId,
  dynamicSources,
  excludedSources,
  layerColor,
  childCount,
  disabledTypes = [],
  useActionsMemoObject,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [tab, setTab] = useState("all");

  const { spreadsheets, pageBlocks, pageActions, activeApp, setPage } =
    useActionsMemoObject;

  const activeAppObject = {
    open_ai_api_key_configured: get(activeApp, "open_ai_api_key_configured"),
    show_make_action: get(activeApp, "show_make_action"),
    make_api_token: get(activeApp, "make_api_token"),
    make_organization_id: get(activeApp, "make_organization_id"),
    show_zapier_action: get(activeApp, "show_zapier_action"),
  };

  const { getStepTypes } = useActions(
    spreadsheets,
    pageBlocks,
    pageActions,
    activeAppObject,
    setPage
  );

  const resolvedStepTypes = getStepTypes({
    step: data,
    onChange,
    disabledTypes,
    actionId,
    stepId: activeStepId,
  });

  const config = resolvedStepTypes.find((st) => st.type === data.type);

  const fields = [
    ...get(config, "fields", []),
    {
      id: "conditions",
      sectionHint:
        "Set conditions to determine whether this step and any of it's children will be run or not.",
      componentId: "DisplayConditions",
      orientation: "vertical",
      section: "Run Conditions",
    },
  ];
  const otherContent = get(config, "otherContent");
  const stepLabel = data.type ? get(config, "label") : "Select Step Type";

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const editStepType = () =>
    setConfirmationModalData({
      title: "Reset Step Data?",
      text: "Changing the type will erase any current data for this step. Are you sure you want to continue?",
      confirm: () => {
        successNotification("Step Reset");
        clearStepType();
      },
    });

  const confirmDelete = () => {
    if (childCount > 0) {
      setConfirmationModalData({
        title: "Delete Action Step?",
        text: "This step has depdendencies. Any references to this step in subsequent steps will need to be manually updated.",
        confirm: () => {
          successNotification("Step Deleted");
          deleteStep(data.id);
        },
      });
    } else {
      setConfirmationModalData({
        title: "Delete Action Step?",
        text: "This is irreversible. Are you sure you want to continue?",
        confirm: () => {
          successNotification("Step Deleted");
          deleteStep(data.id);
        },
      });
    }
  };

  const editType = data.type
    ? [
        {
          text: "Edit Type",
          type: "basic",
          icon: "FiEdit",
          onClick: editStepType,
        },
      ]
    : [];

  const displayedBlockTypes = resolvedStepTypes.filter((t) => {
    return tab === "all" || t.category === tab;
  });

  const showConditionWarning = conditionWarning && !get(data, "conditions");

  return (
    <>
      {showEdit && (
        <Modal
          minWidth={config ? "450px" : "1000px"}
          background={colors.grey1}
          header={{
            title: get(
              resolvedStepTypes.find((t) => t.type === data.type),
              "label",
              "Select Step Type"
            ),
          }}
          buttons={[
            {
              text: "Delete",
              type: "basic",
              icon: "FiTrash",
              onClick: confirmDelete,
            },
            ...editType,
            // {
            //   text: "Save",
            //   onClick: () => setShowEdit(false),
            // },
          ]}
          hide={() => setShowEdit(false)}
        >
          <>
            {/* STEP TYPE SELECTION */}
            {!config && (
              <>
                <Tabs
                  data={{
                    margin: "0 0 15px 0",
                    tabs: [
                      {
                        label: "All",
                        active: tab === "all",
                        onClick: () => setTab("all"),
                      },
                      {
                        label: "Data",
                        active: tab === "data",
                        onClick: () => setTab("data"),
                      },
                      {
                        label: "Generative AI",
                        active: tab === "ai",
                        onClick: () => setTab("ai"),
                      },
                      {
                        label: "Automation",
                        active: tab === "automation",
                        onClick: () => setTab("automation"),
                      },
                      {
                        label: "Other",
                        active: tab === "other",
                        onClick: () => setTab("other"),
                      },
                    ],
                  }}
                />
                <BlocksContainer count={displayedBlockTypes.length}>
                  {displayedBlockTypes.map((t) => (
                    <BlockTile
                      disabledMessage={t.disabledMessage}
                      onClick={() => onChange("type", t.type)}
                      title={t.label}
                      description={t.description}
                      icon={t.icon || "FiGrid"}
                    />
                  ))}
                </BlocksContainer>
              </>
            )}

            {/* STEP FIELDS */}
            {config && (
              <AdminForm
                otherContent={otherContent}
                sectionPadding={"0px 0px 15px 0px"}
                previousSteps={previousSteps}
                dynamicSources={dynamicSources}
                excludedSources={excludedSources}
                gridLayout
                onChange={(k, v) => onChange(k, v)}
                fields={fields
                  .filter(
                    (f) =>
                      !f.displayCondition ||
                      (f.displayCondition && f.displayCondition(f))
                  )
                  .map((f) => ({
                    ...f,
                    value: get(data, f.id),
                  }))}
              />
            )}
          </>
        </Modal>
      )}

      <StepWrapper>
        <StepContainer
          onClick={() => setShowEdit(true)}
          onMouseEnter={() => setShowButtons(true)}
          onMouseLeave={() => setShowButtons(false)}
        >
          <Row justifyContent="space-between" alignItems="center" gap="10px">
            <Icon
              data={{ icon: get(config, "icon", "FiEdit"), color: layerColor }}
            />
            <Text
              data={{
                text: stepLabel,
                fontStyle: "headingMd",
                cursor: "pointer",
              }}
            />
            {showConditionWarning && (
              <div className={`step-${data.id}`}>
                <Tooltip
                  anchorSelect={`.step-${data.id}`}
                  place="left"
                  style={{ zIndex: 9999, width: "220px" }}
                >
                  This step does not have any conditions set. This will cause
                  issues with your conditional logic.
                </Tooltip>
                <Warning>
                  <Icon
                    data={{ icon: "FiAlertTriangle", color: "white", size: 15 }}
                  />
                </Warning>
              </div>
            )}
          </Row>
          {showButtons && (
            <>
              <AddStepIconWrapper
                style={{
                  top: "-10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Icon
                  data={{
                    icon: "FiPlus",
                    color: colors.grey2,
                    onClick: (e) => {
                      e.stopPropagation();
                      addStep({ child: data.id });
                    },
                    hover: true,
                    size: 16,
                  }}
                />
              </AddStepIconWrapper>
              <AddStepIconWrapper
                style={{
                  bottom: "-10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Icon
                  data={{
                    icon: "FiPlus",
                    color: colors.grey2,
                    onClick: (e) => {
                      e.stopPropagation();
                      addStep({ parent: data.id });
                    },
                    hover: true,
                    size: 16,
                  }}
                />
              </AddStepIconWrapper>
            </>
          )}
        </StepContainer>
      </StepWrapper>
    </>
  );
};

const AddStepIconWrapper = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${colors.grey2};
  border-radius: 50%;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: brightness(90%);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid ${colors.grey2};
  width: 100%;
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.s3};
  cursor: pointer;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  padding: ${spacing.s4};
  border-radius: 8px;
  border: 1px solid ${colors.grey2};
  width: 100%;
  background: white;
  position: relative;
  cursor: pointer;

  &:hover {
    filter: brightness(99%);
  }
`;

const FullScreenEditor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 9999;
  background: white;
  padding: 0px;
  overflow: scroll;
`;

const StepsContainer = styled.div`
  padding: 30px;
  background: ${colors.grey1};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const NewStep = ({
  stepId,
  steps,
  layer = 0,
  getStepData,
  conditionWarning,
  useActionsMemoObject,
}) => {
  const step = steps.find((step) => step.id === stepId);

  const layerColors = ["#4597f8", "#50ab64", "#df5534", "#8d4cf6"];

  const ancestorIds = getPreviousSteps(steps, stepId);

  const previousSteps = steps.filter((s, i) => ancestorIds.includes(s.id));

  const children = steps.filter(
    (step) => step.parent && step.parent === stepId
  );

  // Calculate layer color based on index and then repeat when index exceeds color array length
  const layerColor = layerColors[layer % layerColors.length];
  const childCount = children.length;

  const childrenWithoutConditions = children.filter(
    (s) => !get(s, "conditions")
  );
  const childConditionsWarning =
    children.length > 1 && childrenWithoutConditions.length > 0;

  const stepData = getStepData(step);

  return (
    <NewStepContainer>
      <StepActionContainer color={layerColor}>
        {stepData && (
          <Step
            {...stepData}
            layerColor={layerColor}
            childCount={childCount}
            previousSteps={previousSteps}
            conditionWarning={conditionWarning}
            useActionsMemoObject={useActionsMemoObject}
          />
        )}
      </StepActionContainer>

      {childCount > 0 && (
        <>
          <VerticalLine></VerticalLine>
          <StepChildren>
            {children.map((child, i) => {
              // if smallest index, right border only
              const isSmallestIndex = i === 0;

              // if largest index, left border only
              const isLargestIndex = i === childCount - 1;

              // if middle index, left and right border
              const isMiddleIndex = !isSmallestIndex && !isLargestIndex;

              return (
                <StepChildContainer>
                  {childCount > 1 && (
                    <>
                      {isSmallestIndex && (
                        <HorizontalLine
                          style={{ position: "absolute", right: 0 }}
                        />
                      )}
                      {isMiddleIndex && (
                        <HorizontalLine style={{ width: "100%" }} />
                      )}
                      {isLargestIndex && (
                        <HorizontalLine
                          style={{ position: "absolute", left: 0 }}
                        />
                      )}
                    </>
                  )}

                  <VerticalLine />
                  <NewStep
                    stepId={child.id}
                    steps={steps}
                    layer={layer + 1}
                    getStepData={getStepData}
                    conditionWarning={childConditionsWarning}
                    useActionsMemoObject={useActionsMemoObject}
                  />
                </StepChildContainer>
              );
            })}
          </StepChildren>
        </>
      )}
    </NewStepContainer>
  );
};

const NewStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
`;

const StepActionContainer = styled.div`
  border-radius: 10px;
  width: fit-content;
  color: white;
  font-weight: 500;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  font-size: 12px;
`;

const StepChildren = styled.div`
  display: flex;
`;

const StepChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const VerticalLine = styled.div`
  border-left: 1px solid grey;
  height: 20px;
  width: 1px;
`;

const HorizontalLine = styled.div`
  border-top: 1px solid grey;
  height: 1px;
  width: calc(100% / 2);
`;

const Warning = styled.div`
  background: ${badges.red};
  padding: 8px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
`;
