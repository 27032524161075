import { Button, Text } from "app/components";
import { get, isArray } from "lodash";

import Card from "./Card";
import Chart from "app/components/charts/Chart";
import { TableComponent } from "app/renderingApp/blocks/Table";
import { colors } from "app/utils/theme";
import { passesCondition } from "app/utils/utils";
import { passesFilters } from "./utils";
import { rSavedSpreadsheets } from "app/utils/recoil";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const Report = ({ data, spreadsheetId }) => {
  const navigate = useNavigate();

  return (
    <Card
      reportId={data.id}
      label={get(data, "label", "New Report")}
      type={data.component}
      // onClick={() => navigate(`/report/${data.id}`)}
    >
      <ReportContent data={data} spreadsheetId={spreadsheetId} />
    </Card>
  );
};

export default Report;

export const ReportContent = ({ data, spreadsheetId, reset }) => {
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const sheetId = data.spreadsheet || spreadsheetId;

  const matchingSpreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);

  const sheetHeaders = get(matchingSpreadsheet, "headers", []);
  const sheetData = get(matchingSpreadsheet, "data", []);

  const initialFilters = get(data, "filters", []) || [];

  const filters = initialFilters.filter((f) => sheetHeaders.includes(f.field));

  const filteredResults =
    sheetData && sheetData.filter((r) => passesFilters(r, filters, ""));

  const field = get(data, "field");
  const calculationMetric = get(data, "calculation_metric");

  const validField = !field || (field && sheetHeaders.includes(field));

  if (!validField) {
    return (
      <div>
        <Text
          data={{
            fontStyle: "bodyLg",
            text: "This query returned no results. Please ensure you're using valid spreadsheet and field names in your request.",
          }}
        />
        <Button
          data={{ text: "Try Again", onClick: reset, margin: "10px 0 0 0" }}
        />
      </div>
    );
  }

  // TABLE
  if (data.component === "Table") {
    const firstRow = get(sheetData, 0, {});
    const columns = Object.keys(firstRow);
    return (
      <div style={{ width: "100%" }}>
        <TableComponent
          data={{
            preventDarkMode: true,
            resultsPerPage: 5,
            rows: filteredResults,
            columns: columns
              .filter((c) => c !== "frontly_data")
              .map((c) => ({ key: c })),
          }}
        />
      </div>
    );
  }

  // CHART
  else if (data.component === "Chart") {
    return <Chart data={{ ...data, values: sheetData }} />;
  }

  // STAT
  else if (data.component === "Stat") {
    const dateRangeResultObject = getDateRange(data, filteredResults);

    const result = calculateMetric(filteredResults, calculationMetric, field);

    const value = dateRangeResultObject
      ? get(dateRangeResultObject, "value")
      : get(result, "value");

    let valueString = value && value.toString();
    let fontSize = 80;
    if (valueString.length > 7) {
      fontSize = 45;
    } else if (valueString.length > 5) {
      fontSize = 55;
    }

    if (dateRangeResultObject) {
      const direction = get(dateRangeResultObject, "direction");
      const growth = get(dateRangeResultObject, "growth");

      let statColor = null;
      let finalValue = value;

      if (!growth && direction) {
        statColor = direction === "up" ? "#50cd89" : "#f1416c";
      } else {
        finalValue = parseFloat(value);
      }

      // Option 1 - Display the range 2 value, but show percentage growth
      return (
        <StatValue fontSize={fontSize} color={statColor}>
          {finalValue.toLocaleString()}
          {direction && growth && (
            <GrowthDisplay direction={direction}>{growth}</GrowthDisplay>
          )}
        </StatValue>
      );
    }

    const customColor = get(data, "color");
    const format = get(data, "format");
    const decimalPoints = get(data, "decimalPoints", 2);

    let renderValue = value;

    if (format === "decimal") {
      renderValue = parseFloat(value).toFixed(decimalPoints);
    }

    if (format === "integer") {
      renderValue = parseInt(value);
    }

    return (
      <StatValue fontSize={fontSize} color={customColor}>
        {get(data, "isPrice") ? "$" : ""}
        {renderValue.toLocaleString()}
      </StatValue>
    );
  }
};

const getDateRange = (data, records) => {
  const dateRange = get(data, "date_range");

  if (!dateRange) {
    return null;
  }

  const field = get(data, "field");
  const calculationMetric = get(data, "calculation_metric");

  const metricField = get(dateRange, "metric_field");
  const range1 = get(dateRange, "range_1");
  const range2 = get(dateRange, "range_2");

  const range1Filters = [
    {
      field: metricField,
      operator: "date_after",
      value: get(range1, "start_date"),
    },
    {
      field: metricField,
      operator: "date_before",
      value: get(range1, "end_date"),
    },
  ];

  const range1data =
    records && records.filter((r) => passesFilters(r, range1Filters, ""));

  const range2Filters = [
    {
      field: metricField,
      operator: "date_after",
      value: get(range2, "start_date"),
    },
    {
      field: metricField,
      operator: "date_before",
      value: get(range2, "end_date"),
    },
  ];

  const range2data =
    records && records.filter((r) => passesFilters(r, range2Filters, ""));

  const range1Metric = calculateMetric(range1data, calculationMetric, field);
  const range2Metric = calculateMetric(range2data, calculationMetric, field);

  const val1 = get(range1Metric, "value", 0);
  const val2 = get(range2Metric, "value", 0);
  const difference = val2 - val1;
  const decimal = difference / val1;
  const decimalRounded = (difference / val1).toFixed(2);
  const percent = `${(decimal * 100).toFixed(2)}%`;

  const direction = val1 > val2 ? "down" : "up";

  const dateRangeCalculation = get(dateRange, "calculation"); // percentage_growth or growth

  if (dateRangeCalculation === "percentage_growth_value") {
    return {
      value: val2,
      growth: percent,
      direction,
    };
  } else if (dateRangeCalculation === "percentage_growth_difference") {
    return {
      value: difference,
      growth: percent,
      direction,
    };
  } else if (dateRangeCalculation === "percentage_growth_rate") {
    return {
      value: percent,
      direction,
    };
  }

  return {
    difference,
    decimalRounded,
    decimal,
    percent,
    direction,
  };
};

const StatValue = styled.div`
  font-size: ${(p) => p.fontSize}px;
  font-weight: 600;
  color: ${(p) => p.color || colors.default};
`;

const calculateMetric = (
  data,
  metric,
  field,
  value = null,
  operator = "equals"
) => {
  if (!data || !isArray(data)) {
    return {
      label: "Count",
      value: 0,
    };
  }

  switch (metric) {
    case "count":
      return {
        label: "Count",
        value: data.length,
      };
    case "field_count":
      return {
        label: "Field Count",
        value: data.filter((item) =>
          passesCondition({ value1: item[field], value2: value, operator })
        ).length,
      };
    case "percent":
      const count = data.filter((item) =>
        passesCondition({ value1: item[field], value2: value, operator })
      ).length;
      const percentage = (count / data.length) * 100;
      return {
        label: "Percentage",
        value: percentage.toFixed(1) + "%",
      };
    case "average":
      const sum = data.reduce(
        (total, item) => total + parseFloat(get(item, field) || 0),
        0
      );

      const average = sum / data.length;

      return {
        label: "Average",
        value: average.toFixed(2),
      };
    case "sum":
      const total = data.reduce(
        (acc, item) => acc + parseFloat(item[field] || 0),
        0
      );
      return {
        label: "Sum",
        value: total,
      };
    case "maximum":
      const max = Math.max(...data.map((item) => parseFloat(item[field] || 0)));
      return {
        label: "Maximum",
        value: max,
      };
    case "minimum":
      const min = Math.min(...data.map((item) => parseFloat(item[field] || 0)));
      return {
        label: "Minimum",
        value: min,
      };
    // case "unique_count":
    //   const uniqueValues = new Set(data.map((item) => item[field]));
    //   return {
    //     label: "Unique Count",
    //     value: uniqueValues.size,
    //   };
    // case "ratio":
    //   const numeratorSum = data.reduce(
    //     (acc, item) => acc + parseFloat(item[field]),
    //     0
    //   );
    //   const denominatorSum = data.reduce((acc, item) => acc + item[value], 0);
    //   const ratio = numeratorSum / denominatorSum;
    //   return {
    //     label: "Ratio",
    //     value: ratio.toFixed(2),
    //   };
    // case "boolean_count":
    //   return {
    //     label: "Boolean Count",
    //     value: data.filter((item) => item[field] === true).length,
    //   };
    // case "median":
    //   const sortedValues = data
    //     .map((item) => item[field])
    //     .sort((a, b) => a - b);
    //   const middleIndex = Math.floor(sortedValues.length / 2);
    //   const median =
    //     sortedValues.length % 2 === 0
    //       ? (sortedValues[middleIndex - 1] + sortedValues[middleIndex]) / 2
    //       : sortedValues[middleIndex];
    //   return {
    //     label: "Median",
    //     value: median.toFixed(2),
    //   };
    // case "mode":
    //   const fieldValues = data.map((item) => item[field]);
    //   const frequencies = {};
    //   fieldValues.forEach((value) => {
    //     frequencies[value] = (frequencies[value] || 0) + 1;
    //   });
    //   const maxFrequency = Math.max(...Object.values(frequencies));
    //   const mode = Object.keys(frequencies).filter(
    //     (value) => frequencies[value] === maxFrequency
    //   );
    //   return {
    //     label: "Mode",
    //     value: mode.join(", "),
    //   };
    default:
      return {
        label: "Count",
        value: 0,
      };
    // throw new Error("Invalid metric");
  }
};

const GrowthDisplay = styled.div`
  color: #78829d;
  background: #f9f9f9;
  ${(p) => p.direction === "up" && "color: #50cd89; background: #eefbec;"}
  ${(p) => p.direction === "down" && "color: #f1416c; background: #fff2f1;"}
  font-size: 12px;
  font-weight: 700;
  padding: 7px;
  border-radius: 10px;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
`;
