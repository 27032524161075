import {
  CardContent,
  Icon,
  PaginationWrapper,
  SkeletonLoader,
  Text,
} from "app/components";
import { boxShadow, colors, spacing } from "app/utils/theme";
import { get, isNil } from "lodash";
import { getBadgeColorMap, sortRecords } from "./Table";
import {
  getBlockContentHeight,
  getGridStaticItems,
  getPixels,
  resizeImage,
} from "app/utils/utils";
import {
  rApp,
  rAppDateFormat,
  rDarkMode,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useState } from "react";

import styled from "styled-components";
import useActionResolver from "../useActionResolver";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import { useRecoilValue } from "recoil";
import useUtils from "app/renderingApp/useUtils";

const Grid = ({ block, page }) => {
  const { actionResolver } = useActionResolver(page);

  const { processObjects } = useProcessObjects();

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const { getListData } = useListData();

  const darkMode = useRecoilValue(rDarkMode);

  const app = useRecoilValue(rApp);
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const data = getListData(block);
  const resultsPerPage = get(block, "resultsPerPage");
  const { recordClick, processDynamicText } = useUtils();
  const fields = get(block, "fields", []);
  const finalFields = [
    {
      mapKey: "image", // This tells processObjects to convert the mapped key to 'image'
      key: get(block, "image"),
    },
    ...fields,
  ];

  let items = processObjects(
    page,
    block,
    finalFields,
    data,
    processDynamicText
  );

  // Get user's custom defined badge colors
  const badgeColorMap = getBadgeColorMap(get(block, "badgeColors", []));

  const sortingColumn = get(block, "sortingColumn");
  const sortingDirection = get(block, "sortingDirection", "asc");

  if (sortingColumn) {
    const spreadsheet = savedSpreadsheets.find(
      (s) => s.id === block.spreadsheet
    );

    const fieldData = get(
      spreadsheet,
      ["field_data", "config", sortingColumn],
      {}
    );

    let columnType = "text";

    if (get(fieldData, "componentId") === "DateTimePicker") {
      columnType = "date";
    }

    // Sort rows
    items = sortRecords(
      items,
      sortingColumn,
      sortingDirection,
      columnType,
      get(fieldData, "dateFormat", {}),
      appDateFormat
    );
  }

  const imageFillCard = get(block, "imageFillCard");
  const gridMode = get(block, "gridMode", "dynamic");
  const gridItemMin = get(block, "cardWidth", "220px");
  const gridItemMax = gridMode === "static" ? gridItemMin : "1fr";
  const imageHeight = get(block, "imageHeight", "150px");
  const backgroundColor = get(block, "backgroundColor", "white");
  const customBadgeColors = get(block, "customBadgeColors", []);

  const height = getBlockContentHeight(app, block, items, resultsPerPage);

  return (
    <ContentContainer height={height}>
      <PaginationWrapper
        itemsPerPage={resultsPerPage}
        items={items}
        hideBorder={true}
        padding="16px 0 0 0"
        noResultsPadding="0 0 16px 0"
        darkMode={darkMode}
        isFetching={block.isFetching}
        block={block}
      >
        {(paginatedItems) => {
          return (
            <GridContainer
              gridMode={gridMode}
              itemCount={paginatedItems.length}
              gridItemMin={gridItemMin}
              gridItemMax={gridItemMax}
            >
              {!block.isFetching && paginatedItems.length === 0 && (
                <Text data={{ text: `No ${block.label}` }} />
              )}

              {block.isFetching &&
                [1, 2, 3, 4, 5, 6, 7, 8].map((r, i) => {
                  return (
                    <GridItem
                      imageHeight={imageHeight}
                      objectFit={get(block, "objectFit")}
                      imageFillCard={imageFillCard}
                      key={i}
                      item={{}}
                      fields={fields}
                      skeleton
                      backgroundColor={backgroundColor}
                      customBadgeColors={customBadgeColors}
                    />
                  );
                })}

              {!block.isFetching &&
                paginatedItems.map((item, i) => (
                  <GridItem
                    imageHeight={imageHeight}
                    blockId={block.id}
                    objectFit={get(block, "objectFit")}
                    imageFillCard={imageFillCard}
                    key={i}
                    item={{ ...item, badgeColorMap }}
                    fields={fields}
                    onClick={() => recordClick(block, item, actionResolver)}
                    backgroundColor={backgroundColor}
                    customBadgeColors={customBadgeColors}
                  />
                ))}
            </GridContainer>
          );
        }}
      </PaginationWrapper>
    </ContentContainer>
  );
};

export default Grid;

const GridItem = ({
  item,
  fields,
  onClick,
  imageFillCard,
  objectFit,
  blockId,
  imageHeight,
  skeleton,
  backgroundColor,
  customBadgeColors,
}) => {
  const app = useRecoilValue(rApp);
  const darkMode = useRecoilValue(rDarkMode);

  const styling = get(app, "styling");
  const blockBorder = get(styling, "blockBorder");
  const blockBorderRadius = get(styling, "blockBorderRadius");
  const blockBorderColor = get(styling, "blockBorderColor");
  const blockBoxShadow = get(styling, "blockBoxShadow");

  const finalBorder = blockBorder ? "1px solid " + blockBorderColor : "none";

  return (
    <GridItemContainer
      darkMode={darkMode}
      onClick={onClick}
      imageFillCard={imageFillCard}
      border={finalBorder}
      boxShadow={blockBoxShadow}
      borderRadius={blockBorderRadius}
      backgroundColor={backgroundColor}
    >
      {skeleton && (
        <SkeletonLoader
          height={imageHeight}
          width={"100%"}
          margin="0 0 15px 0"
        />
      )}

      {!skeleton && item.image && (
        <GridImage
          uniqueId={item.frontly_id}
          imageHeight={imageHeight}
          src={resizeImage({
            url: item.image,
            height: 500,
            quality: 60,
            forceJpg: true,
          })}
          imageFillCard={imageFillCard}
          objectFit={objectFit}
        />
      )}
      <ContentWrapper imageFillCard={imageFillCard} image={item.image}>
        <CardContent
          blockId={blockId}
          fields={fields}
          item={item}
          skeleton={skeleton}
          imageFillCard={imageFillCard}
          customBadgeColors={customBadgeColors}
        />
      </ContentWrapper>
    </GridItemContainer>
  );
};

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  cursor: pointer;
  padding: 0 0 5px 2px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${(p) => p.gridItemMin}, ${(p) => p.gridItemMax})
  );
  grid-auto-rows: auto;
  grid-gap: 20px;
  ${(p) =>
    p.itemCount &&
    p.itemCount < 4 &&
    `grid-template-columns: ${getGridStaticItems(p.itemCount, p.gridItemMin)};`}
  @media (max-width: 1000px) {
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
`;

const ContentContainer = styled.div`
  height: ${(p) => p.height};
  overflow-y: auto;
`;

const GridItemContainer = styled.div`
  background: ${(p) =>
    p.darkMode ? colors.darkModeLightBackground : p.backgroundColor || "white"};
  padding: ${(p) => (p.imageFillCard ? 0 : "16px")};
  border-radius: ${(p) =>
    getPixels(isNil(p.borderRadius) ? 8 : p.borderRadius)};
  box-shadow: ${(p) => (p.boxShadow === false ? null : boxShadow.card)};
  border: ${(p) => p.border};
`;

const NoImageWrapper = styled.div`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: ${spacing.s3};
  border: 1px solid ${colors.grey2};
  background: ${colors.grey1};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    height: 130px;
  }
`;

const GridImageContainer = styled.img`
  height: ${(p) => p.imageHeight};
  width: 100%;
  object-fit: ${(p) => p.objectFit || "cover"};
  border-radius: ${(p) =>
    p.imageFillCard
      ? `${p.borderRadius} ${p.borderRadius} 0 0`
      : p.borderRadius};
  margin-bottom: ${spacing.s3};
  @media (max-width: 1000px) {
    height: 130px;
  }
`;

const ContentWrapper = styled.div`
  padding: ${(p) =>
    p.imageFillCard ? `${p.image ? "0px" : "16px"} 16px 16px 16px` : "0px"};
`;

export const GridImage = ({
  src,
  imageFillCard,
  objectFit,
  imageHeight,
  uniqueId,
}) => {
  const [error, setError] = useState(false);

  const app = useRecoilValue(rApp);

  const styling = get(app, "styling");
  const blockBorderRadius = get(styling, "blockBorderRadius");
  const borderRadius = getPixels(blockBorderRadius || 8);

  useEffect(() => {
    if (src) {
      setError(false);
    }
  }, [src]);

  const invalidImage = !src || error;

  return (
    <div key={uniqueId}>
      {invalidImage ? (
        <NoImageWrapper
          key={uniqueId}
          src={src}
          imageFillCard={imageFillCard}
          imageHeight={imageHeight}
        >
          <Icon data={{ icon: "FiImage", color: colors.grey2, size: 40 }} />
        </NoImageWrapper>
      ) : (
        <GridImageContainer
          key={uniqueId}
          borderRadius={borderRadius}
          imageHeight={imageHeight}
          objectFit={objectFit}
          imageFillCard={imageFillCard}
          src={src}
          onError={() => setError(true)}
        />
      )}
    </div>
  );
};
