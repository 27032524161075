import React from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import './styles.css';

const Popover = () => (
  <RadixPopover.Root>
    <RadixPopover.Trigger asChild>
      <button className="IconButton" aria-label="Update dimensions">
      </button>
    </RadixPopover.Trigger>
    <RadixPopover.Portal>
      <RadixPopover.Content className="PopoverContent" sideOffset={5}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <p className="Text" style={{ marginBottom: 10 }}>
            Dimensions
          </p>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="width">
              Width
            </label>
            <input className="Input" id="width" defaultValue="100%" />
          </fieldset>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="maxWidth">
              Max. width
            </label>
            <input className="Input" id="maxWidth" defaultValue="300px" />
          </fieldset>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="height">
              Height
            </label>
            <input className="Input" id="height" defaultValue="25px" />
          </fieldset>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="maxHeight">
              Max. height
            </label>
            <input className="Input" id="maxHeight" defaultValue="none" />
          </fieldset>
        </div>
        <RadixPopover.Close className="PopoverClose" aria-label="Close">
        </RadixPopover.Close>
        <RadixPopover.Arrow className="PopoverArrow" />
      </RadixPopover.Content>
    </RadixPopover.Portal>
  </RadixPopover.Root>
);

export default Popover;