import { Button, Input, Row, Select, Text } from "app/components";
import { boxShadow, colors } from "app/utils/theme";
import { errorNotification, successNotification } from "app/utils/Notification";
import { rReports, rSavedSpreadsheets } from "app/utils/recoil";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminWrapper from "../home/AdminWrapper";
import { BackButton } from "../components";
import { ReportContent } from "./Report";
import ReportSettings from "./ReportSettings";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { getHighest } from "app/utils/utils";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useState } from "react";

const ReportDetails = () => {
  const params = useParams();
  const reportId =
    get(params, "id") && get(params, "id") !== "new"
      ? parseInt(get(params, "id"))
      : null;

  const navigate = useNavigate();

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const [reports, setReports] = useRecoilState(rReports);

  const report = reports.find((r) => r.id === parseInt(reportId));

  const [spreadsheetId, setSpreadsheetId] = useState(null);

  const [prompt, setPrompt] = useState("");
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [changes, setChanges] = useState(false);
  const [error, setError] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const matchingSpreadsheet = savedSpreadsheets.find(
    (s) => s.id === spreadsheetId
  );
  const sheetHeaders = get(matchingSpreadsheet, "headers", []) || [];

  const finalData = report || data;

  // RUN AI QUERY
  const runQuery = () => {
    setIsFetching(true);
    mixpanel.track("Run Report");

    apiRequest
      .post("/magic_metrics/", {
        prompt,
        sheet_headers: sheetHeaders,
        spreadsheet_id: spreadsheetId,
      })
      .then((r) => {
        const responseData = get(r, "data");
        const errorMessage = get(responseData, "error_message");

        if (errorMessage) {
          setError(errorMessage || "There was an unexpected error.");
          errorNotification(errorMessage);
        } else {
          setData(get(r, "data"));
          setChanges(false);
        }

        setIsFetching(false);
      });
  };

  // SAVE REPORT TO API
  const saveReport = () => {
    // If new report, add to list
    const newReports = reportId
      ? reports
      : [
          ...reports,
          {
            ...data,
            spreadsheet: spreadsheetId,
            id: getHighest(reports, "id") + 1,
          },
        ];

    if (reportId) {
      mixpanel.track("Edit Report");
    } else {
      mixpanel.track("Save Report");
    }

    setIsSaving(true);
    apiRequest
      .post("/app_settings/", {
        reports: newReports,
      })
      .then(() => {
        navigate("/reports");
        setReports(newReports);
        setIsSaving(false);
        successNotification("Saved!");
      });
  };

  const updateReportLabel = (newLabel) => {
    setReports(
      reports.map((r) => {
        if (r.id === parseInt(reportId)) {
          return { ...r, label: newLabel };
        }
        return r;
      })
    );
  };

  const reset = () => {
    setData(null);
    setError(false);
  };

  return (
    <AdminWrapper>
      <BackButton onClick={() => navigate("/reports")} />

      <Row alignItems="center" justifyContent="space-between">
        <Input
          data={{
            width: "100%",
            value: get(finalData, "label", "New Report"),
            padding: "0px",
            border: "0px",
            fontStyle: "headingXl500",
            background: "transparent",
            onChange: (v) => updateReportLabel(v),
          }}
        />
        <Button
          data={{
            text: "Save Report",
            isFetching: isSaving,
            onClick: saveReport,
          }}
        />
      </Row>

      <ReportDetailsWrapper>
        {reportId && (
          <ReportSidebar>
            <Text
              data={{
                text: "Settings",
                fontStyle: "headingMd",
                margin: "0 0 20px 0",
              }}
            />
            <ReportSettings reportId={reportId} />
          </ReportSidebar>
        )}

        {!error && !finalData && (
          <ReportContentWrapper>
            <Text
              data={{
                text: "Spreadsheet",
                fontStyle: "headingMd",
                margin: "0 0 10px 0",
              }}
            />
            <Select
              data={{
                width: "200px",
                value: spreadsheetId,
                onChange: (v) => setSpreadsheetId(v),
                label: "Spreadsheet",
                options: savedSpreadsheets.map((s) => ({
                  label: s.tab_name,
                  value: s.id,
                })),
              }}
            />
            <Text
              data={{
                text: "What data do you want to see?",
                fontStyle: "headingMd",
                margin: "20px 0 0 0",
              }}
            />
            <StyledTextArea
              autoFocus
              placeholder={"Show project revenue by category"}
              value={prompt}
              onChange={(e) => {
                setPrompt(e.target.value);
                setChanges(true);
              }}
            />
            <Button
              data={{
                text: "Run Custom Query",
                onClick: runQuery,
                icon: "FiArrowRight",
                size: "large",
                flippedIcon: true,
                disabled: !changes || !spreadsheetId,
                isFetching,
              }}
            />
          </ReportContentWrapper>
        )}
        {error && (
          <ReportContentWrapper>
            <Text
              data={{
                text: error,
                fontStyle: "bodyXl",
                margin: "10px 0 20px 0",
              }}
            />
            <Text
              data={{
                text: "Please try another query, ensuring you are only referencing valid spreadsheets and column names",
                fontStyle: "bodyXl",
                margin: "10px 0 20px 0",
              }}
            />
            <Button
              data={{
                text: "Try Again",
                onClick: reset,
                margin: "10px 0 0 0",
              }}
            />
          </ReportContentWrapper>
        )}
        {!error && finalData && (
          <ReportComponentCard>
            <ReportContent
              data={finalData}
              spreadsheetId={spreadsheetId}
              reset={reset}
            />
          </ReportComponentCard>
        )}
      </ReportDetailsWrapper>
    </AdminWrapper>
  );
};

export default ReportDetails;

const ReportDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 30px;
  margin-top: 25px;
`;

const ReportSidebar = styled.div`
  min-width: 200px;
  height: 100%;
  border: 1px solid ${colors.grey2};
  padding: 20px;
  height: 100%;
  border-radius: 10px;
`;

const ReportComponentCard = styled.div`
  width: 600px;
  background: white;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${boxShadow.card};
  border-radius: 15px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const ReportContentWrapper = styled.div`
  width: 600px;
  background: white;
  padding: 30px;
  box-shadow: ${boxShadow.card};
  border-radius: 15px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTextArea = styled.textarea`
  resize: none;
  height: fit-content;
  border: 0px;
  width: 100%;
  padding: 0px;
  font-size: 30px;
  outline: none;
  font-weight: 400;
  margin-top: 15px;
  color: ${colors.default};
  background: transparent;
  padding: 0px;
  width: 100%;
  cursor: pointer;
  &::placeholder {
    color: ${colors.grey3};
  }
  @media screen and (max-width: 800px) {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    font-size: 14px;
  }
`;
