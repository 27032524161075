import AdminWrapper from "./AdminWrapper";
import { AppsContent } from "./Apps";
import DataSourcesCard from "./DataSourcesCard";
import RecentAiSessions from "./RecentAiSessions";
import Resources from "./Resources";
import { TemplatesSection } from "../appManagement/Templates";
import styled from "styled-components";

const Home = () => {
  return (
    <AdminWrapper>
      <Sections>
        <RecentAiSessions />
        <DataSourcesCard />
        <TemplatesSection />
        <Resources />
        <AppsContent itemsPerPage={8} />
      </Sections>
    </AdminWrapper>
  );
};

export default Home;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
