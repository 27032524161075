import { defaultTrue, safeArray } from "app/utils/utils";
import { rActiveDetailViewId, rApp, rPage } from "app/utils/recoil";

import AdminForm from "app/adminApp/components/AdminForm";
import { get } from "lodash";
import useBlockVersion from "app/utils/useBlockVersion";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";

const DetailViewSettings = () => {
  const { setBlock } = useSetPage();

  // const page = useRecoilValue(rPage);
  const page = usePage();

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const activeApp = useRecoilValue(rApp);

  const blocks = get(page, "blocks", []);
  let detailViewBlock = blocks.find((b) => b.id === activeDetailViewId);

  const { getBlockWithVersion } = useBlockVersion();
  const { blockWithVersion } = getBlockWithVersion(detailViewBlock);
  detailViewBlock = blockWithVersion;

  const relationFieldKeys = safeArray(activeApp, "data_relations")
    .filter((r) => r.sheet2 === get(detailViewBlock, "spreadsheet"))
    .map((r) => r.column2);

  const allowEditing = defaultTrue(get(detailViewBlock, "showEdit"));

  const defaultSettings = {
    componentId: "StaticFields",
    formContext: "detailView", // This is used to help with the field click editing system
    section: "Fields",
    id: "fieldData",
    customSpreadsheetId: get(detailViewBlock, "spreadsheet"),
    value: get(detailViewBlock, "fieldData", {}),
    keys: [
      {
        id: "label",
        label: "Label",
        hint: "Override the default label",
        componentId: "Input",
        required: true,
        defaultValue: "",
      },
      {
        id: "componentId",
        label: "Field Type",
        hint: "Define how the column should be displayed visually in forms and other blocks",
        componentId: "Select",
        required: true,
        defaultValue: "Input",
        options: [
          { label: "Input", value: "Input" },
          { label: "Select", value: "Select" },
          { label: "Multi Select", value: "MultiSelect" },
          { label: "Switch", value: "Switch" },
          { label: "Checkbox", value: "Checkbox" },
          { label: "Text Area", value: "TextArea" },
          { label: "Date", value: "DateTimePicker" },
          // { label: "Time", value: "TimePicker" },
          { label: "Image", value: "ImageUpload" },
          { label: "File Upload", value: "FileUpload" },
          { label: "Markdown", value: "MarkdownEditor" },
          { label: "Hidden", value: "Hidden" },
        ],
      },
      {
        id: "ignoreLinks",
        label: "Ignore Links",
        componentId: "Switch",
        orientation: "horizontal",
        hint: "By default, we'll detect links in the text and make them clickable and highlighted. Turn this off to disable this behavior.",
        displayCondition: () => !allowEditing,
      },
      {
        id: "hiddenValue",
        label: "Hidden Value",
        componentId: "DynamicString",
        displayCondition: (f) => get(f, "componentId") === "Hidden",
      },
      {
        id: "defaultValue",
        label: "Default Value",
        hint: "Provide a default value for this field",
        componentId: "DynamicString",
        required: false,
        advanced: true,
      },
      {
        id: "description",
        label: "Description",
        hint: "Display a description for this field",
        componentId: "Input",
        required: false,
        defaultValue: "",
        advanced: true,
      },
      {
        id: "hint",
        label: "Hint",
        hint: "Display a hint for this field",
        componentId: "Input",
        required: false,
        defaultValue: "",
        advanced: true,
      },
      {
        id: "placeholder",
        label: "Placeholder",
        hint: "Display a placeholder for this field",
        componentId: "Input",
        required: false,
        defaultValue: "",
        advanced: true,
      },
      {
        id: "options",
        label: "Options",
        componentId: "DataGrid",
        orientation: "vertical",
        hint: "Define the options to appear in your select dropdown. Auto detected any unique values in the sheet. Adjust them as you see fit.",
        requiresSheet: true,
        newObject: { label: "New Item", value: "New Value" },
        columns: [
          {
            key: "label",
            componentId: "Input",
          },
          {
            key: "value",
            componentId: "Input",
          },
        ],
        displayCondition: (field) =>
          ["Select", "MultiSelect"].includes(get(field, "componentId")),
      },
      {
        id: "selectSorting",
        label: "Sort Options",
        componentId: "Select",
        requiresSheet: true,
        hideEmptyItem: true,
        options: [
          { label: "None", value: null },
          { label: "Ascending", value: "asc" },
          { label: "Descending", value: "desc" },
        ],
        advanced: true,
        displayCondition: (field) =>
          ["Select", "MultiSelect"].includes(get(field, "componentId")),
      },
      {
        id: "validation",
        label: "Validation",
        componentId: "FormValidation",
        block: detailViewBlock,
      },
      {
        id: "conditions",
        label: "Display Conditions",
        componentId: "DisplayConditions",
        orientation: "vertical",
        allowSpreadsheet: true,
        advanced: true,
      },
      {
        id: "columnSpan",
        label: "Column Span",
        hint: "Force this field to take up more than 1 field's width in the form. Usful for large fields like text areas that need more space.",
        componentId: "Select",
        options: [
          {
            label: "1 Column",
            value: 1,
          },
          {
            label: "2 Columns",
            value: 2,
          },
          {
            label: "3 Columns",
            value: 3,
          },
        ],
        required: false,
        defaultValue: 1,
        advanced: true,
      },
      {
        id: "minHeight",
        label: "Min Height",
        hint: "Set a minimum default height for the TextArea field.",
        componentId: "Select",
        options: [
          {
            label: "100px",
            value: "100px",
          },
          {
            label: "150px",
            value: "150px",
          },
          {
            label: "200px",
            value: "200px",
          },
          {
            label: "300px",
            value: "300px",
          },
        ],
        displayCondition: (f) => get(f, "componentId") === "TextArea",
        required: false,
      },
      {
        id: "disabled",
        label: "Disabled",
        hint: "Prevent the field from being edited",
        componentId: "Switch",
        required: true,
        defaultValue: false,
      },

      // Show hidden filters here if select dropdown AND has a relation
      {
        id: "hiddenFilters",
        label: "Hidden Filters",
        componentId: "HiddenFiltersConfig",
        showOnlyRelationFields: true,
        requiresSheet: true,
        orientation: "vertical",
        hint: "Filter the initial data that users can access",
        link: "https://help.frontly.ai/en/articles/7971258-hidden-filters",
        displayCondition: (fieldData, fieldId) =>
          relationFieldKeys.includes(fieldId),
      },
    ],
  };

  const recordClickAction = get(
    detailViewBlock,
    "recordClickAction",
    "default"
  );

  let fields = [
    {
      id: "recordClickAction",
      componentId: "SelectToggle",
      section: "detailViewType",
      defaultValue: "default",
      requiresSheet: true,
      hideEmptyItem: true,
      sectionHint:
        "Determine what to do when a user clicks on a record in a list of data",
      link: "https://help.frontly.ai/en/articles/7971235-record-click-action",
      tabs: [
        {
          label: "Default",
          value: "default",
          active: !recordClickAction || recordClickAction === "default",
        },
        {
          label: "Custom",
          value: "custom",
          active: recordClickAction === "custom",
        },
      ],
    },
  ];

  const detailCreateAction = get(detailViewBlock, "detailCreateSubmitAction");
  const detailEditAction = get(detailViewBlock, "detailEditSubmitAction");

  if (get(detailViewBlock, "recordClickAction") === "default") {
    fields = [
      ...fields,
      {
        id: "showEdit",
        label: "Allow Editing Records",
        componentId: "Switch",
        section: "permissions",
        defaultValue: true,
        requiresSheet: true,
        orientation: "horizontal",
        value: allowEditing,
        hint: "Allow users to edit the records in a form.",
      },
      {
        id: "showDelete",
        label: "Allow Deleting Records",
        componentId: "Switch",
        section: "permissions",
        defaultValue: true,
        requiresSheet: true,
        orientation: "horizontal",
        value: defaultTrue(get(detailViewBlock, "showDelete")),
        hint: "Display a 'Delete' button that lets the user delete records that they have access to in the selected spreadsheet",
      },
      {
        id: "detailEditSubmitAction",
        label: "Edit Mode Submit Action",
        section: "actions",
        hint: "This action runs in addition to the default update action",
        isAction: true,
        componentId: "Action",
        dynamicSources: ["form"],
        customAction: detailEditAction,
        customOnChange: (newAction) => {
          setBlock(
            {
              detailEditSubmitAction: {
                ...detailEditAction,
                steps: newAction,
              },
            },
            {},
            activeDetailViewId
          );
        },
      },
      {
        id: "detailCreateSubmitAction",
        label: "Create Mode Submit Action",
        section: "actions",
        dynamicSources: ["form"],
        excludedSources: ["detail"],
        hint: "This action runs in addition to the default create action",
        isAction: true,
        componentId: "Action",
        customAction: detailCreateAction,
        customOnChange: (newAction) => {
          setBlock(
            {
              detailCreateSubmitAction: {
                ...detailCreateAction,
                steps: newAction,
              },
            },
            {},
            activeDetailViewId
          );
        },
      },
      defaultSettings,
    ];
  }

  fields = [
    ...fields,
    {
      id: "customViewLabel",
      section: "customViewLabel",
      componentId: "DynamicString",
      requiresSheet: true,
      sectionHint: "Define a label for the custom view",
      value: get(detailViewBlock, "customViewLabel"),
    },
    {
      id: "blockHierarchy",
      componentId: "BlockHierarchy",
      sectionHint: "Configure the blocks on this page",
      section: "blocks",
      orientation: "vertical",
    },
  ];

  return (
    <AdminForm
      fields={fields}
      borderBottom={true}
      onChange={(key, value) =>
        setBlock({ [key]: value }, {}, activeDetailViewId)
      }
    />
  );
};

export default DetailViewSettings;
