import { Tabs } from "./components";
import { get } from "lodash";
import styled from "styled-components";
import { useState } from "react";

const Workflow = ({ data = [] }) => {
  const [workflowIndex, setWorkflowIndex] = useState(0);

  const workflow = get(data, workflowIndex, {});

  const steps = get(workflow, "steps", []);

  const topLayer = steps.filter((s) => !s.parent);

  return (
    <Container>
      <Tabs
        data={{
          margin: "0 0 30px 0",
          tabs: data.map((wf, wfi) => {
            const { role } = wf;
            return {
              label: role,
              onClick: () => setWorkflowIndex(wfi),
              active: wfi === workflowIndex,
            };
          }),
        }}
      />
      <StepsContainer>
        {topLayer.map((step) => (
          <Step stepId={step.id} steps={steps} layer={0} />
        ))}
      </StepsContainer>
    </Container>
  );
};

export default Workflow;

const colors = ["#4597f8", "#50ab64", "#df5534", "#8d4cf6"];

const Step = ({ stepId, steps, layer = 0 }) => {
  const step = steps.find((step) => step.id === stepId);

  const children = steps.filter(
    (step) => step.parent && step.parent === stepId
  );

  // Calculate layer color based on index and then repeat when index exceeds color array length
  const layerColor = colors[layer % colors.length];
  const childCount = children.length;

  return (
    <StepContainer>
      <StepActionContainer color={layerColor}>
        {get(step, "action")}
      </StepActionContainer>

      {childCount > 0 && (
        <>
          <VerticalLine></VerticalLine>
          <StepChildren>
            {children.map((child, i) => {
              // if smallest index, right border only
              const isSmallestIndex = i === 0;

              // if largest index, left border only
              const isLargestIndex = i === childCount - 1;

              // if middle index, left and right border
              const isMiddleIndex = !isSmallestIndex && !isLargestIndex;

              return (
                <StepChildContainer>
                  {childCount > 1 && (
                    <>
                      {isSmallestIndex && (
                        <HorizontalLine
                          style={{ position: "absolute", right: 0 }}
                        />
                      )}
                      {isMiddleIndex && (
                        <HorizontalLine style={{ width: "100%" }} />
                      )}
                      {isLargestIndex && (
                        <HorizontalLine
                          style={{ position: "absolute", left: 0 }}
                        />
                      )}
                    </>
                  )}

                  <VerticalLine />
                  <Step stepId={child.id} steps={steps} layer={layer + 1} />
                </StepChildContainer>
              );
            })}
          </StepChildren>
        </>
      )}
    </StepContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
`;

const StepActionContainer = styled.div`
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
  background: ${(p) => p.color};
  color: white;
  font-weight: 500;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  font-size: 12px;
`;

const StepChildren = styled.div`
  display: flex;
`;

const StepChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const VerticalLine = styled.div`
  border-left: 1px solid grey;
  height: 20px;
  width: 1px;
`;

const HorizontalLine = styled.div`
  border-top: 1px solid grey;
  height: 1px;
  width: calc(100% / 2);
`;
