import React from 'react';
import * as RadxiToggleGroup from '@radix-ui/react-toggle-group';
import { TextAlignLeftIcon, TextAlignCenterIcon, TextAlignRightIcon } from '@radix-ui/react-icons';
import './styles.css';

const ToggleGroup = () => (
  <RadxiToggleGroup.Root
    className="ToggleGroup"
    type="single"
    defaultValue="center"
    aria-label="Text alignment"
  >
    <RadxiToggleGroup.Item className="ToggleGroupItem" value="left" aria-label="Left aligned">
      <TextAlignLeftIcon />
    </RadxiToggleGroup.Item>
    <RadxiToggleGroup.Item className="ToggleGroupItem" value="center" aria-label="Center aligned">
      <TextAlignCenterIcon />
    </RadxiToggleGroup.Item>
    <RadxiToggleGroup.Item className="ToggleGroupItem" value="right" aria-label="Right aligned">
      <TextAlignRightIcon />
    </RadxiToggleGroup.Item>
  </RadxiToggleGroup.Root>
);

export default ToggleGroup;