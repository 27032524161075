import { Column, Text } from "app/components";
import React, { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";

import { AdminForm } from "app/adminApp/components";
import { colors } from "app/utils/theme";
import { isFrontlyAdmin } from "app/utils/utils";
import { rApp } from "app/utils/recoil";
import styled from "styled-components";
import useActionResolver from "app/renderingApp/useActionResolver";
import { useRecoilValue } from "recoil";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";
import useUtils from "app/renderingApp/useUtils";
import useValidateFields from "app/utils/validation";

export const getValidationObject = (inputs) => {
  let obj = {};

  const validations = [
    "isPrice",
    "isAlphabetical",
    "isNumber",
    "isEmail",
    "required",
    "minOffset",
    "maxOffset",
    "customRegex",
    "customRegexError",
  ];

  inputs.forEach((field) => {
    let fieldObj = {};

    const isBoolean = ["Switch", "Checkbox"].includes(field.componentId);

    validations.forEach((validation) => {
      if (field[validation]) {
        fieldObj[validation] = field[validation];
      }
    });
    const required = get(field, "required") === false ? false : true;

    if (required && !isBoolean) {
      fieldObj["required"] = required;
    }

    obj[field.cell] = fieldObj;
  });

  return obj;
};

const QuoteCalculator = ({ page, block }) => {
  const inputs = get(block, "inputs", []);
  const resultsCells = get(block, "results", []);
  const { actionResolver } = useActionResolver(page);

  const validationObj = getValidationObject(inputs);

  const activeApp = useRecoilValue(rApp);

  const { passesDisplayConditions } = useUtils();

  const [isFetching, setIsFetching] = useState(false);
  const [errors, setErrors] = useState({});

  const [inputData, setInputData] = useState({});
  const [results, setResults] = useState();

  const { updateAndRetreive } = useSpreadsheetRequests();

  const inputsLabel = get(block, "inputsLabel") || "Inputs";
  const resultsLabel = get(block, "resultsLabel") || "Results";
  const buttonLabel = get(block, "buttonLabel") || "Get Quote";

  const { validateFields } = useValidateFields();

  useEffect(() => {
    // set defaults
    const defaultValues = {};
    inputs.forEach((i) => {
      defaultValues[i.cell] = i.defaultValue;
    });
    setInputData(defaultValues);
  }, []);

  const getQuote = () => {
    if (isEmpty(validateFields(validationObj, inputData, true, setErrors))) {
      setIsFetching(true);

      updateAndRetreive({
        spreadsheetId: block.spreadsheet,
        updates: inputData,
        results: resultsCells.map((r) => r.cell),
      }).then((res) => {
        setResults(res);
        setIsFetching(false);

        if (get(block, "submitAction")) {
          actionResolver({
            rawAction: get(block, ["actionMap", "submitAction"]),
            actionId: get(block, "submitAction"),
            context: {
              results: res,
              inputs: inputData,
            },
          });
        }
      });
    }
  };

  const finalFields = inputs
    .map((cell) => {
      return {
        ...cell,
        id: get(cell, "cell"),
        preventExtras: true,
        required: true,
        type: cell.isNumber ? "number" : "text",
        label: get(cell, "label"),
        value: get(inputData, get(cell, "cell")),
      };
    })
    .filter((f) => f.cell)
    .filter((f) => {
      return passesDisplayConditions({
        conditions: get(f, "conditions", []),
        context: {
          calculatorInput: inputData,
        },
      });
    });

  return (
    <RowContainer>
      <ResultsContainer>
        <Text
          data={{
            text: inputsLabel,
            fontStyle: "headingLg",
            margin: "0 0 25px 0",
          }}
        />
        <AdminForm
          gridLayout
          isFetching={isFetching}
          onChange={(k, v) => setInputData({ ...inputData, [k]: v })}
          sectionPadding={"15px 0px 0px 0px"}
          firstSectionPadding={"0px"}
          borderBottom={false}
          errors={errors}
          submit={getQuote}
          submitText={buttonLabel}
          fields={finalFields}
          buttonColor={get(activeApp, "primary_color")}
        />
      </ResultsContainer>

      <ResultsContainer>
        <Text
          data={{
            text: resultsLabel,
            fontStyle: "headingLg",
            margin: "0 0 25px 0",
          }}
        />

        {isFrontlyAdmin || results ? (
          <ResultsList>
            {resultsCells.map((r) => (
              <Column gap="4px">
                <Text data={{ text: r.label, fontStyle: "headingSm" }} />
                <Text
                  data={{
                    text: isFrontlyAdmin
                      ? "Example Value"
                      : get(results, r.cell),
                    fontStyle: "bodyLg",
                    allowSelect: true,
                  }}
                />
              </Column>
            ))}
          </ResultsList>
        ) : (
          <Text
            data={{
              text: "Complete the form in the Inputs section to see your results",
              fontStyle: "bodyMd",
            }}
          />
        )}
      </ResultsContainer>
    </RowContainer>
  );
};

export default QuoteCalculator;

const ResultsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const RowContainer = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: 800px) {
    gap: 15px;
    flex-direction: column;
  }
`;

const ResultsContainer = styled.div`
  flex: 1;
  border: 1px dashed ${colors.divider};
  padding: 30px;
  border-radius: 10px;
  @media (max-width: 800px) {
    padding: 20px;
  }
`;
