import {
  Button,
  Icon,
  Modal,
  PopupMenu,
  Row,
  Select,
  Spinner,
} from "app/components";
import { boxShadow, colors } from "app/utils/theme";
import {
  rApp,
  rApps,
  rClearConfig,
  rOrganization,
  rUser,
} from "app/utils/recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Cookies from "js-cookie";
import { addSlash } from "app/utils/utils";
import { get } from "lodash";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import useFetchAppConfig from "app/utils/useFetchAppConfig";
import { useState } from "react";

const AdminWrapper = ({
  children,
  contentWidth = null,
  padding = "40px",
  isFetching = false,
}) => {
  const location = useLocation();

  const path = get(location, "pathname");

  const fetchAppConfig = useFetchAppConfig();

  const user = useRecoilValue(rUser);

  const clearConfig = useSetRecoilState(rClearConfig);

  const app = useRecoilValue(rApp);

  const organization = useRecoilValue(rOrganization);

  const isExpert = get(organization, "is_expert");
  const subscription = get(organization, "subscription");

  const currentPlan = get(subscription, "plan");

  const helpCards = [
    {
      title: "YouTube",
      description: "Watch video tutorials and guides on our YouTube channel.",
      onClick: () => window.open("https://www.youtube.com/@frontlyapp"),
      linkText: "Go to YouTube",
      icon: "FiYoutube",
    },
    {
      title: "Help Articles",
      description: "Browse our extensive collection of help articles.",
      onClick: () => window.open("https://help.frontly.ai"),
      linkText: "Read Help Articles",
      icon: "FiBook",
    },
    {
      title: "Experts Marketplace",
      description: "Find and hire experts for your projects.",
      onClick: () => navigate("/experts-marketplace"),
      linkText: "Explore Experts Marketplace",
      icon: "FiUsers",
    },
    {
      title: "Discord Community",
      description: "Join 1200+ users for discussion, support and inspiration.",
      onClick: () => window.open("https://discord.gg/8wnQndpBGU"),
      linkText: "Visit Community Forum",
      icon: "FiMessageCircle",
    },
  ];

  const community = [
    {
      icon: "FiLifeBuoy",
      label: "Resources",
      onClick: () => setShowHelp(true),
    },
  ];

  const currentApp = [
    {
      icon: "FiDatabase",
      label: "Data Sources",
      route: "/spreadsheets",
    },
    {
      icon: "FiFile",
      label: "Pages",
      route: "/pages",
    },
    {
      icon: "FiUsers",
      label: "Users",
      route: "/users",
    },
    {
      icon: "FiClipboard",
      label: "Forms",
      route: "/forms",
    },
    {
      icon: "FiSettings",
      label: "Settings",
      route: "/settings",
    },
  ];

  let homeSection = [
    {
      icon: "FiHome",
      label: "Home",
      route: "/home",
    },
    {
      icon: "FiGrid",
      label: "Apps",
      route: "/apps",
    },
  ];

  const apps = useRecoilValue(rApps);

  const navigate = useNavigate();

  const switchApp = (appId) => {
    fetchAppConfig({
      appId,
      callback: () => navigate("/pages"),
    });
  };

  let popupLinks = [
    {
      text: "Assets",
      icon: "FiImage",
      onClick: () => navigate("/assets"),
    },
    {
      text: "Billing",
      icon: "FiCreditCard",
      onClick: () => navigate("/billing"),
    },
    {
      text: "Expert Projects",
      icon: "FiLayers",
      onClick: () => navigate("/projects"),
    },
    {
      text: "Refer Users",
      icon: "BsMegaphone",
      onClick: () =>
        window.open("https://referrals.frontly.ai/signup?campaign=referrals"),
    },
    // {
    //   text: "Reports",
    //   onClick: () => navigate("/reports"),
    // },
    {
      text: "Logout",
      icon: "FiLogOut",
      onClick: () => {
        clearConfig();
        Cookies.remove("accessToken");
        localStorage.removeItem("appId");
        successNotification("Logged out.");
        navigate("/login");
      },
    },
  ];

  if (isExpert) {
    popupLinks = [
      {
        text: "My Expert Profile",
        icon: "FiUser",
        onClick: () => navigate("/expert-profile"),
      },
      ...popupLinks,
    ];
  }

  const [showHelp, setShowHelp] = useState(false);

  const getAppLink = (app) => {
    const customDomain = get(app, ["custom_domain", "custom_domain"]);

    const domain = customDomain || get(app, "subdomain");

    const appUrl = customDomain
      ? `https://${customDomain}`
      : `https://${domain}.frontly.ai`;

    return appUrl;
  };

  return (
    <Container>
      {showHelp && (
        <Modal
          minWidth="650px"
          hide={() => setShowHelp(false)}
          header={{
            title: "Need help? We've got you covered",
          }}
        >
          <HelpCardsContainer>
            {helpCards.map((card) => (
              <HelpCard data={card} />
            ))}
          </HelpCardsContainer>
        </Modal>
      )}
      <Body>
        <Sidebar>
          <LogoContainer>
            <Logo src="https://res.cloudinary.com/frontly/image/upload/v1686583732/admin/frontly_black_abjsh6.svg" />
          </LogoContainer>

          <Row
            alignItems="center"
            gap="5px"
            style={{
              padding: "12px",
              borderBottom: `1px solid ${colors.grey15}`,
            }}
          >
            <Select
              data={{
                hideEmptyItem: true,
                width: "171px",
                borderRadius: "10px",
                value: app.id,
                options: apps.map((a) => ({ label: a.name, value: a.id })),
                onChange: (v) => switchApp(v),
              }}
            />
            <Button
              data={{
                onClick: () => window.open(getAppLink(app)),
                type: "basic",
                icon: "FiExternalLink",
                height: "39px",
              }}
            />
          </Row>

          <SplitContent>
            <div>
              <ItemsList>
                {homeSection.map((item) => {
                  const isActive = addSlash(path).includes(
                    addSlash(item.route)
                  );
                  return <Item item={item} active={isActive} />;
                })}
              </ItemsList>

              <SectionLabel>Current App</SectionLabel>
              <ItemsList>
                {currentApp.map((item) => {
                  const isActive = addSlash(path).includes(
                    addSlash(item.route)
                  );
                  return <Item item={item} active={isActive} />;
                })}
              </ItemsList>

              <SectionLabel>Resources</SectionLabel>
              <ItemsList>
                {community.map((item) => {
                  const isActive = addSlash(path).includes(
                    addSlash(item.route)
                  );
                  return <Item item={item} active={isActive} />;
                })}
              </ItemsList>
            </div>

            <BottomSectionContainer>
              {!currentPlan && !path.includes("billing") && (
                <UpgradeButton onClick={() => navigate("/billing")}>
                  <div>Upgrade To Publish</div>
                  <Icon data={{ icon: "FiZap", size: 20, hover: true }} />
                </UpgradeButton>
              )}

              <PopupMenuWrapper>
                <PopupMenu
                  data={{
                    backgroundColor: "#9a9db3",
                    textColor: "white",
                    width: "160px",
                    activeUser: user,
                    links: popupLinks,
                  }}
                />
              </PopupMenuWrapper>
            </BottomSectionContainer>
          </SplitContent>
        </Sidebar>
        <Content>
          <ContentWidth width={contentWidth} padding={padding}>
            {isFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <Spinner size={40} />
              </div>
            ) : (
              children
            )}
          </ContentWidth>
        </Content>
      </Body>
    </Container>
  );
};

const HelpCard = ({ data: { title, description, icon, onClick } }) => (
  <HelpCardContainer onClick={onClick}>
    <div style={{ height: "25px" }}>
      <Icon data={{ icon, size: 30, color: colors.primary }} />
    </div>
    <div>
      <div style={{ fontSize: "20px", fontWeight: 500, textAlign: "center" }}>
        {title}
      </div>
      <div
        style={{
          fontSize: "16px",
          color: colors.grey3,
          textAlign: "center",
          margin: "4px 0 0 0",
        }}
      >
        {description}
      </div>
    </div>
  </HelpCardContainer>
);

const UpgradeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background: ${colors.primary};
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;
  margin-bottom: 20px;
  &:hover {
    filter: brightness(1.1);
  }
`;

const BottomSectionContainer = styled.div``;

const PopupMenuWrapper = styled.div`
  margin: 0 0 5px 0;
  padding: 8px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid ${colors.pitchBorder};
  &:hover {
    background: ${colors.clickUpBackground};
  }
`;

const HelpCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(261px, 1fr));
  }
  gap: 20px;
  margin-top: 20px;
`;

const HelpCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  background: white;
  box-shadow: rgba(29, 29, 31, 0.05) 0px 4px 5px;
  cursor: pointer;
  transition: box-shadow 0.2s;
  border: 1px solid ${colors.grey2};
  &:hover {
    box-shadow: rgba(29, 29, 31, 0.1) 0px 8px 10px;
  }
`;

const Item = ({ item, active }) => {
  const navigate = useNavigate();

  // Get onClick
  let onClick = null;
  if (item.onClick) {
    onClick = item.onClick;
  } else if (item.link) {
    onClick = () => window.open(item.link);
  } else if (item.route) {
    onClick = () => navigate(item.route);
  }

  return (
    <ItemContainer active={active} onClick={onClick}>
      <Icon
        data={{
          icon: item.icon,
          size: 20,
          color: active ? colors.primary : colors.default,
        }}
      />
      <ItemLabel active={active}>{item.label}</ItemLabel>
      {item.badge && (
        <div
          style={{
            background: colors.primary,
            color: "white",
            borderRadius: "4px",
            padding: "2px 4px 2px 4px",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          {item.badge}
        </div>
      )}
    </ItemContainer>
  );
};

export default AdminWrapper;

const SplitContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  padding: 12px;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Body = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const Sidebar = styled.div`
  align-items: flex-start;
  min-width: 240px;
  overflow-y: auto;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e0e1e6;
  // box-shadow: ${boxShadow.card};
  z-index: 9999;
`;

const Content = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  // background: #f3f4f7;
  background: #f7f8fc;
`;

const ContentWidth = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${(p) => p.padding};
  flex: 1;
`;

const LogoContainer = styled.div`
  border-bottom: 1px solid ${colors.grey15};
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 12px;
`;

const Logo = styled.img`
  height: 40px;
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 11px 11px 11px 11px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  ${(p) =>
    p.active
      ? `
        background: ${colors.primaryUltraLight};
      `
      : `
    &:hover {
      background: rgb(238, 238, 240, 0.5);
    }
  `};
`;

const ItemLabel = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: ${(p) => (p.active ? colors.primary : colors.default)};
`;

const SectionLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.grey3};
  padding: 0 22px 5px 11px;
  margin-top: 15px;
`;
