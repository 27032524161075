import { Icon } from "app/components";
import { colors } from "app/utils/theme";
import styled from "styled-components";

export const SettingsWrapper = ({ label, sections, tab, children }) => {
  return (
    <Row style={{ height: "100%", padding: "40px", gap: "50px" }}>
      <SettingsSections label={label} sections={sections} tab={tab} />
      <Container>{children}</Container>
    </Row>
  );
};

const SettingsSections = ({ label, sections, tab }) => {
  return (
    <Sidebar>
      {/* <Text
        data={{
          text: label,
          fontStyle: "headingLg",
          margin: "5px 5px 20px 5px",
        }}
      /> */}
      <SectionsContainer>
        {sections.map((section) => {
          const active = section.id === tab;

          return (
            <SettingItem
              key={section.id}
              onClick={section.onClick}
              active={active}
            >
              <Icon
                data={{
                  icon: section.icon,
                  color: colors.grey4,
                  size: 20,
                  hover: true,
                }}
              />
              <ItemText active={active}>{section.label}</ItemText>
            </SettingItem>
          );
        })}
      </SectionsContainer>
    </Sidebar>
  );
};

const Row = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  overflow-y: auto;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  min-width: 230px;
  overflow: hidden;
  padding: 10px 10px 10px 10px;
  border: 1px solid ${colors.divider};
  border-radius: 12px;
  height: fit-content;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SettingItem = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 11px 11px 11px 11px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  ${(p) =>
    p.active
      ? `background: rgb(238, 238, 240)`
      : `
        &:hover {
          background: rgb(238, 238, 240, 0.5);
        }
  `};
`;

const ItemText = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: ${colors.grey4};
`;
