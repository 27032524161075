import { getGridStaticItems, getPixels } from "app/utils/utils";

import styled from "styled-components";

// scrollbar-width: none;
// -ms-overflow-style: none;
// &::-webkit-scrollbar {
//   display: none;
// }

export const CustomBlockContainer = styled.div`
  width: 100%;
  display: grid;
  cursor: pointer;
  padding: 0 0 5px 2px;
  margin-bottom: ${(p) => (p.repeating ? "20px" : "0px")};
  grid-gap: ${(p) => getPixels(p.gap || 25)};

  ${(p) =>
    p.containerType === "carousel" &&
    `
    grid-auto-flow: column;
    grid-auto-columns: ${getPixels(p.minWidth)};
    overflow-x: auto;
    white-space: nowrap;
  `}

  ${(p) =>
    p.containerType === "grid" &&
    `
      grid-template-columns: repeat(
        auto-fit,
        minmax(${getPixels(p.minWidth)}, 1fr)
      );
      grid-auto-rows: auto;

      ${
        p.itemCount && p.itemCount < 4
          ? `grid-template-columns: ${getGridStaticItems(
              p.itemCount,
              getPixels(p.minWidth)
            )};`
          : ""
      }
  `}
`;

// @media screen and (max-width: 800px) {
//   grid-gap: 12px;
//   grid-template-columns: 1fr;
// }

// grid-template-columns: repeat(auto-fill, 200px);
// grid-auto-rows: 200px;
