import {
  getHighest,
  isFrontlyAdmin,
  migrateConditionFormat,
  passesCondition,
} from "app/utils/utils";

import { get } from "lodash";
import { getViewLabel } from "app/renderPage/utils";
import { rAppDateFormat } from "app/utils/recoil";
import useDynamicText from "./useDynamicText";
import useModalStateData from "app/useModalStateData";
import { useRecoilValue } from "recoil";

const useUtils = () => {
  const { processDynamicText } = useDynamicText();

  const { modalStack, setModalStack } = useModalStateData();

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const addModalToStack = (context) => {
    const newStackItemId = getHighest(modalStack, "id") + 1;
    const newItem = { id: newStackItemId, ...context };
    const existingStack = modalStack.map((item) => {
      return {
        ...item,
        label: processDynamicText({ text: get(item, "label") }),
      };
    });

    setModalStack([...existingStack, newItem]);
    return newStackItemId;
  };

  const recordClick = (block, item, actionResolver) => {
    // Skip in setup mode
    if (isFrontlyAdmin) {
      return null;
    }

    let clickAction = get(block, "recordClickAction");
    const validClickActionTypes = ["default", "custom", "action", "none"];
    if (!validClickActionTypes.includes(clickAction)) {
      clickAction = "default";
    }

    if (["default", "custom"].includes(clickAction)) {
      const viewLabel = getViewLabel(block);
      addModalToStack({
        label: viewLabel,
        blockId: block.id,
        itemId: item.frontly_id,
        type: "edit",
        detailViewMode: block.detailViewMode,
        recordClickAction: clickAction,
      });
    } else if (clickAction === "action") {
      actionResolver({
        actionId: get(block, "customRecordClickAction"),
        context: { record: item },
        reusableBlockId: block.reusableBlockId,
        blockId: block.id,
      });
    } else {
      // NO ACTION
    }
  };

  const passesDisplayConditions = (data) => {
    const { conditions, context, reusableBlockId } = data;

    if (isFrontlyAdmin || !conditions || conditions.length === 0) {
      return true;
    }

    const migratedConditions = conditions.map((c) => migrateConditionFormat(c));

    const orConditions =
      migratedConditions.filter((c) => c.conditionType === "or").length > 0;

    const results = migratedConditions
      .filter((condition) => {
        // Check if the condition is complete / valid
        if (!condition.value1) {
          return false;
        }
        return true;
      })
      .map((condition) => {
        const { value1, value2 } = condition;

        const resolvedValue1 = processDynamicText({
          text: value1,
          context,
          reusableBlockId,
        });

        const resolvedValue2 = processDynamicText({
          text: value2,
          context,
          reusableBlockId,
        });

        const pass = passesCondition({
          value1: resolvedValue1,
          value2: resolvedValue2,
          operator: get(condition, "operator", "equals"),
          value1DateFormat: appDateFormat,
        });

        return pass;
      });

    if (orConditions) {
      // Return true if any of the conditions are true
      return results.includes(true);
    }

    // Return true if all of the conditions are true
    return !results.includes(false);
  };

  return {
    processDynamicText,
    addModalToStack,
    recordClick,
    passesDisplayConditions,
  };
};

export default useUtils;
