import RenderBlock from "./RenderBlock";
import { get } from "lodash";
import useActionResolver from "../useActionResolver";
import useBlockVersion from "app/utils/useBlockVersion";
import { useRef } from "react";

const RenderBlockLiveMode = ({ data }) => {
  // Any live mode logic goes here

  let originalBlock = get(data, "block", {});

  const { customBlockOnClick, page } = data;

  const { getBlockWithVersion } = useBlockVersion();
  const { blockWithVersion: block } = getBlockWithVersion(originalBlock);

  const actions = get(page, "actions", []);

  const { actionResolver } = useActionResolver(page);

  const blockId = get(block, "id");
  const componentId = get(block, "componentId");

  // Handle Click Action For Row and Column only
  let handleClick = null;
  let hasValidAction = false;

  if (["Row", "Column"].includes(componentId)) {
    const clickAction = get(block, "clickAction");
    if (clickAction) {
      hasValidAction = true;
      const repeatingRecord = get(block, "repeatingRecord");
      handleClick = (event) => {
        event.stopPropagation();
        actionResolver({
          reusableBlockId: block.reusableBlockId,
          actionId: clickAction,
          blockId,
          context: { record: repeatingRecord },
        });
      };
    }
  }

  if (
    ["Button", "Icon", "Text", "Image", "Stat", "Column", "Row"].includes(
      componentId
    )
  ) {
    const clickAction = get(block, "clickAction");
    const matchingAction = actions.find((action) => action.id === clickAction);
    const hasSteps =
      get(matchingAction, "steps", []).filter((s) => s.type).length > 0;

    if (clickAction && hasSteps) {
      hasValidAction = true;
    }
  }

  let mouseEvents = {};
  if (customBlockOnClick) {
    mouseEvents = {
      onClick: (e) => {
        e.stopPropagation();
        customBlockOnClick();
      },
    };
  } else if (hasValidAction && handleClick) {
    mouseEvents = {
      onClick: handleClick,
    };
  }

  const containerRef = useRef();

  // This is the container ref, not actually related to drag
  const dragParams = {
    ref: containerRef,
  };

  const liveModeData = {
    ...data,
    block,
    dragParams,
    mouseEvents,
    hasValidAction,
  };

  return <RenderBlock data={liveModeData} />;
};

export default RenderBlockLiveMode;
