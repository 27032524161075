import { AdminForm, HiddenFiltersConfig, StaticFields } from "../components";
import { CheckboxGroup, Input, Modal, Switch, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";
import { rApp, rSavedSpreadsheets } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { apiRequest } from "app/utils/apiRequests";
import mixpanel from "mixpanel-browser";
import { useState } from "react";

const DataSourceDetails = ({
  activeDataSource,
  setActiveDataSource,
  setDateFields,
}) => {
  const activeApp = useRecoilValue(rApp);

  const supabaseProjectId = get(activeApp, "supabase_project_id");

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const [isImporting, setIsImporting] = useState(null);

  const [errors, setErrors] = useState(null);
  const [isCreatingSheet, setIsCreatingSheet] = useState(null);

  const [tabs, setTabs] = useState([]);
  const activeSourceData = savedSpreadsheets.find(
    (s) => s.id === get(activeDataSource, "id")
  );

  const sheetId = get(activeDataSource, "spreadsheet_id");

  const isValid = () => {
    const err = {};
    if (!sheetId || !sheetId.length || !sheetId.includes("docs.google")) {
      err.spreadsheet_id = "Please enter valid link URL.";
    }
    setErrors(err);

    return isEmpty(err);
  };

  const createSheet = () => {
    if (!isValid()) return;

    setIsCreatingSheet(true);

    const validatedData = {
      title: get(activeDataSource, "title"),
      spreadsheet_id: parseGoogleSheetId(sheetId),
    };

    apiRequest.post("/sheet_management/", validatedData).then((response) => {
      const newSheet = get(response, "data");
      const tabs = get(newSheet, "tabs");
      const error = get(newSheet, "error");

      if (error) {
        errorNotification(error);
        setIsCreatingSheet(false);
        return;
      }

      if (tabs && tabs.length > 0) {
        const newTabs = tabs.filter((t) => {
          const match = savedSpreadsheets.find(
            (s) => s.tab_id == t.tab_id && s.spreadsheet_id == t.spreadsheet_id
          );
          return !match;
        });

        setTabs(
          newTabs.map((tab) => ({
            ...tab,
            label: tab.tab_name,
            value: true,
          }))
        );
        setIsCreatingSheet(false);
      } else {
        setSavedSpreadsheets([...savedSpreadsheets, newSheet]);
        successNotification("Google Sheet imported");
        mixpanel.track("Import Google Sheet");
        setIsCreatingSheet(false);
        setActiveDataSource(null);
      }
    });
  };

  const importTabs = () => {
    setIsImporting(true);
    const selected = tabs.filter((t) => t.value);
    apiRequest.post("/import_tabs/", { tabs: selected }).then((response) => {
      const newSheet = get(response, "data");
      const tabs = get(newSheet, "spreadsheets", []);

      setSavedSpreadsheets([...savedSpreadsheets, ...tabs]);

      let dateFields = [];
      tabs.forEach((t) => {
        const config = get(t, ["field_data", "config"], {});
        Object.keys(config).forEach((k) => {
          const match = get(config, k);
          if (match.componentId === "DateTimePicker") {
            dateFields.push({
              id: t.id,
              field: k,
            });
          }
        });
      });

      if (dateFields.length > 0) {
        setDateFields(dateFields);
      }

      successNotification("Import successful");
      setIsImporting(false);
      mixpanel.track("Import Google Sheet");
      setActiveDataSource(null);
      setTabs([]);
    });
  };

  const saveLabel = () => {
    setActiveDataSource(null);
    successNotification("Saved");
    apiRequest.post("/sheet_management/", activeSourceData);
  };

  const updateActiveSource = (k, v) =>
    setSavedSpreadsheets(
      savedSpreadsheets.map((s) => {
        if (s.id === get(activeDataSource, "id")) {
          return { ...s, [k]: v };
        }
        return s;
      })
    );

  const fieldData = get(activeSourceData, "field_data", {});

  const renderNewSheet = () => {
    return (
      <>
        <Text
          data={{
            text: "Copy the full link from the address bar of your Google Spreadsheet",
            margin: "0 0 20px 0",
          }}
        />
        <Input
          data={{
            label: "Google Sheet Link",
            placeholder:
              "https://docs.google.com/spreadsheets/d/90132849012384kkjdslflkjad/edit#gid=0",
            required: true,
            width: "100%",
            value: get(activeSourceData, "spreadsheet_id"),
            onChange: (value) =>
              setActiveDataSource({
                ...activeDataSource,
                spreadsheet_id: value,
              }),
          }}
        />
      </>
    );
  };

  const renderActiveSource = () => {
    return (
      <>
        <AdminForm
          fields={[
            {
              label: "Title",
              id: "title",
              componentId: "Input",
              required: true,
              value: get(activeSourceData, "title"),
            },
          ]}
          sectionPadding="0px"
          onChange={(k, v) => updateActiveSource(k, v)}
        />
        <Text
          data={{
            margin: "25px 0 5px 0",
            text: "Column Defaults",
            fontStyle: "headingMd",
          }}
        />
        <Text
          data={{
            margin: "0 0 5px 0",
            text: "Adjust how your columns should be rendered throughout your pages",
            fontStyle: "bodySm",
          }}
        />

        <StaticFields
          data={{
            value: fieldData,
            onChange: (newData) => updateActiveSource("field_data", newData),
            customSpreadsheetId: get(activeDataSource, "id"),
            getDescription: (field) =>
              `(${get(
                {
                  Input: "Text",
                  Select: "Dropdown",
                  MultiSelect: "Multi Select",
                  ImageUpload: "Image",
                  DateTimePicker: "Date",
                  TimePicker: "Time",
                  Switch: "True/False",
                },
                get(field, "componentId", "Input")
              )})`,
            keys: [
              {
                id: "label",
                label: "Label",
                hint: "Override the default label",
                componentId: "Input",
                required: true,
                defaultValue: "",
              },
              {
                id: "componentId",
                label: "Component",
                hint: "Define how the column should be displayed visually in forms and other blocks",
                componentId: "Select",
                required: true,
                defaultValue: "Input",
                hideEmptyItem: true,
                options: [
                  { label: "Text", value: "Input" },
                  { label: "Select", value: "Select" },
                  { label: "Multi Select", value: "MultiSelect" },
                  { label: "Image", value: "ImageUpload" },
                  { label: "Date", value: "DateTimePicker" },
                  // { label: "Time", value: "TimePicker" },
                  { label: "True/False", value: "Switch" },
                ],
              },
              {
                displayCondition: (field) =>
                  get(field, "componentId", "Input") === "Input",
                id: "isNumber",
                label: "Is Number",
                hint: "Used for the mock data system",
                componentId: "Switch",
              },
              {
                displayCondition: (field) =>
                  get(field, "componentId") === "DateTimePicker",
                id: "dateFormat",
                label: "Date Format",
                componentId: "DateFormatConfig",
                enabledFields: [
                  "inputDate",
                  "outputDate",
                  "inputTime",
                  "outputTime",
                  "showAmPm",
                ],
              },
              {
                id: "options",
                label: "Options",
                componentId: "DataGrid",
                orientation: "vertical",
                hint: "Define the options to appear in your select dropdown",
                requiresSheet: true,
                columns: [
                  {
                    key: "label",
                    componentId: "Input",
                  },
                  {
                    key: "value",
                    componentId: "Input",
                  },
                ],
                displayCondition: (field) =>
                  ["Select", "MultiSelect"].includes(get(field, "componentId")),
              },
            ],
          }}
        />

        <Text
          data={{
            margin: "25px 0 5px 0",
            text: "Hidden Filters",
            fontStyle: "headingMd",
          }}
        />
        <HiddenFiltersConfig
          data={{
            hideConditionType: true,
            value: get(activeSourceData, "hidden_filters"),
            onChange: (newData) =>
              updateActiveSource("hidden_filters", newData),
            linkedSpreadsheet: activeSourceData,
          }}
        />

        <Text
          data={{
            margin: "25px 0 5px 0",
            text: "Allow Public Access",
            fontStyle: "headingMd",
          }}
        />
        <Text
          data={{
            margin: "0 0 5px 0",
            text: "This will allow anyone on the internet to view, edit and delete your sheet data depending on your settings.",
            fontStyle: "bodySm",
            width: "400px",
            color: get(activeSourceData, "allow_public_access") && "#c41414",
          }}
        />
        <Switch
          data={{
            text: "Allow Public Access",
            onChange: (v) => updateActiveSource("allow_public_access", v),
            value: get(activeSourceData, "allow_public_access"),
          }}
        />
      </>
    );
  };

  const renderTabs = () => {
    return (
      <>
        <Text
          data={{
            margin: "0 0 20px 0",
            text: "Each Sheet / Tab in your spreadsheet document can be imported as its own data source",
            fontStyle: "bodySm",
          }}
        />

        <CheckboxGroup
          data={{
            options: tabs,
            onChange: (index) => {
              setTabs(
                tabs.map((t, i) => {
                  if (i === index) {
                    return { ...t, value: !t.value };
                  }
                  return t;
                })
              );
            },
          }}
        />
      </>
    );
  };

  const renderContent = () => {
    if (tabs && tabs.length > 0) {
      return {
        content: renderTabs(),
        buttons: [
          {
            text: "Import Tabs",
            onClick: importTabs,
            isFetching: isImporting,
          },
        ],
        title: "Select Sheets For Import",
      };
    }

    let buttons = [{ text: "Save Changes", onClick: saveLabel }];

    if (get(activeDataSource, "service", "Google Sheets") === "supabase") {
      buttons = [
        {
          text: "Open In Supabase",
          type: "basic",
          icon: "HiArrowTopRightOnSquare",
          onClick: () =>
            window.open(
              `https://supabase.com/dashboard/project/${supabaseProjectId}`
            ),
        },
        ...buttons,
      ];
    } else {
      buttons = [
        {
          text: "Open In Google",
          type: "basic",
          icon: "HiArrowTopRightOnSquare",
          onClick: () =>
            window.open(
              `https://docs.google.com/spreadsheets/d/${activeDataSource.spreadsheet_id}/edit#gid=${activeDataSource.tab_id}`
            ),
        },
        ...buttons,
      ];
    }

    if (get(activeDataSource, "id")) {
      return {
        content: renderActiveSource(),
        title: "Edit Data Source",
        buttons,
      };
    }

    return {
      content: renderNewSheet(),
      title: "Import Spreadsheet",
      buttons: [
        {
          text: "Import Sheet",
          onClick: createSheet,
          isFetching: isCreatingSheet,
        },
      ],
    };
  };

  const modalContent = renderContent();

  return (
    <Modal
      minWidth="400px"
      header={{ title: get(modalContent, "title") }}
      buttons={get(modalContent, "buttons")}
      hide={() => setActiveDataSource(null)}
    >
      {get(modalContent, "content")}
    </Modal>
  );
};

export default DataSourceDetails;

const parseGoogleSheetId = (string) => {
  let idStartIndex = string.indexOf("/d/") + 3;
  let idEndIndex = string.indexOf("/edit");
  return string.slice(idStartIndex, idEndIndex);
};
