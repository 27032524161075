import { AdminForm, DateFormatConfig } from "../components";
import {
  Button,
  Column,
  Input,
  Modal,
  Row,
  SearchBar,
  Tabs,
  Text,
} from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, startCase } from "lodash";
import { getDisplayHeaders, getUrlParameter, safeLower } from "app/utils/utils";
import {
  rApp,
  rAppDateFormat,
  rConfirmationModalData,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "../home/AdminWrapper";
import CardGrid from "../components/CardGrid";
import { CardSection } from "../settings/SettingsSection";
import DataSourceDetails from "./DataSourceDetails";
import { EmptyStateDashboard } from "app/renderPage/EmptyStates";
import GoogleIntegrationNew from "./GoogleIntegrationNew";
import { InfoBox } from "../settings/InfoBox";
import { Label } from "app/adminApp/home/Pages";
import RelationalData from "./RelationalData";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import googleSheetsLogo from "../assets/google-sheets-logo.png";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";

const Spreadsheets = () => {
  const appDateFormat = useRecoilValue(rAppDateFormat);

  const [app, setApp] = useRecoilState(rApp);

  const navigate = useNavigate();

  const location = useLocation();

  const tab = getUrlParameter("tab", location) || "dataSources";

  const code = getUrlParameter("code", location);

  useEffect(() => {
    if (code && tab !== "accountConnections") {
      const search = get(location, "search");
      const newRoute = `/spreadsheets${search}&tab=accountConnections`;
      navigate(newRoute);
    }
  }, []);

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const [search, setSearch] = useState("");

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);
  const [dateFields, setDateFields] = useState(null);

  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const filteredSheets = savedSpreadsheets.filter(
    (sheet) =>
      search === "" ||
      safeLower(sheet.title).includes(safeLower(search)) ||
      safeLower(sheet.tab_name).includes(safeLower(search))
  );

  const { refreshSheet } = useSpreadsheetRequests();

  const [isImportingTabs, setIsImportingSheet] = useState(null);
  const [activeDataSource, setActiveDataSource] = useState(null);

  const [activeSupabaseTable, setActiveSupabaseTable] = useState(null);

  const disconnect = () => {
    setConfirmationModalData({
      title: "Disconnect Google Sheets",
      text: "Are you sure you want to disconnect Google Sheets? This will break any existing pages using your spreadsheets.",
      hide: null,
      confirm: () => {
        // CONFIRMED DELETE
        setIsDisconnecting(true);

        apiRequest
          .post(`/app_settings/`, {
            google_sheets_access_token: "",
            google_sheets_refresh_token: "",
          })
          .then(() => {
            setApp({
              google_sheets_connected: false,
            });

            mixpanel.track("Disconnected Google Sheet");

            successNotification("Google Sheets Disconnected");
          })
          .catch((error) => {
            errorNotification("Could not disconnect your Google Sheets");
          })
          .finally(() => {
            setIsDisconnecting(false);
          });
      },
    });
  };

  const importSupabaseTable = () => {
    setIsImportingSheet(true);

    apiRequest
      .post("/import_supabase_table/", {
        table: get(activeSupabaseTable, "table"),
      })
      .then((response) => {
        const resData = get(response, "data", {});
        const error = get(resData, "error");

        if (error) {
          errorNotification(error);
          setIsImportingSheet(false);
          return;
        }

        const newTable = get(resData, "data_source");

        setSavedSpreadsheets([...savedSpreadsheets, newTable]);

        successNotification("Import successful");
        setIsImportingSheet(false);
        mixpanel.track("Import Supabase Table");
        setActiveSupabaseTable(null);
      });
  };

  const unlinkSheet = (sheetId) => {
    apiRequest
      .delete("/sheet_management/", {
        data: { id: sheetId },
      })
      .then(() => {
        setSavedSpreadsheets(savedSpreadsheets.filter((s) => s.id !== sheetId));
        successNotification("Data Source Removed");
        mixpanel.track("Unlink Google Sheet");
      });
  };

  const supabaseProjectId = get(app, "supabase_project_id");

  const isSupabaseIntegrated = supabaseProjectId && get(app, "supabase_key");

  const [showAddDataSources, setShowAddDataSources] = useState(false);

  const dataSourcesContent = (
    <div>
      {showAddDataSources && (
        <Modal
          minWidth="660px"
          hide={() => setShowAddDataSources(false)}
          header={{
            title: "Select a data source",
          }}
        >
          <DataSourcesContainer>
            <DataSourceCard
              name="Google Sheets"
              description="Connect your app to your Google spreadsheets to use as a database"
              icon={googleSheetsLogo}
              onClick={() => {
                if (googleConnected) {
                  setShowAddDataSources(false);
                  setActiveDataSource({});
                } else {
                  errorNotification(
                    "Please connect your Google Sheets account"
                  );
                  navigate("/spreadsheets?tab=accountConnections");
                }
              }}
            />
            <DataSourceCard
              name="Supabase (Beta)"
              description="Connect a scalable and secure database to your app."
              icon={
                "https://res.cloudinary.com/frontly/image/upload/v1716142973/supabase-icon_akbjay.png"
              }
              onClick={() => {
                if (isSupabaseIntegrated) {
                  setActiveSupabaseTable({});
                  setShowAddDataSources(false);
                } else {
                  errorNotification(
                    "Please enter your Supabase Project ID and Key in the Settings / Integrations tab first."
                  );
                }
              }}
            />
            {/* <DataSourceCard
              name="Airtable (Coming Soon)"
              disabled
              description="Use an Airtable database to power your Frontly app."
              icon={
                "https://res.cloudinary.com/frontly/image/upload/v1716143095/airtable-icon-512x428-olxouyvv_yu6idx.png"
              }
              // onClick={() => setActiveAirtable({})}
            /> */}
          </DataSourcesContainer>
        </Modal>
      )}

      {savedSpreadsheets.length === 0 && (
        <EmptyStateDashboard
          title="You don't have any data sources"
          subtitle="To build a live app, you will need to add your own data. Get started by clicking the button below."
          graphic={
            "https://res.cloudinary.com/frontly/image/upload/v1716245131/Graphs_y2esoe.svg"
          }
          buttonText="Add Data Source"
          onClick={() => setShowAddDataSources(true)}
        />
      )}

      {savedSpreadsheets.length > 0 && (
        <CardGrid
          noResultsName={"data sources"}
          items={filteredSheets.map((s) => {
            let dropdownOptions = [
              {
                label: "Remove Data Source",
                icon: "FiTrash",
                onClick: () =>
                  setConfirmationModalData({
                    title: "Remove Data Source",
                    text: "Are you sure you want to remove this data source? This is irreversible and it will break any pages using it in your app.",
                    confirm: () => unlinkSheet(s.id),
                  }),
              },
            ];

            if (!s.deleted) {
              dropdownOptions.push({
                icon: "FiRefreshCcw",
                label: "Refresh Data & Headers",
                onClick: () => refreshSheet(s.id),
              });
            }

            if (s.service === "supabase") {
              dropdownOptions.push({
                icon: "FiExternalLink",
                label: "Open In Supabase",
                onClick: () =>
                  window.open(
                    `https://supabase.com/dashboard/project/${supabaseProjectId}`
                  ),
              });
            } else {
              dropdownOptions.push({
                icon: "FiExternalLink",
                label: "Open In Google",
                onClick: () =>
                  window.open(
                    `https://docs.google.com/spreadsheets/d/${s.spreadsheet_id}/edit#gid=${s.tab_id}`
                  ),
              });
            }

            const service = get(s, "service", "Google Sheets");

            let badges = [
              {
                color: service === "supabase" ? "#AFE6F1" : "#b8e7d2",
                text: startCase(service),
              },
              {
                color: s.allow_public_access ? "#AFE6F1" : "#F0F0F0",
                text: s.allow_public_access ? "Public" : "Private",
              },
            ];

            if (s.deleted) {
              badges.push({
                color: "#f1afaf",
                text: "Not Found In Google",
              });
            }

            return {
              title: s.title,
              description: `${
                getDisplayHeaders(get(s, "headers", [])).length
              } columns`,
              onClick: () => {
                s.deleted
                  ? errorNotification(
                      "This sheet tab can no longer be found in Google. Did you delete it?"
                    )
                  : setActiveDataSource(s);
              },
              badges,
              options: dropdownOptions,
            };
          })}
          itemsPerPage={12}
        />
      )}
    </div>
  );

  const [supabaseChanges, setSupabaseChanges] = useState(false);

  const saveSupabase = () => {
    apiRequest.post("/app_settings/", {
      supabase_project_id: supabaseProjectId,
      supabase_key: get(app, "supabase_key"),
      supabase_host: get(app, "supabase_host"),
      supabase_database_password: get(app, "supabase_database_password"),
    });
    setSupabaseChanges(false);
    successNotification("Supabase settings saved");
  };

  const googleConnected = get(app, "google_sheets_connected");

  const databaseSettingsUrl = `https://supabase.com/dashboard/project/${supabaseProjectId}/settings/database`;

  return (
    <AdminWrapper padding="40px">
      {activeSupabaseTable && (
        <Modal
          minWidth="400px"
          header={{ title: "Add Supabase Table" }}
          buttons={[
            {
              text: "Import Table",
              onClick: importSupabaseTable,
              isFetching: isImportingTabs,
            },
          ]}
          hide={() => setActiveSupabaseTable(null)}
        >
          <Text
            data={{
              text: "Enter the exact name of your Supabase table (case sensitive)",
              margin: "0 0 20px 0",
            }}
          />
          <Input
            data={{
              label: "Supabase Table Name",
              placeholder: "products",
              required: true,
              width: "100%",
              value: get(activeSupabaseTable, "table"),
              onChange: (value) =>
                setActiveSupabaseTable({ ...activeDataSource, table: value }),
            }}
          />
        </Modal>
      )}

      {activeDataSource && (
        <DataSourceDetails
          activeDataSource={activeDataSource}
          setActiveDataSource={setActiveDataSource}
          setDateFields={setDateFields}
        />
      )}

      {dateFields && (
        <Modal
          hide={() => setDateFields(null)}
          header={{ title: "Date Columns Detected" }}
        >
          <Text
            data={{
              fontStyle: "bodyLg",
              text: "Confirm the correct date format for each date column to continue:",
            }}
          />

          <Column gap="15px" margin="20px 0 0 0">
            {dateFields.map((df) => {
              const m = savedSpreadsheets.find((s) => s.id === df.id);
              const firstThreeDates = get(m, "data", [])
                .slice(0, 3)
                .map((d) => get(d, df.field))
                .join(", ");
              const fd = get(m, "field_data");
              const fdc = get(fd, "config", {});
              const fdcv = get(fdc, df.field);

              const dateFormat = get(
                fdcv,
                "dateFormat",
                get(appDateFormat, "inputDate")
              );

              return (
                <DateContainer>
                  <Text
                    data={{ text: startCase(df.field), fontStyle: "headingMd" }}
                  />
                  <Row gap="10px">
                    <Text
                      data={{
                        text: "Samples From Your Sheet:",
                        fontStyle: "headingSm",
                      }}
                    />
                    <Text
                      data={{
                        text: firstThreeDates,
                        fontStyle: "bodyMd",
                      }}
                    />
                  </Row>

                  <Row gap="10px">
                    <Text
                      data={{
                        text: "Current Format Setting:",
                        fontStyle: "headingSm",
                      }}
                    />
                    <Text
                      data={{
                        text: get(dateFormat, "inputDate"),
                        fontStyle: "bodyMd",
                      }}
                    />
                  </Row>

                  <DateFormatConfig
                    data={{
                      value: dateFormat,
                      enabledFields: ["inputDate", "inputTime"],
                      onChange: (newDateFormatObj) => {
                        setSavedSpreadsheets(
                          savedSpreadsheets.map((s) => {
                            if (s.id === df.id) {
                              let newObj = {
                                ...fd,
                                config: {
                                  ...fdc,
                                  [df.field]: {
                                    ...fdcv,
                                    dateFormat: newDateFormatObj,
                                  },
                                },
                              };

                              return {
                                ...s,
                                field_data: newObj,
                              };
                            }
                            return s;
                          })
                        );
                      },
                    }}
                  />
                </DateContainer>
              );
            })}
          </Column>
          <Button
            data={{
              margin: "15px 0 0 0",
              text: "Save Date Formats",
              onClick: () => {
                setDateFields(null);
                successNotification("Date Formats Saved");

                // Bulk save edited sheets
                dateFields.forEach((df) => {
                  const m = savedSpreadsheets.find((s) => s.id === df.id);
                  apiRequest.post("/sheet_management/", m);
                });
              },
            }}
          />
        </Modal>
      )}

      <Label>Data Sources</Label>
      <Row
        margin="15px 0 30px 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tabs
          data={{
            margin: "15px 0 0 0",
            fontStyle: "headingLg",
            tabs: [
              {
                label: "Linked Sources",
                active: tab === "dataSources",
                onClick: () => navigate("/spreadsheets?tab=dataSources"),
              },
              {
                label: "Relational Data",
                active: tab === "dataRelations",
                onClick: () => navigate("/spreadsheets?tab=dataRelations"),
                hidden: savedSpreadsheets.length === 0,
              },
              {
                label: "Account Connections",
                active: tab === "accountConnections",
                onClick: () => navigate("/spreadsheets?tab=accountConnections"),
              },
            ],
          }}
        />
        {tab === "dataSources" && (
          <Row gap="15px">
            <SearchBar
              data={{
                value: search,
                placeholder: "Search data sources",
                onChange: (v) => setSearch(v),
              }}
            />
            <Button
              data={{
                text: "Add Data Source",
                icon: "FiPlus",
                onClick: () => setShowAddDataSources(true),
              }}
            />
          </Row>
        )}
      </Row>

      {tab === "dataSources" && dataSourcesContent}
      {tab === "accountConnections" && (
        <div style={{ maxWidth: "450px" }}>
          <CardSection style={{ margin: "30px 0 0 0" }}>
            <Text
              data={{
                text: "Google Sheets",
                fontStyle: "headingLg",
              }}
            />
            <Text
              data={{
                text: "Connect your app to your Google spreadsheets to use as a database",
                fontStyle: "bodyMd",
                margin: "5px 0 20px 0",
              }}
            />

            {!googleConnected && <GoogleIntegrationNew />}

            {googleConnected && (
              <Button
                data={{
                  isFetching: isDisconnecting,
                  text: "Google Sheets Connected - Click To Disconnect",
                  icon: "FiAlertTriangle",
                  type: "basic",
                  onClick: disconnect,
                }}
              />
            )}
          </CardSection>

          <CardSection style={{ margin: "30px 0 0 0" }}>
            <Text
              data={{
                text: "Supabase (Beta)",
                fontStyle: "headingLg",
              }}
            />
            <Text
              data={{
                text: "Connect your app to your Supabase database",
                fontStyle: "bodyMd",
                margin: "5px 0 20px 0",
              }}
            />

            <AdminForm
              labelStyle="headingSm"
              sectionPadding="5px"
              fields={[
                {
                  id: "supabase_project_id",
                  label: "Project ID",
                  hint: "Your Supabase Project ID (found in your Supabase dashboard).",
                  value: supabaseProjectId,
                },
                {
                  id: "supabase_key",
                  label: "Public Key",
                  hint: "A secure API key used by Frontly to make requests to your Supabase account.",
                  type: "password",
                  value: get(app, "supabase_key"),
                },
                {
                  id: "supabase_host",
                  label: "Host",
                  hint: "The host value for your database, found in your Supabase database settings.",
                  value: get(app, "supabase_host"),
                },
                {
                  id: "supabase_database_password",
                  label: "Database Password",
                  hint: "The password to your database, used when creating the project. Found in your Supabase database settings. Can be reset if you don't know it, but this could have consequences if you have other connections to your database.",
                  type: "password",
                  value: get(app, "supabase_database_password"),
                },
              ]}
              submitText={"Save Changes"}
              submit={supabaseChanges && saveSupabase}
              orientation={"vertical"}
              onChange={(k, v) => {
                setSupabaseChanges(true);
                setApp({
                  ...app,
                  [k]: v,
                });
              }}
            />
            {supabaseProjectId && (
              <Text
                data={{
                  margin: "10px 0 0 0",
                  text: "Open Supabase Database Settings (For Host and Password)",
                  onClick: () => window.open(databaseSettingsUrl),
                  cursor: "pointer",
                  color: colors.primary,
                }}
              />
            )}
            <InfoBox margin="15px 5px 5px 5px">
              Supabase integration is in beta mode, which means it's under
              development and is subject to change and potential bugs.
            </InfoBox>
          </CardSection>
        </div>
      )}

      {tab === "dataRelations" && <RelationalData />}
    </AdminWrapper>
  );
};

export default Spreadsheets;

const DataSourceCard = ({ name, description, onClick, icon, disabled }) => {
  return (
    <DataSourceContainer onClick={onClick} disabled={disabled}>
      <DataSourceIcon src={icon} />
      <Text
        data={{
          text: name,
          fontStyle: "headingLg",
          textAlign: "center",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      />
      <Text
        data={{
          text: description,
          textAlign: "center",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      />
    </DataSourceContainer>
  );
};

const DataSourceIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
`;

const DataSourcesContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const DataSourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 20px;
  width: 300px;
  border-radius: 10px;
  background: white;
  box-shadow: rgba(29, 29, 31, 0.05) 0px 4px 5px;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  transition: box-shadow 0.2s;
  border: 1px solid ${colors.grey2};
  ${(p) => p.disabled && `opacity: 0.6;`}
  &:hover {
    box-shadow: rgba(29, 29, 31, 0.1) 0px 8px 10px;
  }
`;

const DateContainer = styled.div`
  border: 1px solid ${colors.divider};
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
