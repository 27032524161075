import { Button, Icon, Row, Spinner, Text } from "app/components";
import { rApp, rSavedSpreadsheets } from "app/utils/recoil";

import AdminWrapper from "../home/AdminWrapper";
import { Label } from "../home/Pages";
import Report from "./Report";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getCurrentDomain } from "app/utils/utils";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useState } from "react";

const Reports = () => {
  const [app, setApp] = useRecoilState(rApp);

  const reports = get(app, "reports", []) || [];
  const navigate = useNavigate();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const lastRefreshDate = get(app, "sheets_last_refreshed");

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const refreshData = () => {
    const spreadsheetIds = reports.map((r) => r.spreadsheet);

    mixpanel.track("Reports - Refresh Sheets");

    setIsRefreshing(true);

    apiRequest
      .post("/google_sheets/", {
        endpoint_type: "refresh_multi",
        spreadsheet_ids: spreadsheetIds,
        domain: getCurrentDomain(),
      })
      .then((response) => {
        const data = get(response, "data");

        setSavedSpreadsheets(
          savedSpreadsheets.map((s) => {
            const match = get(data, s.id);
            if (match) {
              return { ...s, ...match };
            }
            return s;
          })
        );

        setApp({
          sheets_last_refreshed: moment(),
        });
        setIsRefreshing(false);
      });
  };

  return (
    <AdminWrapper>
      <Row justifyContent="space-between">
        <Label>Reports</Label>
        <Button
          data={{
            text: "Add Report",
            icon: "FiPlus",
            onClick: () => navigate("/report/new"),
          }}
        />
      </Row>

      {reports.length === 0 && <EmptyState />}
      {reports.length > 0 && (
        <>
          {isRefreshing ? (
            <Spinner size={20} color={colors.primary} padding="0 0 20px 0" />
          ) : (
            <Row margin="0 0 20px 0" gap="5px">
              <Text
                data={{
                  text: `Last updated ${
                    lastRefreshDate
                      ? moment(lastRefreshDate).fromNow()
                      : moment(get(app, "created_date")).fromNow()
                  }.`,
                }}
              />
              <Text
                data={{
                  text: "Refresh Now",
                  color: colors.primary,
                  fontStyle: "headingSm",
                  onClick: refreshData,
                }}
              />
            </Row>
          )}

          <DashboardGrid count={reports.length}>
            {reports.map((report) => (
              <Report data={report} />
            ))}
          </DashboardGrid>
        </>
      )}
    </AdminWrapper>
  );
};

export default Reports;

const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <EmptyWrapper>
      {/* <EmptyCard> */}
      <Row alignItems="center" gap="20px">
        <EmptyOutline>
          <Icon data={{ icon: "FiBarChart", color: "#3c78e140", size: 85 }} />
        </EmptyOutline>
        <EmptyOutline>
          <Icon data={{ icon: "FiPieChart", color: "#3c78e140", size: 85 }} />
        </EmptyOutline>
      </Row>

      <Text
        data={{
          text: "Generate custom analytics reports with AI",
          fontStyle: "headingXl",
          margin: "30px 0 25px 0",
          textAlign: "center",
        }}
      />
      <Row gap="15px">
        <Button
          data={{
            text: "Add Report",
            icon: "FiPlus",
            size: "large",
            onClick: () => navigate("/report/new"),
          }}
        />
        <Button
          data={{
            text: "Watch Tutorial",
            icon: "FiYoutube",
            size: "large",
            type: "basic",
            onClick: () => window.open("https://youtu.be/zy3zuPQHHWU"),
          }}
        />
      </Row>
      {/* </EmptyCard> */}
    </EmptyWrapper>
  );
};

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 200px;
`;

const EmptyOutline = styled.div`
  border: 3px solid #3c78e140;
  border-radius: 10px;
  padding: 30px;
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  ${(p) => p.count && p.count < 4 && "grid-template-columns: 1fr 1fr;"}
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;
