import {
  Button,
  FullScreenLoader,
  Icon,
  Input,
  Modal,
  Row,
  Text,
  TextArea,
} from "app/components";
import { RatingCount, RatingStars, RatingText } from ".";
import { getUrlParameter, resizeImage, safeToFixed } from "app/utils/utils";
import { rConfirmationModalData, rUser } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { BackButton } from "../components";
import { Header } from "../landing/Header";
import { NoImage } from "app/components/Thumbnail";
import RichText from "app/renderingApp/blocks/RichText/RichText";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";

const Profile = ({ customData }) => {
  const navigate = useNavigate();

  const location = useLocation();

  const isAdmin = getUrlParameter("admin", location);

  return (
    <div style={{ background: "white" }}>
      <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
        {!customData && <Header />}
        <BackButton
          width="auto"
          margin="30px 0 10px 20px"
          text="All Experts"
          onClick={() =>
            navigate(isAdmin ? "/internal_admin/experts" : "/experts")
          }
        />
        <RenderProfile />
      </div>
    </div>
  );
};

export const RenderProfile = ({ customData, internal }) => {
  const { id } = useParams();

  const [expert, setExpert] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const user = useRecoilValue(rUser);

  useEffect(() => {
    if (!customData) {
      setIsFetching(true);
      apiRequest.get(`/expert/?id=${id}`).then((res) => {
        const expertData = get(res, "data");
        setExpert(expertData);
        setIsFetching(false);
      });
    }
  }, []);

  const navigate = useNavigate();

  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const [activeDemoAppIndex, setActiveDemoAppIndex] = useState(null);

  if (isFetching) {
    return <FullScreenLoader />;
  }

  const data = customData || {
    id: get(expert, "id"),
    name: get(expert, "name"),
    ...get(expert, "data", {}),
  };

  const demoApps = get(data, "demo_apps", []).filter((d) => {
    return d.image && d.name && d.description;
  });

  const reviews = get(expert, "reviews", []) || [];

  const activeDemoApp = demoApps.find((d, i) => i === activeDemoAppIndex);

  return (
    <Container>
      <MainRow>
        <LeftColumn>
          <ProfileDetails>
            {data?.image ? (
              <ProfileImage
                src={resizeImage({
                  url: data?.image,
                  height: 300,
                })}
              />
            ) : (
              <NoImage height="200px" width="200px" iconSize={50} />
            )}
            <ProfileName>{data?.name}</ProfileName>
            <Row alignItems="center" gap="10px" margin="15px 0 10px 0">
              <Icon
                data={{ icon: "FiMapPin", colors: colors.grey2, size: 18 }}
              />
              <ProfileLocation>
                {data?.location || "No Location"}
              </ProfileLocation>
            </Row>

            <ProfileLocation>
              {data?.hourlyRate
                ? `Hourly Rate: $${safeToFixed(data?.hourlyRate, 2)} USD`
                : "No Hourly Rate Set"}
            </ProfileLocation>

            <StarRatingRow
              margin={"10px 0 25px 0"}
              rating={expert.average_rating || 0.0}
              ratingCount={expert.review_count || 0}
            />

            <Button
              data={{
                text: "Hire This Expert",
                width: "100%",
                onClick: () => {
                  if (!user) {
                    navigate("/signup");
                  } else {
                    setShowContactModal(true);
                  }
                },
              }}
            />
            <Button
              data={{
                text: "Write a review",
                type: "basic",
                width: "100%",
                margin: "10px 0 0 0",
                onClick: () => setShowReviewModal(true),
              }}
            />
            {showContactModal && (
              <ContactModal
                hide={() => setShowContactModal(false)}
                expert={data}
              />
            )}
            {showReviewModal && (
              <Modal
                hide={() => setShowReviewModal(false)}
                header={{ title: "Write A Review" }}
              >
                <Text
                  data={{
                    fontStyle: "bodyLg",
                    text: "This feature is unavailable until you have completed a project with this expert",
                  }}
                />
              </Modal>
            )}
            {activeDemoApp && (
              <Modal
                hide={() => setActiveDemoAppIndex(null)}
                header={{ title: get(activeDemoApp, "name") }}
                minWidth="900px"
              >
                <DemoAppRow>
                  <div style={{ flex: 1 }}>
                    <Text
                      data={{
                        text: "Description",
                        fontStyle: "headingSm",
                        margin: "0 0 10px 0",
                      }}
                    />
                    <Text
                      data={{
                        text:
                          activeDemoApp.fullDescription ||
                          activeDemoApp.description,
                        fontStyle: "bodyLg",
                        whiteSpace: "pre-line",
                      }}
                    />
                    {get(activeDemoApp, "type", "frontlyApp") ===
                      "frontlyApp" &&
                      activeDemoApp.subdomain && (
                        <Button
                          data={{
                            text: "View Live App",
                            icon: "FiEye",
                            margin: "15px 0 0 0",
                            onClick: () =>
                              window.open(
                                `https://${activeDemoApp.subdomain}.frontly.ai`
                              ),
                          }}
                        />
                      )}
                    {get(activeDemoApp, "type", "frontlyApp") === "other" &&
                      activeDemoApp.link && (
                        <Button
                          data={{
                            text: "View Project",
                            icon: "FiEye",
                            margin: "15px 0 0 0",
                            onClick: () => window.open(activeDemoApp.link),
                          }}
                        />
                      )}
                  </div>
                  <PreviewImage
                    src={resizeImage({
                      url: activeDemoApp.image,
                      height: 500,
                      quality: 70,
                      forceJpg: true,
                    })}
                  />
                </DemoAppRow>
              </Modal>
            )}
          </ProfileDetails>

          <ProfileInfo style={{ gap: "15px" }}>
            <BulletList
              label="Languages"
              items={get(data, "languages", "")
                .split(",")
                .map((v) => v.trim())
                .filter((v) => v !== "")}
            />
            <BulletList
              label="Specializations"
              items={get(data, "specializations", "")
                .split(",")
                .map((v) => v.trim())
                .filter((v) => v !== "")}
            />
          </ProfileInfo>
        </LeftColumn>

        <RightColumn>
          <Section>
            <SectionLabel>About {data?.name}</SectionLabel>
            <RichText
              noValuesText={"This expert has not completed their profile"}
              block={{
                content: data?.description,
              }}
            />
          </Section>

          <Section>
            <SectionLabel>Previous Work</SectionLabel>
            {demoApps.length === 0 && (
              <Text data={{ text: "This expert has no demo apps listed" }} />
            )}
            <DemoAppsContainer count={demoApps.length}>
              {demoApps.map((demoApp, demoAppIndex) => (
                <DemoAppWrapper
                  onClick={() => setActiveDemoAppIndex(demoAppIndex)}
                >
                  <DemoAppImage
                    src={resizeImage({
                      url: demoApp.image,
                      height: 300,
                      quality: 70,
                      forceJpg: true,
                    })}
                  />
                  <Text data={{ text: demoApp.name, fontStyle: "headingMd" }} />
                  <Text data={{ text: demoApp.description }} />
                </DemoAppWrapper>
              ))}
            </DemoAppsContainer>
          </Section>
          <Section>
            <Row justifyContent="space-between" alignItems="center">
              <Row alignItems="center" gap="10px">
                <SectionLabel style={{ margin: "0px" }}>Reviews</SectionLabel>
                <StarRatingRow
                  rating={expert.average_rating || 0.0}
                  ratingCount={expert.review_count || 0}
                  margin={"0px"}
                />
              </Row>
              <Button
                data={{
                  text: "Write Review",
                  type: "basic",
                  onClick: () => setShowReviewModal(true),
                }}
              />
            </Row>
            {reviews.length === 0 && (
              <Text data={{ text: "This expert has no reviews yet" }} />
            )}
            {reviews.map((review) => (
              <ReviewContainer>
                <ReviewLabel>{review.title}</ReviewLabel>
                <Row alignItems="center" gap="9px">
                  <RatingStars rating={review.rating} />
                  <Text
                    data={{
                      text: "|",
                      color: colors.divider,
                      fontStyle: "bodyLg",
                    }}
                  />
                  <Text data={{ text: review.date }} />
                  <Text
                    data={{
                      text: "|",
                      color: colors.divider,
                      fontStyle: "bodyLg",
                    }}
                  />
                  <Text data={{ text: review.customer }} />
                </Row>
                <Text data={{ text: review.content, margin: "5px 0 0 0" }} />
              </ReviewContainer>
            ))}
          </Section>
        </RightColumn>
      </MainRow>
    </Container>
  );
};

export default Profile;

const ContactModal = ({ expert, hide }) => {
  const [projectDetails, setProjectDetails] = useState({});

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const navigate = useNavigate();

  const submitProject = () =>
    setConfirmationModalData({
      title: "Submit Project?",
      text: "Once the project is submitted, the expert will respond with a quote or follow up questions to assess the scope of the project.",
      confirm: () => {
        successNotification("Project Sent To Expert");
        // SEND PROJECT TO EXPERT
        hide();
        apiRequest
          .post("/send_expert_request/", {
            ...projectDetails,
            expert_id: expert.id,
          })
          .then((r) => {
            const projectId = get(r, ["data", "project_id"]);
            navigate(`/project/${projectId}`);
          });
      },
    });

  return (
    <Modal
      hide={hide}
      header={{ title: `Hire ${get(expert, "name")} For Your Project` }}
      buttons={[
        {
          text: "Submit Project To Expert",
          onClick: submitProject,
        },
      ]}
    >
      <Text
        data={{
          text: "Fill out the information below so that the expert can review your project and provide a quote",
          fontStyle: "bodyMd",
          margin: "0 0 20px 0",
        }}
      />

      <Text
        data={{
          text: "Project Name",
          fontStyle: "headingMd",
          margin: "0 0 5px 0",
        }}
      />
      <Input
        data={{
          value: get(projectDetails, "name"),
          onChange: (v) => setProjectDetails({ ...projectDetails, name: v }),
          placeholder: "Education Portal...",
          width: "100%",
        }}
      />

      <Text
        data={{
          text: "Project Description",
          fontStyle: "headingMd",
          margin: "20px 0 5px 0",
        }}
      />
      <TextArea
        data={{
          value: get(projectDetails, "summary"),
          onChange: (v) => setProjectDetails({ ...projectDetails, summary: v }),
          placeholder:
            "Provide as much detail as possible to help the expert assess your project and provide an accurate quote.",
          minHeight: "200px",
        }}
      />

      {/* <Text
        data={{
          text: "Select Project Plan",
          fontStyle: "headingMd",
          margin: "15px 0 0 0",
        }}
      />
      <Text
        data={{
          text: "Select an existing project plan or create one now to share with the expert",
          fontStyle: "bodySm",
          margin: "5px 0 5px 0",
        }}
      />
      <Text
        data={{
          text: `Selected: ${planName || "None"}`,
          margin: "0 0 15px 0",
        }}
      /> */}

      {/* <Row gap="15px" alignItems="center">
        <Button
          data={{
            text: "Select Project Plan",
            onClick: () => setShowPast(true),
            type: "basic",
          }}
        />
        <Text data={{ text: "or" }} />
        <Button
          data={{
            text: "New Project Plan",
            onClick: () => setShowPast(true),
            type: "basic",
          }}
        />
      </Row>

      {showPast && (
        <PastProjectPlans
          hide={() => setShowPast(false)}
          onSelect={(planId) => setSelectedPlanId(planId)}
        />
      )} */}
    </Modal>
  );
};

const DemoAppRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 15px;
  }
`;

// if not last item, show border
const ReviewContainer = styled.div`
  border-bottom: 1px solid ${colors.divider};
  padding: 20px 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Container = styled.div`
  padding: 20px;
`;

const ReviewLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const LeftColumn = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media (max-width: 800px) {
    gap: 15px;
    width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;
`;

const Section = styled.div`
  margin-bottom: 50px;
`;

const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const BulletList = ({ label, items }) => {
  return (
    <div>
      <BulletListLabel>{label}</BulletListLabel>
      {items.map((item) => (
        <BulletListItem>{item}</BulletListItem>
      ))}
      {items.length === 0 && <div>No {label}</div>}
    </div>
  );
};

const DemoAppsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  ${(p) => p.count < 3 && "grid-template-columns: 280px 280px;"}
  gap: 20px;
`;

const DemoAppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border: 1px solid ${colors.grey2};
  border-radius: 10px;
  cursor: pointer;
`;

const DemoAppImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const PreviewImage = styled.img`
  width: 450px;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid ${colors.grey2};
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const BulletListLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
`;

const BulletListItem = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.grey1};
  border: 1px solid ${colors.grey2};
  border-radius: 10px;
  padding: 20px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.grey1};
  border: 1px solid ${colors.grey2};
  border-radius: 10px;
  padding: 20px;
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  border: 1px solid ${colors.grey2};
`;

const ProfileName = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
`;

const ProfileLocation = styled.div`
  font-size: 16px;
`;

const StarRatingRow = ({ rating, ratingCount, margin }) => (
  <Row alignItems="center" margin={margin}>
    <Icon
      data={{
        size: 20,
        icon: "BsFillStarFill",
        color: "#FFD700",
      }}
    />
    <RatingText>{safeToFixed(rating, 2)}</RatingText>
    <RatingCount>({ratingCount})</RatingCount>
  </Row>
);
