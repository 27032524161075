import { Button, Modal, Row, SearchBar, Spinner, Text } from "app/components";
import { rProjectPlans, rUser } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AdminWrapper from "../../home/AdminWrapper";
import { Label } from "../../home/Pages";
import Workflow from "app/Workflow";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { errorNotification } from "app/utils/Notification";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { truncateText } from "app/utils/utils";
import { useRecoilValue } from "recoil";

const ProjectPlanner = () => {
  const { id } = useParams();

  const user = useRecoilValue(rUser);

  const defaultMessages = [
    {
      role: "system",
      content: `Hi ${get(
        user,
        "first_name"
      )}! I'm your AI project manager. My job is to understand your project requirements.`,
    },
    {
      role: "system",
      content:
        "To start, can you please provide an overview of your business and app project?",
    },
  ];

  const [thread, setThread] = useState(defaultMessages);
  const [name, setName] = useState(null);

  const [showWorkflow, setShowWorkflow] = useState(false);
  const [workflow, setWorkflow] = useState(null);

  const [isGenerating, setIsGenerating] = useState(false);

  const [newMessage, setNewMessage] = useState("");

  const wsURL =
    "wss://o0v9pmvz0g.execute-api.ca-central-1.amazonaws.com/production";

  useEffect(() => {
    const ws = new WebSocket(wsURL);

    ws.onmessage = (event) => {
      const messageData = JSON.parse(event.data);

      console.log("messageData", messageData);

      const newWorkflow = get(messageData, "workflow");
      const workflowsArray = get(newWorkflow, "workflows", []);

      const response = get(messageData, "response");
      const previousThread = get(messageData, "thread", []);
      const pId = get(messageData, "plan_id");
      const question = get(response, "question");
      const requrementsGathered = get(response, "requirements_gathered");
      const finalizedAppName = get(response, "finalized_app_name");

      if (finalizedAppName) {
        setName(finalizedAppName);
      }

      setIsGenerating(false);

      if (newWorkflow) {
        // Set workflow object to local state
        setWorkflow(workflowsArray);

        // Save workflow response
        apiRequest
          .post(
            "/project_planner/save/",
            {
              plan_id: pId,
              workflow: workflowsArray,
              app_name: finalizedAppName,
            },
            {
              timeout: 20000,
            }
          )
          .then((r) => {
            const newPlanId = get(r, ["data", "new_plan_id"]);
            if (newPlanId) {
              navigate(`/project-planner/${newPlanId}`);
            }
          });
      } else if (question && !requrementsGathered) {
        const newThread = [
          ...previousThread,
          {
            role: "system",
            content: question,
          },
        ];

        setThread(newThread);

        // Save response
        apiRequest
          .post(
            "/project_planner/save/",
            { plan_id: pId, thread: newThread, app_name: finalizedAppName },
            {
              timeout: 20000,
            }
          )
          .then((r) => {
            const newPlanId = get(r, ["data", "new_plan_id"]);
            if (newPlanId) {
              navigate(`/project-planner/${newPlanId}`);
            }
          });
      } else if (requrementsGathered) {
        setThread([
          ...previousThread,
          {
            role: "system",
            content:
              "Thank you! Now that I understand your project, I will generate a step-by-step user workflow chart. One moment please...",
          },
        ]);

        if (finalizedAppName) {
          // Save name
          apiRequest.post(
            "/project_planner/save/",
            { plan_id: pId, app_name: finalizedAppName },
            {
              timeout: 20000,
            }
          );
        }

        generateWorkflow(previousThread);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    window.ws = ws;

    return () => {
      ws.close();
    };
  }, []);

  const generateWorkflow = (currentThread) => {
    setIsGenerating(true);
    if (window.ws && window.ws.readyState === WebSocket.OPEN) {
      window.ws.send(
        JSON.stringify({
          action: "generateWorkflow",
          thread: currentThread,
          plan_id: id,
        })
      );
    } else {
      console.error("WebSocket is not open.");
    }
  };

  const sendMessage = () => {
    const newThread = [
      ...thread,
      {
        content: newMessage,
        role: "user",
      },
    ];

    if (newMessage.length < 2) {
      errorNotification("Please enter a longer message.");
    } else {
      setThread(newThread);
      setNewMessage("");
      setIsGenerating(true);
      if (window.ws && window.ws.readyState === WebSocket.OPEN) {
        const projId = id === "new" ? null : id;
        window.ws.send(
          JSON.stringify({
            action: "projectPlanner",
            thread: newThread,
            plan_id: projId,
          })
        );
      } else {
        console.error("WebSocket is not open.");
      }
    }
  };

  const [isFetching, setIsFetching] = useState(false);

  const fetchPlan = (pId) => {
    setIsFetching(true);
    apiRequest.get(`/project_planner/details/?plan_id=${pId}`).then((res) => {
      const name = get(res, ["data", "name"], []);
      setName(name);
      const thread = get(res, ["data", "thread"], []);
      setThread(thread);
      const wf = get(res, ["data", "result"], []);
      setWorkflow(wf);
      setIsFetching(false);
    });
  };

  useEffect(() => {
    if (id !== "new") {
      fetchPlan(id);
    }
  }, []);

  const navigate = useNavigate();

  const [showPast, setShowPast] = useState(false);

  return (
    <AdminWrapper contentWidth="100%">
      {showPast && (
        <PastProjectPlans
          hide={() => setShowPast(false)}
          onSelect={(planId) => {
            navigate(`/project-planner/${planId}`);
            fetchPlan(planId);
          }}
        />
      )}

      <Row justifyContent="space-between" alignItems="center">
        <Label>Project Planner {name ? ` - ${name}` : ""}</Label>

        <Row gap={"15px"} alignItems="center">
          {/* <Button
            data={{
              text: "View Previous Plans",
              onClick: () => setShowPast(true),
              type: "basic",
            }}
          /> */}
          {id !== "new" && (
            <Button
              data={{
                text: "Create New Project Plan",
                onClick: () => {
                  navigate("/project-planner/new");
                  setThread(defaultMessages);
                },
              }}
            />
          )}
        </Row>
      </Row>
      {/* <BackButton
        width="auto"
        margin="20px 0 0 0"
        text="Home"
        onClick={() => navigate("/home")}
      /> */}
      <Row gap="30px" margin="25px 0 0 0">
        {workflow && (
          <OutlinePanel>
            <Text
              data={{
                text: "Project Workflows",
                fontStyle: "headingLg",
                margin: "0 0 20px 0",
              }}
            />
            <Button
              data={{
                text: "Click To View",
                onClick: () => setShowWorkflow(true),
              }}
            />

            {showWorkflow && (
              <Modal
                minWidth="95%"
                header={{ title: "App Workflows" }}
                hide={() => setShowWorkflow(false)}
              >
                <Workflow data={workflow} />
              </Modal>
            )}
          </OutlinePanel>
        )}
        {isFetching ? (
          <PreviewPanel>
            <Spinner color={colors.grey3} />
          </PreviewPanel>
        ) : (
          <PreviewPanel>
            <Text
              data={{
                text: "Conversation",
                fontStyle: "headingLg",
                margin: "0 0 20px 0",
              }}
            />
            <MessagesContainer>
              {thread.map((m) => {
                return (
                  <MessageContainer currentUser={m.role !== "user"}>
                    {m.content}
                  </MessageContainer>
                );
              })}
              {isGenerating && (
                <MessageContainer currentUser={true}>
                  <Spinner color={"white"} padding={"10px"} size={20} />
                </MessageContainer>
              )}
            </MessagesContainer>
            <StyledTextArea
              onChange={(e) => setNewMessage(e.target.value)}
              value={newMessage}
              placeholder="Type your message here"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  sendMessage();
                }
              }}
            />
            <Button
              data={{
                text: "Send Message",
                onClick: sendMessage,
                margin: "10px 0 0 0",
                // isFetching: isGenerating,
              }}
            />
          </PreviewPanel>
        )}
      </Row>
    </AdminWrapper>
  );
};

export default ProjectPlanner;

const PastProjectPlans = ({ hide, onSelect }) => {
  const [search, setSearch] = useState("");
  const plans = useRecoilValue(rProjectPlans);

  return (
    <Modal
      minWidth="1000px"
      header={{
        title: "Previously Created App Plans",
        description: "Click on a plan to view it.",
      }}
      hide={hide}
      background={colors.grey1}
    >
      <SearchBar
        data={{
          value: search,
          placeholder: "Search app plans by id",
          margin: "10px 0 20px 0",
          onChange: (v) => setSearch(v),
        }}
      />

      <PlansGrid>
        {plans
          .filter(
            (p) =>
              search === "" ||
              get(p, "name").toLowerCase().includes(search.toLowerCase())
          )
          .map((p) => (
            <PlanBubble
              onClick={() => {
                hide();
                onSelect(p.id);
              }}
            >
              <Text
                data={{
                  text: truncateText(get(p, "name"), 30),
                  fontStyle: "headingMd",
                  cursor: "pointer",
                }}
              />
              <Text
                data={{
                  text: get(p, "date")
                    ? moment(get(p, "date")).fromNow()
                    : moment().fromNow(),
                  fontStyle: "bodyMd",
                  cursor: "pointer",
                }}
              />
            </PlanBubble>
          ))}
      </PlansGrid>
    </Modal>
  );
};

const StyledTextArea = styled.textarea`
  resize: none;
  min-height: 50px;
  height: fit-content;
  background: ${colors.grey1};
  border: 1px solid ${colors.grey2};
  width: 100%;
  padding: 15px;
  font-size: 14px;
  outline: none;
  font-weight: 400;
  border-radius: 15px;
  color: ${colors.default};
  &::placeholder {
    color: ${colors.grey3};
  }
`;

const MessagesContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  gap: 15px;
  display: flex;
  margin: 0 0 25px 0;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  padding: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: fit-content;
  align-self: ${(p) => (p.currentUser ? "flex-end" : "flex-start")};
  background: ${(p) => (p.currentUser ? colors.primary : colors.grey1)};
  color: ${(p) => (p.currentUser ? "white" : colors.default)};
  border: 1px solid ${(p) => (p.currentUser ? colors.primary : colors.grey2)};
`;

const OutlinePanel = styled.div`
  min-width: 300px;
  max-width: 300px;
  padding: 20px;
  border: 1px solid ${colors.grey2};
  background: white;
  border-radius: 15px;
`;

const PreviewPanel = styled.div`
  border: 1px solid ${colors.grey2};
  background: white;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  padding: 20px;
`;

const PlansGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const PlanBubble = styled.div`
  background: white;
  border: 1px solid ${colors.grey2};
  padding: 15px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background: ${colors.grey1};
  }
`;
