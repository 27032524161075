import {
  getPixels,
  handleClientSidePagination,
  isFrontlyAdmin,
} from "app/utils/utils";
import {
  rApp,
  rLiveSpreadsheets,
  rPagination,
  rSavedSpreadsheets,
  rTranslations,
  refreshBlockIdsSelector,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Pagination } from "@mui/material";
import { Text } from "app/components";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const PaginationContainer = styled.div`
  padding: ${(p) => p.padding};
  border-top: ${(p) =>
    p.hideBorder
      ? "0px"
      : `1px solid ${p.darkMode ? colors.darkModeLightBorder : "#e1e3e5"}`};
`;

export function PaginationWrapper({
  children,
  items = [],
  itemsPerPage,
  showFirstButton = false,
  showLastButton = false,
  hideBorder = false,
  padding = "0px",
  noResultsPadding = "0px",
  isFetching = false,
  noResultsName = "results",
  darkMode = false,
  block,
}) {
  const [recoilPagination, setPagination] = useRecoilState(rPagination);
  const setRefreshBlockIds = useSetRecoilState(refreshBlockIdsSelector);

  const spreadsheets = useRecoilValue(rLiveSpreadsheets);

  const translations = useRecoilValue(rTranslations);

  const isReusable = get(block, "componentId") === "Custom";

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const matchingSheet = savedSpreadsheets.find(
    (s) => s.id === get(block, "spreadsheet")
  );
  const service = get(matchingSheet, "service");

  const isSupabase = service === "supabase";

  const livePagination = get(spreadsheets, "pagination", {});
  const blockPagination = get(livePagination, get(block, "id"), {});

  const [localPage, setLocalPage] = useState(1);

  let paginatedItems = [...items];

  const itemsLength = isSupabase
    ? get(blockPagination, "count", 0)
    : get(items, "length", 0);

  // If user is on page 2 or greater and results are less than a page, reset to page one
  useEffect(() => {
    if (!isSupabase) {
      if (localPage > 1 && itemsLength <= itemsPerPage) {
        setLocalPage(1);
      }
    }
  }, [itemsLength]);

  useEffect(() => {
    if (!isSupabase) {
      setLocalPage(1);
    }
    return () => {};
  }, [itemsPerPage]);

  useEffect(() => {
    if (isSupabase) {
      if (blockPagination.page) {
        setLocalPage(blockPagination.page);
      }
    }
  }, []);

  const showPagination =
    isSupabase || (itemsLength > itemsPerPage && itemsPerPage);

  const clientSidePageCount = Number.isFinite(
    Math.ceil(itemsLength / itemsPerPage)
  )
    ? Math.ceil(itemsLength / itemsPerPage)
    : 0;

  if (!isSupabase && itemsPerPage) {
    paginatedItems = handleClientSidePagination({
      items: items,
      currentPage: localPage,
      itemsPerPage: itemsPerPage,
    });
  }

  const paginationProps = {
    className: "mui-pagination",
    showFirstButton: showFirstButton,
    showLastButton: showLastButton,
    variant: "outlined",
    shape: "rounded",
    color: "primary",
  };

  const activeApp = useRecoilValue(rApp);

  const styling = get(activeApp, "styling", {});
  const blockBorderRadius = isFrontlyAdmin
    ? null
    : get(styling, "formInputRadius");

  let pagination = (
    <Pagination
      {...paginationProps}
      page={localPage}
      count={clientSidePageCount}
      onChange={(e, page) => {
        if (isSupabase) {
          setPagination({
            ...recoilPagination,
            [block.id]: {
              ...blockPagination,
              page: page,
            },
          });
          setRefreshBlockIds([block.id]);
          setLocalPage(page);
        } else {
          setLocalPage(page);
        }
      }}
      sx={{
        "& .MuiPaginationItem-root": {
          color: darkMode ? colors.darkModeLightGrey : colors.grey4, // For text color
          borderRadius: getPixels(blockBorderRadius),
          borderColor: darkMode && colors.darkModeLightBorder,
        },
        "& .MuiPaginationItem-root.Mui-selected": {
          backgroundColor: darkMode
            ? colors.darkModeLightBackground
            : colors.grey1, // Background color for the currently active page
          borderColor: darkMode ? colors.darkModeLightGrey : colors.grey3,
          color: darkMode ? "white" : colors.grey4, // For text color
        },
      }}
    />
  );

  return (
    <>
      {!isFetching && get(paginatedItems, "length", 0) === 0 && (
        <Text
          data={{
            text:
              get(translations, "noResultsMatch") ||
              `No ${noResultsName} match this search.`,
            fontStyle: "bodyLg",
            padding: noResultsPadding,
            color: darkMode ? "lightGrey" : "grey4",
          }}
        />
      )}

      {isFetching
        ? children([])
        : get(paginatedItems, "length", 0) > 0 && children(paginatedItems)}

      {!isFetching && showPagination ? (
        <>
          {isReusable && pagination}

          {!isReusable && (
            <PaginationContainer
              hideBorder={hideBorder}
              padding={padding}
              darkMode={darkMode}
            >
              {pagination}
            </PaginationContainer>
          )}
        </>
      ) : null}
    </>
  );
}

export default PaginationWrapper;
