import { CardContent, Icon, Row, Text } from "app/components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { boxShadow, colors, spacing } from "app/utils/theme";
import { get, isNil, toString } from "lodash";
import {
  getPixels,
  isFrontlyAdmin,
  resizeImage,
  truncateText,
} from "app/utils/utils";
import { rApp, rDarkMode, spreadsheetsSelector } from "app/utils/recoil";
import styled, { css } from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";

import { GridImage } from "./Grid";
import KanbanAdmin from "./KanbanAdmin";
import { getBadgeColorMap } from "./Table";
import { getUniqueBadgeColors } from "app/components/Badge";
import useActionResolver from "../useActionResolver";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";
import useUtils from "app/renderingApp/useUtils";

const getUniqueItemId = (index = 0) => {
  const rand = Math.random().toString(36);
  return `${rand}-${index}`;
};

const Kanban = ({ block, page }) => {
  const { processObjects } = useProcessObjects();

  const { getListData } = useListData();

  const darkMode = useRecoilValue(rDarkMode);

  const activeApp = useRecoilValue(rApp);

  const { recordClick, processDynamicText, addModalToStack } = useUtils();
  const { updateRecord } = useSpreadsheetRequests();
  const { actionResolver } = useActionResolver(page);

  const [spreadsheets, setSpreadsheets] = useRecoilState(spreadsheetsSelector);

  if (isFrontlyAdmin || block.isFetching) {
    return <KanbanAdmin block={block} page={page} />;
  }

  const data = getListData(block);

  const updateActiveSheet = (newColumns, frontlyId, newValue) => {
    const excludedKeys = [
      "badgeColors",
      "frontly_data",
      "index",
      "dateFormat",
      "uniqueItemId",
    ];

    const flatList = newColumns
      .reduce((acc, curr) => {
        return [...acc, ...curr.columnItems];
      }, [])
      .map((item, i) => {
        let returnItem = { ...item };

        // If we're updating a specific record, update it
        if (item.frontly_id === frontlyId) {
          returnItem = {
            ...returnItem,
            [block.columnKey]: newValue,
          };
        }

        // Remove excluded keys from item
        returnItem = Object.keys(returnItem)
          .filter((key) => !excludedKeys.includes(key))
          .reduce((obj, key) => {
            obj[key] = returnItem[key];
            return obj;
          }, {});

        return returnItem;
      });

    setSpreadsheets({
      ...spreadsheets,
      [block.id]: flatList,
    });
  };

  const fields = get(block, "fields", []);
  const finalFields = [
    {
      mapKey: "image", // This tells processObjects to convert the mapped key to 'image'
      key: get(block, "image"),
    },
    ...fields,
  ];

  const items = processObjects(
    page,
    block,
    finalFields,
    data,
    processDynamicText
  ).map((item, i) => ({
    index: i,
    ...item,
    uniqueItemId: getUniqueItemId(i),
  }));

  const columns = get(block, "columns", []).map((c) => ({
    ...c,
    columnItems: items.filter((item) => get(item, block.columnKey) === c.value),
  }));

  const onCardMoved = async (movedCard, newColumn) => {
    await updateRecord({
      sheetId: block.spreadsheet,
      values: [{ key: block.columnKey, value: newColumn }],
      recordId: movedCard.frontly_id,
    });

    // Run custom action if defined
    actionResolver({
      rawAction: get(block, ["actionMap", "itemMoveAction"]),
      actionId: get(block, "itemMoveAction"),
      context: {
        record: { ...movedCard, [block.columnKey]: newColumn },
        repeatingRecord: get(block, "repeatingRecord"),
      },
      blockId: block.id,
    });
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    // DIFFERENT COLUMN
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId - 1];
      const destColumn = columns[destination.droppableId - 1];
      const sourceTasks = [...sourceColumn.columnItems];
      const destTasks = [...destColumn.columnItems];
      const [removed] = sourceTasks.splice(source.index, 1);
      destTasks.splice(destination.index, 0, removed);

      const newStatusLabel = get(destColumn, "value");
      onCardMoved(removed, newStatusLabel);

      return;
    }

    // SAME COLUMN
    const column = columns[source.droppableId - 1];
    const copiedTasks = [...column.columnItems];
    const [removed] = copiedTasks.splice(source.index, 1);
    copiedTasks.splice(destination.index, 0, removed);

    // UPDATE UI
    const newColumns = JSON.parse(JSON.stringify(columns));

    newColumns[source.droppableId - 1] = {
      ...newColumns[source.droppableId - 1],
      columnItems: copiedTasks,
    };

    // TODO - Update value?
    updateActiveSheet(newColumns);
  };

  const initialColumnColors = [
    "#df516e",
    "#f79e44",
    "#f7c744",
    "#74c98f",
    "#429ef0",
    "#6945e1",
  ];

  const columnColors = getUniqueBadgeColors(columns.map((c) => c.value));

  const recordLabel = get(block, "labelSingular") || "Record";

  const createModeLabel = get(block, "createModeLabel") || `New ${recordLabel}`;

  const showCreate = (colValue) =>
    !isFrontlyAdmin &&
    addModalToStack({
      label: createModeLabel,
      blockId: block.id,
      type: "create",
      defaultValues: { [get(block, "columnKey")]: colValue },
    });

  // Get user's custom defined badge colors
  const badgeColorMap = getBadgeColorMap(get(block, "badgeColors", []));

  // Find height of available container for grid layout
  const inGridLayout = get(block, "inGridLayout");

  const styling = get(activeApp, "styling");
  const blockPadding = get(styling, "blockPadding") * 2;
  const blockBorder = get(styling, "blockBorder");
  const blockBorderRadius = get(styling, "blockBorderRadius");
  const blockBorderColor = get(styling, "blockBorderColor");
  const blockBoxShadow = get(styling, "blockBoxShadow");
  const finalBorder = blockBorder ? "1px solid " + blockBorderColor : "none";

  const imageHeight = get(block, "imageHeight", "150px");

  let height = get(block, "height") ? getPixels(get(block, "height")) : "auto";
  if (inGridLayout) {
    height = getPixels(get(block, "gridHeight"));
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <ColumnsContainer height={height}>
        {columns.map((c, i) => {
          const { columnItems } = c;

          const columnColor =
            c.color ||
            get(initialColumnColors, i) ||
            get(columnColors, ["columns", c.value]);

          return (
            <Column key={i}>
              <KanbanColumnHeader
                darkMode={darkMode}
                color={columnColor}
                border={finalBorder}
                boxShadow={blockBoxShadow}
                borderRadius={blockBorderRadius}
              >
                <Row alignItems="center" gap="10px">
                  <Text
                    data={{
                      text: truncateText(c.label, 20),
                      fontStyle: "headingMd",
                      color: darkMode ? "white" : null,
                    }}
                  />
                  <CountWrapper darkMode={darkMode}>
                    <Text
                      data={{
                        text: columnItems.length,
                        fontStyle: "headingMd",
                        color: colors.grey3,
                      }}
                    />
                  </CountWrapper>
                </Row>
                {block?.showCreate && (
                  <Icon
                    data={{
                      icon: "FiPlus",
                      color: colors.grey3,
                      hover: true,
                      onClick: () => showCreate(c.value),
                    }}
                  />
                )}
              </KanbanColumnHeader>
              <Droppable droppableId={toString(i + 1)} key={i + 1}>
                {(provided, snapshot) => {
                  return (
                    <DroppableArea
                      isDraggingOver={snapshot.isDraggingOver}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {columnItems.map((item, index) => (
                        <Draggable
                          isDragDisabled={
                            get(block, "showEdit") === false || isFrontlyAdmin
                          }
                          draggableId={item.uniqueItemId}
                          key={item.uniqueItemId}
                          index={index}
                          style={{ overflow: "visible" }}
                        >
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef}>
                              <Card
                                imageHeight={imageHeight}
                                blockId={block.id}
                                key={item.uniqueItemId}
                                // key={index}
                                item={{ ...item, badgeColorMap }}
                                fields={fields}
                                onClick={() =>
                                  recordClick(block, item, actionResolver)
                                }
                                isDragging={snapshot.isDragging}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  background: darkMode
                                    ? colors.darkModeLightBackground
                                    : "white",
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </DroppableArea>
                  );
                }}
              </Droppable>
            </Column>
          );
        })}
      </ColumnsContainer>
    </DragDropContext>
  );
};

export default Kanban;

const Card = ({
  item,
  fields,
  onClick,
  blockId,
  imageHeight,
  skeleton,
  ...rest
}) => {
  const activeApp = useRecoilValue(rApp);
  const darkMode = useRecoilValue(rDarkMode);

  const styling = get(activeApp, "styling");
  const blockBorder = get(styling, "blockBorder");
  const blockBorderRadius = get(styling, "blockBorderRadius");
  const blockBorderColor = get(styling, "blockBorderColor");
  const blockBoxShadow = get(styling, "blockBoxShadow");
  const finalBorder = blockBorder ? "1px solid " + blockBorderColor : "none";

  return (
    <KanbanItemContainer
      darkMode={darkMode}
      onClick={onClick}
      {...rest}
      border={finalBorder}
      boxShadow={blockBoxShadow}
      borderRadius={blockBorderRadius}
    >
      {item.image && (
        <GridImage
          uniqueId={item.frontly_id}
          imageHeight={imageHeight}
          src={resizeImage({
            url: item.image,
            height: 300,
            quality: 60,
            forceJpg: true,
          })}
        />
      )}
      <CardContent
        fields={fields}
        item={item}
        blockId={blockId}
        skeleton={skeleton}
      />
    </KanbanItemContainer>
  );
};

const ColumnsContainer = styled.div`
  display: flex;
  gap: ${spacing.s6};
  overflow-x: scroll;
  margin: 0 0 0 -5px;
  height: ${(p) => p.height};
  overflow-y: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
`;

const KanbanColumnHeader = styled.div`
  background: ${(p) => (p.darkMode ? colors.darkModeLightBackground : "white")};
  padding: ${spacing.s3};

  border-radius: ${(p) =>
    getPixels(isNil(p.borderRadius) ? 8 : p.borderRadius)};
  box-shadow: ${(p) => (p.boxShadow === false ? null : boxShadow.card)};
  border: ${(p) => p.border};

  border-top: 3px solid ${(p) => p.color || colors.primary};
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin: 0 0 8px 0;
`;

const CountWrapper = styled.div`
  padding: 5px;
  border: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightBorder : colors.grey2)};
  border-radius: 13px;
  width: 34px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KanbanItemContainer = styled.div`
  background: ${(p) => (p.darkMode ? colors.darkModeLightBackground : "white")};
  padding: ${spacing.s4};
  border-radius: ${(p) =>
    getPixels(isNil(p.borderRadius) ? 8 : p.borderRadius)};
  box-shadow: ${(p) => (p.boxShadow === false ? null : boxShadow.card)};
  border: ${(p) => p.border};

  transition: background-color 140ms ease-in-out 0s, color 140ms ease-in-out 0s;
  ${(props) =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: white;
      box-shadow: none;
    `};
`;

const DroppableArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
  padding: 5px;
  border: 2px solid transparent;
  ${(props) => props.isDraggingOver && "border: 2px dashed rgba(0, 0, 0, 0.2)"};
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 280px;
`;
