import { Breadcrumb, Icon, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";
import { get, startCase } from "lodash";
import {
  rActiveDetailViewId,
  rApp,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useRef, useState } from "react";

import AdminForm from "./AdminForm";
import { Popover } from "@mui/material";
import { SmartString } from ".";
import { safeArray } from "app/utils/utils";
import styled from "styled-components";
import useActiveBlock from "app/utils/useActiveBlock";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";

const DynamicString = ({ data }) => {
  const { value, onChange, disabled = false } = data;

  const activeBlock = useActiveBlock();

  // const page = useRecoilValue(rPage);
  const page = usePage();

  const blocks = get(page, "blocks", []);

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const activeApp = useRecoilValue(rApp);

  const isNew = false;

  const [selectedDataSource, setSelectedDataSource] = useState(null);

  const [savedRange, setSavedRange] = useState(null);

  const [fieldCountChanged, setFieldCountChanged] = useState(false);

  // OLD SHIT
  const [anchorEl, setAnchorEl] = useState(false);

  const showPopup =
    anchorEl ||
    (savedRange &&
      savedRange.startOffset === savedRange.endOffset &&
      savedRange.endContainerId === savedRange.startContainerId);

  // DETAIL VIEW SHIT --------------------------------------------------

  // All spreadsheets
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  // NEW DETAIL SOURCE -------------------
  // Detail view
  const detailViewBlock = blocks.find((b) => b.id === activeDetailViewId);
  const detailViewSheet = savedSpreadsheets.find(
    (s) => s.id === get(detailViewBlock, "spreadsheet")
  );
  // Detail view parent
  const detailViewParentBlock = blocks.find(
    (b) => b.id === get(detailViewBlock, "parent")
  );
  const detailViewParentSheet = savedSpreadsheets.find(
    (s) => s.id === get(detailViewParentBlock, "spreadsheet")
  );
  // ------------------------

  // The block that was clicked to trigger the detail view
  const currentBlock = activeBlock || detailViewBlock;
  const currentSheet = savedSpreadsheets.find(
    (s) => s.id === get(currentBlock, "spreadsheet")
  );
  const currentSheetHeaders = get(currentSheet, "headers", []);

  // DETAIL VIEW SHIT --------------------------------------------------

  const inputRef = useRef(null);

  let dataSources = [
    {
      name: "User",
      key: "user",
      description: "Data from the logged-in user.",
    },
    {
      name: "Local State",
      key: "localState",
      description: "Custom variables stored in your local state.",
    },
    {
      name: "URL Parameters",
      key: "params",
      description: "Custom variables stored in your page URL via query params",
    },
    {
      name: "Time",
      key: "time",
      description: "Time-based variables like the current time, etc",
    },
  ];

  const customVariables = safeArray(activeApp, "custom_variables");

  if (customVariables.length > 0) {
    dataSources.push({
      name: "Custom Variables",
      key: "custom",
      description: "Custom variables defined in settings",
    });
  }

  // NEW DETAIL SOURCE
  if (detailViewBlock) {
    dataSources.push({
      name: "Detail View Record",
      key: "detail",
      description:
        "Data from the record that was clicked to open the detail view.",
    });
  }

  if (detailViewParentBlock) {
    dataSources.push({
      name: "Detail View Parent",
      key: "detailParent",
      description: "The detail view record up one level in the hierarchy.",
    });
  }

  // This is for edge cases where we want to specifically exclude a source
  const excludedSources = safeArray(data, "excludedSources");

  const dynamicSources = safeArray(data, "dynamicSources");
  const record = dynamicSources.includes("record");

  const previousSteps = get(data, "previousSteps", []);

  // ACTION STEPS
  if (previousSteps.length > 0) {
    dataSources.push({
      name: "Step",
      key: "step",
      description: "Data from a previous action step",
    });
  }

  if (record) {
    dataSources.push({
      name: "Record",
      key: "record",
      description: "Data from the current record.",
    });
  }

  const form =
    dynamicSources.includes("form") ||
    get(activeBlock, "componentId") === "Form";
  if (form) {
    dataSources.push({
      name: "Form Data",
      key: "form",
      description: "Data from the form that was submitted.",
    });
  }

  dataSources = [
    ...dataSources,
    {
      name: "Google Sheet Cell",
      key: "staticCell",
      description: "A value from a specific Google Sheet cell.",
    },
  ];

  const blockDataOptions = blocks
    .filter((b) => ["Form", "InfoList"].includes(b.componentId))
    .map((b) => ({
      value: b.id,
      label: get(b, "label", b.componentId) || b.componentId,
    }));

  if (blockDataOptions.length > 0) {
    dataSources.push({
      name: "Block Data",
      key: "block",
      description: "Data from another block on the page.",
    });
  }

  dataSources = dataSources.filter((ds) => !excludedSources.includes(ds.key));

  const getDataSourceFields = () => {
    if (selectedDataSource === "custom") {
      return customVariables.map((v) => v.key);
    }

    if (selectedDataSource === "user") {
      const userSchema = get(activeApp, "user_schema", []) || [];

      let finalHeaders = [
        "id",
        "email",
        "first_name",
        "last_name",
        "user_groups",
        "role",
        ...userSchema.map((s) => s.name),
      ];

      const usersSheetId = get(activeApp, [
        "users_sheet_config",
        "spreadsheet_id",
      ]);
      const usersSheetHeaders = get(
        savedSpreadsheets.find((s) => s.id === usersSheetId),
        "headers",
        []
      );

      finalHeaders = [
        ...finalHeaders,
        ...usersSheetHeaders.filter((h) => !finalHeaders.includes(h)),
      ];

      return finalHeaders;
    }

    // DETAIL
    if (selectedDataSource === "detail") {
      return get(detailViewSheet, "headers", []);
    }

    // DETAIL PARENT
    if (selectedDataSource === "detailParent") {
      return get(detailViewParentSheet, "headers", []);
    }

    if (selectedDataSource === "record" || selectedDataSource === "form") {
      return get(currentSheet, "headers", []);
    }

    if (selectedDataSource === "time") {
      return ["now", "today"];
    }

    if (selectedDataSource === "parent") {
      return currentSheetHeaders;
    }

    return [];
  };

  const dataSourceFields = getDataSourceFields();

  const addVariable = (path) => {
    if (isNew) {
      // NEW SHIT

      const selection = window.getSelection();

      const newSegments = segments.map((segment, segmentIndex) => {
        const content = segment.content;

        if (segmentIndex == savedRange.startContainerId) {
          // TODO - If I add - 1, it works for when the field has been adjusted with arrows
          // If I remove -1 (how it is now), it works for when clicked directly
          // Either way, one is off. I need to detect somehow which one

          const mod = get(savedRange, "type") === "click" ? 0 : -1;

          const newContent =
            content.slice(0, savedRange.startOffset + mod) +
            `{{ ${path.join(".")} }}` +
            content.slice(savedRange.endOffset + mod);

          return newContent;
        }

        return content;
      });

      const newString = newSegments.join("");

      data.onChange(newString);

      selection.removeAllRanges();
    } else {
      // OLD SHIT

      const cursorPosition = inputRef.current.selectionStart;
      const newDsString = `{{ ${path.filter((p) => p).join(".")} }}`;

      let valueToSlice = value || "";

      const newString =
        valueToSlice.slice(0, cursorPosition) +
        newDsString +
        valueToSlice.slice(cursorPosition);
      onChange(newString);
      handleClose();
    }
  };

  const getCrumbs = () => {
    if (selectedDataSource === "staticCell") {
      return [
        {
          text: startCase(selectedDataSource),
          onClick: () => setSelectedDataSource(null),
        },
        {
          text: "Configure",
        },
      ];
    }

    if (selectedDataSource) {
      return [
        {
          text: startCase(selectedDataSource),
          onClick: () => setSelectedDataSource(null),
        },
        {
          text: "Select Field",
        },
      ];
    }

    return [{ text: "Insert Variable" }];
  };

  const crumbs = getCrumbs();

  const segments = parseText(value);

  const finalSegments =
    segments.length > 0 ? segments : [{ type: "text", content: " " }];

  // OLD SHIT
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedDataSource(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Container width={data.width}>
      <Popover
        id={"dynamic-string-variables"}
        open={showPopup}
        anchorEl={inputRef.current}
        onClose={() => {
          setSavedRange(null);
          setAnchorEl(null);
        }}
        disableAutoFocus={true}
        transitionDuration={0}
        disableEnforceFocus={true}
        anchorPosition={{ right: fieldCountChanged ? 10 : 11 }}
        anchorOrigin={{
          horizontal: "right",
        }}
      >
        <MenuContainer>
          <Breadcrumb fontStyle="headingSm" items={crumbs} />
          {selectedDataSource && (
            <DataSourcesContainer>
              {["localState", "params"].includes(selectedDataSource) && (
                <StaticCellConfig
                  onClick={(data) =>
                    addVariable([selectedDataSource, data.field])
                  }
                  fields={[
                    {
                      id: "field",
                      label: "Field",
                      hint: "The name of a custom field you created in your local state",
                    },
                  ]}
                />
              )}

              {selectedDataSource === "step" && (
                <StaticCellConfig
                  onFieldCountChange={() =>
                    setFieldCountChanged(!fieldCountChanged)
                  }
                  onClick={(data) =>
                    addVariable(["step", data.stepIndex, data.field])
                  }
                  fields={[
                    {
                      id: "stepIndex",
                      componentId: "Select",
                      label: "Step",
                      options: previousSteps.map((s, i) => ({
                        label: `Step ${i + 1}`,
                        value: i + 1,
                      })),
                    },
                    {
                      id: "field",
                      componentId: "Input",
                      description:
                        "Enter the field from the previous step response that you want to access.",
                      label: "Field",
                      displayCondition: (d) => {
                        // Right now, hide this field. Next, conditionally show it with a select
                        // dropdown or input only when relevant, with the correct field options
                        return false;

                        const stepIndex = get(d, "stepIndex");
                        const matchingStep = previousSteps.find(
                          (s, si) => si + 1 === stepIndex
                        );

                        const isLogicAddOrRemove =
                          get(matchingStep, "type") === "LOGIC" &&
                          [
                            "removeFromCommaSeparatedString",
                            "addToCommaSeparatedString",
                          ].includes(get(matchingStep, "operation"));

                        const isFormValidation =
                          get(matchingStep, "type") === "FORM_VALIDATION";

                        // hide this field if logic action
                        if (isLogicAddOrRemove || isFormValidation) {
                          return false;
                        }

                        // otherwise, show if stepIndex is selected
                        return get(d, "stepIndex");
                      },
                    },
                  ]}
                />
              )}

              {/* {selectedDataSource === "actionSteps" && (
                <StaticCellConfig
                  onFieldCountChange={() =>
                    setFieldCountChanged(!fieldCountChanged)
                  }
                  onClick={(data) =>
                    addVariable(["actionSteps", data.stepIndex, data.field])
                  }
                  fields={[
                    {
                      id: "stepIndex",
                      componentId: "Select",
                      label: "Step",
                      options: previousSteps.map((s, i) => ({
                        label: `Step ${i + 1}`,
                        value: i + 1,
                      })),
                    },
                    {
                      id: "field",
                      componentId: "Input",
                      description:
                        "Enter the field from the previous step response that you want to access.",
                      label: "Field",
                      displayCondition: (d) => {
                        const stepIndex = get(d, "stepIndex");
                        const matchingStep = previousSteps.find(
                          (s, si) => si + 1 === stepIndex
                        );

                        const isLogicAddOrRemove =
                          get(matchingStep, "type") === "LOGIC" &&
                          [
                            "removeFromCommaSeparatedString",
                            "addToCommaSeparatedString",
                          ].includes(get(matchingStep, "operation"));

                        const isFormValidation =
                          get(matchingStep, "type") === "FORM_VALIDATION";

                        // hide this field if logic action
                        if (isLogicAddOrRemove || isFormValidation) {
                          return false;
                        }

                        // otherwise, show if stepIndex is selected
                        return get(d, "stepIndex");
                      },
                    },
                  ]}
                />
              )} */}

              {selectedDataSource === "block" && (
                <StaticCellConfig
                  onFieldCountChange={() =>
                    setFieldCountChanged(!fieldCountChanged)
                  }
                  onClick={(data) =>
                    addVariable(["block", data.block, data.field])
                  }
                  fields={[
                    {
                      id: "block",
                      componentId: "Select",
                      label: "Block",
                      options: blockDataOptions,
                    },
                    {
                      id: "field",
                      componentId: "Input",
                      description:
                        "Enter a field name from the spreadsheet associated with this block (case sensitive).",
                      label: "Field",
                      displayCondition: (d) => get(d, "block"),
                    },
                  ]}
                />
              )}

              {selectedDataSource === "staticCell" && (
                <StaticCellConfig
                  onFieldCountChange={() =>
                    setFieldCountChanged(!fieldCountChanged)
                  }
                  onClick={(data) =>
                    addVariable(["spreadsheets", data.spreadsheet, data.cell])
                  }
                  fields={[
                    {
                      id: "spreadsheet",
                      componentId: "SpreadsheetSelect",
                      preventExtras: true,
                    },
                    {
                      id: "cell",
                      label: "Google Sheet Cell",
                      placeholder: "C5",
                      hint: "Enter a cell name using Google Sheet notation, like A2 or F21, etc.",
                      displayCondition: (d) => get(d, "spreadsheet"),
                    },
                  ]}
                />
              )}
              <FieldsContainer>
                {dataSourceFields.map((field) => (
                  <Option
                    onKeyDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onClick={() => addVariable([selectedDataSource, field])}
                  >
                    {field}
                  </Option>
                ))}
              </FieldsContainer>
            </DataSourcesContainer>
          )}
          {!selectedDataSource && (
            <DataSourcesContainer
              style={{
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {dataSources.map((dataSource) => (
                <Option
                  className={dataSource.key}
                  onClick={() => setSelectedDataSource(dataSource.key)}
                >
                  {dataSource.name}
                </Option>
              ))}
            </DataSourcesContainer>
          )}
          <Text
            data={{
              text: "Learn more about variables",
              color: colors.primary,
              margin: "15px 0 0 0",
              fontStyle: "headingXs",
              onClick: () =>
                window.open(
                  "https://help.frontly.ai/en/articles/7971422-list-of-frontly-dynamic-variables"
                ),
            }}
          />
        </MenuContainer>
      </Popover>

      {isNew ? (
        // RENDER NEW INPUT
        <SmartString
          data={data}
          inputRef={inputRef}
          value={value}
          setSavedRange={setSavedRange}
          savedRange={savedRange}
          segments={finalSegments}
        />
      ) : (
        // RENDER OLD INPUT
        <InputContainer
          border={data.border}
          width={data.width}
          padding={data.padding}
        >
          <StyledInput
            onChange={(e) => onChange(e.target.value)}
            value={value}
            ref={inputRef}
            disabled={disabled}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <IconContainer>
            <Icon
              data={{
                icon: "FiPlusCircle",
                onClick: handleClick,
                color: colors.grey2,
                size: 18,
                hover: true,
              }}
            />
          </IconContainer>
        </InputContainer>
      )}
    </Container>
  );
};

export default DynamicString;

const StaticCellConfig = ({ fields, onClick, onFieldCountChange }) => {
  const [data, setData] = useState({});

  const displayFields = fields
    .map((f) => ({
      ...f,
      value: get(data, f.id),
    }))
    .filter(
      (f) =>
        !f.displayCondition || (f.displayCondition && f.displayCondition(data))
    );

  const complete = displayFields.filter((f) => !get(data, f.id)).length === 0;

  const [fieldCount, setFieldCount] = useState(displayFields.length);

  const fieldsLength = displayFields.length;

  useEffect(() => {
    if (fieldsLength !== fieldCount && onFieldCountChange) {
      onFieldCountChange(fieldsLength);
      setFieldCount(fieldsLength);
    }
  }, [fieldsLength]);

  return (
    <AdminForm
      onChange={(k, v) => setData({ ...data, [k]: v })}
      sectionPadding={"0px"}
      submit={() => onClick(data)}
      submitText={"Insert"}
      disableSubmit={!complete}
      fields={displayFields}
    />
  );
};

const Container = styled.div`
  position: relative;
  width: ${(p) => p.width || "100%"};
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const DataSourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
`;

const MenuContainer = styled.div`
  width: 300px;
  padding: 15px;
  border-radius: 8px;
`;

const Option = styled.div`
  background: ${colors.primary};
  padding: 3px 6px 3px 6px;
  border-radius: 5px;
  font-size: 12px;
  color: white;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
`;

// OLD SHIT
const InputContainer = styled.div`
  display: flex;
  border: ${(p) => p.border || `1px solid ${colors.pitchBorder}`};
  overflow: hidden;
  border-radius: 8px;
  width: ${(p) => p.width || "100%"};
  align-items: center;
  height: 40px;
`;

const StyledInput = styled.input`
  border: 0px;
  width: 100%;
  padding: ${spacing.s3};
  height: 100%;
  border-radius: 8px;
  &:focus {
    outline: none;
  }
`;

const IconContainer = styled.div`
  padding: 0 5px 0 4px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;

const parseText = (text) => {
  try {
    const variableRegex = /{{\s*[\w\.]+\s*}}/g;
    let parts = [];
    let lastIndex = 0;

    text &&
      text.replace(variableRegex, (match, index) => {
        parts.push({ type: "text", content: text.slice(lastIndex, index) });
        parts.push({ type: "variable", content: match });
        lastIndex = index + match.length;
      });

    if (lastIndex < text.length) {
      parts.push({ type: "text", content: text.slice(lastIndex) });
    }

    const lastPart = parts[parts.length - 1];
    if (lastPart?.type === "variable") {
      parts.push({ type: "text", content: "" });
    }

    return parts;
  } catch (e) {
    // console.log("ERROR");
    return [];
  }
};
