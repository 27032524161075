import {
  Button,
  Card,
  FullScreenLoader,
  Modal,
  Row,
  SearchBar,
  Tabs,
  Text,
} from "app/components";
import { boxShadow, colors } from "app/utils/theme";
import { useLocation, useNavigate } from "react-router-dom";

import InternalAdminExperts from "./InternalAdminExperts";
import { TableComponent } from "app/renderingApp/blocks/Table";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { getUrlParameter } from "app/utils/utils";
import moment from "moment";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const InternalAdmin = () => {
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [activeUserId, setActiveUserId] = useState(null);
  const [search, setSearch] = useState("");
  const [isFetchingLink, setIsFetchingLink] = useState(false);

  const location = useLocation();
  const tab = getUrlParameter("tab", location) || "users";
  const navigate = useNavigate();

  const fetchData = () => {
    let endpoint = `/internal_admin/?email=${search}`;

    setIsFetching(true);
    apiRequest.get(endpoint).then((response) => {
      const d = get(response, "data");
      setIsFetching(false);
      setData(d);
    });
  };

  if (isFetching) {
    return <FullScreenLoader />;
  }

  const users = get(data, "users", []).map((u) => ({
    ...u,
    name: `${u.first_name} ${u.last_name}`,
    signup_date: moment(u.signup_date).format("MMM D, YYYY"),
    onClick: () => setActiveUserId(u.id),
    active_subscription: get(u, "subscriptions", []).length > 0,
  }));

  const activeUser = users.find((u) => u.id === activeUserId);

  const activeOrgUsers = users.filter(
    (u) => u.organization_id === get(activeUser, "organization_id")
  );

  const orgColumns = [
    "name",
    "email",
    "active_subscription",
    "signup_date",
    "id",
  ].map((c) => ({
    key: c,
    columnType: c === "active_subscription" ? "boolean" : "text",
  }));

  const subscriptionColumns = [
    "plan",
    "id",
    "amount",
    "created_date",
    "cancelled_date",
  ].map((c) => ({
    key: c,
    columnType: "text",
  }));

  const syncUser = () => {
    successNotification("User Synced With Active Campaign");
    apiRequest.post("/internal_admin/sync_user/", {
      org_id: get(activeUser, "organization_id"),
    });
  };

  const resetErrorChats = () => {
    successNotification("Reset Chats");
    apiRequest.post("/internal_admin/reset_chats/", {
      org_id: get(activeUser, "organization_id"),
    });
  };

  const removePlan = () => {
    successNotification("Plan Removed");
    apiRequest.post("/internal_admin/remove_plan/", {
      org_id: get(activeUser, "organization_id"),
    });
    setData({
      ...data,
      users: users.map((u) => {
        if (u.id === activeUserId) {
          return {
            ...u,
            subscriptions: [],
          };
        }
        return u;
      }),
    });
  };

  const addPlan = (plan) => {
    successNotification("Plan Created");
    setData({
      ...data,
      users: users.map((u) => {
        if (u.id === activeUserId) {
          return {
            ...u,
            subscriptions: [...get(u, "subscriptions", []), { plan: "custom" }],
          };
        }
        return u;
      }),
    });
    apiRequest.post("/internal_admin/add_plan/", {
      plan,
      org_id: get(activeUser, "organization_id"),
    });
  };

  const getMagicLink = (userId) => {
    setIsFetchingLink(true);
    apiRequest
      .post("/internal_admin/magic_link/", {
        user_id: userId,
      })
      .then((response) => {
        setIsFetchingLink(false);
        const link = get(response, ["data", "link"]);
        const adminLink = `${link}&admin_preview=true`;
        navigator.clipboard.writeText(adminLink);
        successNotification("Login Link copied to clipboard");
      });
  };

  const strippedSearch = search.trim().toLowerCase();

  return (
    <Container>
      {activeUser && (
        <Modal
          minWidth="800px"
          header={{ title: get(activeUser, "name") }}
          hide={() => setActiveUserId(null)}
          background={colors.grey1}
        >
          <Card
            width="100%"
            header={{
              label: "Organization Users",
            }}
            padding="20px"
          >
            <TableComponent
              data={{
                preventDarkMode: true,
                isFetching,
                rows: activeOrgUsers.map((u) => ({
                  ...u,
                  onClick: () => getMagicLink(u.id),
                })),
                columns: [
                  {
                    key: "role",
                    columnType: "text",
                  },
                  {
                    key: "name",
                    columnType: "text",
                  },
                  {
                    key: "email",
                    columnType: "text",
                  },
                ],
              }}
            />
          </Card>
          <Card
            width="100%"
            header={{
              label: "Subscriptions",
            }}
            padding="20px"
            margin="20px 0 0 0"
          >
            {get(activeUser, ["subscriptions", "length"]) === 0 ? (
              <>
                <Text
                  data={{
                    text: "No Active Subscription",
                    margin: "0 0 20px 0",
                    fontStyle: "headingLg",
                  }}
                />

                <Row gap="10px" margin="0 0 20px 0" padding="20px">
                  <Button
                    data={{
                      text: "Get Login Link",
                      onClick: () => getMagicLink(activeUserId),
                      isFetching: isFetchingLink,
                    }}
                  />
                  <Button
                    data={{
                      text: "Reset Error Chats",
                      onClick: resetErrorChats,
                    }}
                  />
                </Row>

                <Text
                  data={{
                    text: "V1 Plans",
                    margin: "0 0 20px 0",
                    fontStyle: "headingLg",
                  }}
                />

                <Row gap="10px" margin="0 0 20px 0" padding="20px">
                  <Button
                    data={{
                      text: "Add AppSumo Freebie Plan",
                      onClick: () => addPlan("appsumo_freebie"),
                    }}
                  />
                  <Button
                    data={{
                      text: "Add $299 Plan",
                      onClick: () => addPlan("299_ltd"),
                    }}
                  />
                </Row>

                <Text
                  data={{
                    text: "AppSumo Tier Plans",
                    margin: "0 0 20px 0",
                    fontStyle: "headingLg",
                  }}
                />

                <Row gap="10px" margin="0 0 20px 0" padding="20px">
                  <Button
                    data={{
                      text: "AppSumo Tier 1",
                      onClick: () => addPlan("frontly_x_appsumo_tier1"),
                    }}
                  />

                  <Button
                    data={{
                      text: "AppSumo Tier 2",
                      onClick: () => addPlan("frontly_x_appsumo_tier2"),
                    }}
                  />

                  <Button
                    data={{
                      text: "AppSumo Tier 3",
                      onClick: () => addPlan("frontly_x_appsumo_tier3"),
                    }}
                  />

                  <Button
                    data={{
                      text: "AppSumo Tier 4",
                      onClick: () => addPlan("frontly_x_appsumo_tier4"),
                    }}
                  />

                  <Button
                    data={{
                      text: "AppSumo Tier 5",
                      onClick: () => addPlan("frontly_x_appsumo_tier5"),
                    }}
                  />
                </Row>
              </>
            ) : (
              <Row gap="10px">
                <Button
                  data={{
                    text: "Get Login Link",
                    onClick: () => getMagicLink(activeUserId),
                    isFetching: isFetchingLink,
                  }}
                />
                <Button
                  data={{
                    text: "Reset Error Chats",
                    onClick: resetErrorChats,
                  }}
                />
                <Button
                  data={{
                    text: "Remove Plan",
                    onClick: removePlan,
                  }}
                />
                <Button
                  data={{
                    text: "Sync User With Active Campaign",
                    onClick: syncUser,
                  }}
                />
              </Row>
            )}
            <TableComponent
              data={{
                preventDarkMode: true,
                isFetching,
                rows: get(activeUser, "subscriptions", []).map((sub) => ({
                  ...sub,
                  onClick: () =>
                    window.open(
                      `https://api.frontly.ai/admin/frontly/subscription/${sub.id}/`
                    ),
                  amount: sub.amount === 0 ? "0.00" : sub.amount,
                  created_date: moment(sub.created_date).format("MMM D, YYYY"),
                  cancelled_date: sub.cancelled_date
                    ? moment(sub.cancelled_date).format("MMM D, YYYY")
                    : "-",
                })),
                columns: subscriptionColumns,
              }}
            />
          </Card>
        </Modal>
      )}

      <Tabs
        data={{
          margin: "0 0 30px 0",
          tabs: [
            {
              label: "Users",
              active: tab === "users",
              onClick: () => navigate("/internal_admin?tab=users"),
            },
            {
              label: "Experts",
              active: tab === "experts",
              onClick: () => navigate("/internal_admin?tab=experts"),
            },
          ],
        }}
      />

      {tab === "experts" && <InternalAdminExperts />}
      {tab === "users" && (
        <Card
          width="860px"
          header={{
            label: "Users",
          }}
          padding="0px"
        >
          <SearchBar
            data={{
              value: search,
              margin: "20px",
              submit: fetchData,
              onChange: (v) => setSearch(v),
            }}
          />
          <TableComponent
            data={{
              preventDarkMode: true,
              noResultsPadding: "0 0 22px 22px",
              isFetching,
              resultsPerPage: 10,
              rows: users.filter((u) => {
                // Match email
                if (u.email.toLowerCase().includes(strippedSearch)) {
                  return true;
                }

                // Match name
                if (u.name.toLowerCase().includes(strippedSearch)) {
                  return true;
                }

                // Match subdomain
                let matchSubdomain = get(u, "subdomains", []).find((s) => {
                  if (s && s.toLowerCase().includes(strippedSearch)) {
                    return true;
                  }
                });

                if (matchSubdomain) {
                  return true;
                }

                return false;
              }),
              columns: orgColumns,
            }}
          />
        </Card>
      )}
    </Container>
  );
};

export default InternalAdmin;

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StatContainer = styled.div`
  padding: 15px;
  background: white;
  box-shadow: ${boxShadow.card};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
