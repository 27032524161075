import {
  Button,
  FormInputWrapper,
  Menu,
  SelectToggle,
  TextArea,
} from "app/components";
import { get, startCase } from "lodash";
import {
  getHighest,
  operatorLabelMap,
  operators,
  safeArray,
} from "app/utils/utils";

import { MultiForm } from "app/adminApp/components";
import mixpanel from "mixpanel-browser";
import { rApp } from "app/utils/recoil";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import useActiveBlockSheet from "app/utils/useActiveBlockSheet";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";
import { useState } from "react";
import { useWebsocketCustom } from "app/useWebsocketCustom";

const getLabel = (filter) => {
  if (filter.key) {
    return startCase(`${filter.key} ${get(filter, "operator", "Equals")}`);
  }

  return "New Hidden Filter";
};

const HiddenFiltersConfig = ({ data }) => {
  const activeBlockSheet = useActiveBlockSheet();

  const activeBlock = useActiveBlock();

  const { setBlock } = useSetPage();

  const activeApp = useRecoilValue(rApp);

  const linkedSpreadsheet = get(data, "linkedSpreadsheet") || activeBlockSheet;

  const schema = safeArray(activeApp, "user_schema").map((f) => f.name);

  const [prompt, setPrompt] = useState("");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isGeneratingFilters, setIsGeneratingFilters] = useState(false);

  const onWebsocketResponse = (responseData) => {
    let newFilters = [...filters];

    get(responseData, "filters", []).forEach((f) => {
      newFilters.push({
        id: getHighest(newFilters, "id") + 1,
        key: f.key,
        operator: f.operator,
        value: f.value,
      });
    });

    successNotification("New Filters Generated");

    setIsGeneratingFilters(false);

    data.onChange(newFilters);

    setPrompt("");

    setMenuAnchor(null);
  };

  const { generate } = useWebsocketCustom(onWebsocketResponse);

  const generateWithAi = (prompt) => {
    setIsGeneratingFilters(true);
    generate({
      action: "hidden_filters",
      prompt,
      columns: get(linkedSpreadsheet, "headers", []).filter((h) => h !== ""),
      user_fields: ["first_name", "last_name", ...schema],
    });

    mixpanel.track(`AI - Generate Hidden Filters`);
  };

  const filters = get(data, "value", []);

  let fields = [
    {
      id: "key",
      label: "Field",
      hint: "Which column to use for matching the row",
      componentId: "SpreadsheetColumnSelect",
      showOnlyRelationFields: data.showOnlyRelationFields,
      linkedSpreadsheet,
    },
    {
      id: "operator",
      label: "Operator",
      componentId: "Select",
      defaultValue: "equals",
      hideEmptyItem: true,
      options: operators.map((o) => ({
        label: get(operatorLabelMap, o),
        value: o,
      })),
    },
    {
      id: "value",
      label: "Value",
      hint: "The value to match with Value 1",
      componentId: "DynamicString",
      displayCondition: (f) =>
        !["is_true", "is_false", "exists", "does_not_exist"].includes(
          get(f, "operator")
        ),
    },
  ].filter(
    (f) => !f.displayCondition || (f.displayCondition && f.displayCondition(f))
  );

  const hiddenFiltersConditionType = get(
    activeBlock,
    "hiddenFiltersConditionType",
    "all"
  );

  return (
    <>
      <Menu
        width={"420px"}
        hide={() => setMenuAnchor(null)}
        label="Generate Hidden Filters With AI"
        description="Describe how you want to limit your user's access to data and let AI generate the filters for you"
        anchorElement={menuAnchor}
        hint="This feature helps you generate filters with AI based on your description. Double-check the generated filters before saving to ensure they are what you're looking for."
        hintLink="https://help.frontly.ai/en/articles/7971258-hidden-filters"
      >
        <TextArea
          data={{
            value: prompt,
            onChange: (v) => setPrompt(v),
          }}
        />
        <Button
          data={{
            text: "Generate",
            icon: "HiSparkles",
            margin: "10px 0 0 0",
            isFetching: isGeneratingFilters,
            onClick: () => {
              generateWithAi(prompt);
            },
          }}
        />
      </Menu>
      <MultiForm
        data={{
          label: "Filters",
          width: "300px",
          orientation: "vertical",
          labelSingular: "Hidden Filter",
          componentId: "MultiForm",
          value: filters,
          fields,
          newObject: {},
          getItemLabel: (f) => getLabel(f),
          onChange: (newFilters) => {
            data.onChange(newFilters);
          },
        }}
      />

      <Button
        data={{
          text: "Generate With AI",
          icon: "HiSparkles",
          type: "basic",
          size: "small",
          margin: "10px 0 0 0",
          onClick: (e) => setMenuAnchor(e.currentTarget),
        }}
      />
      {!data.hideConditionType && (
        <FormInputWrapper
          margin="15px 0 0 0"
          label="Condition Type"
          hint="How you want your filters to be applied, either all true or any true"
        >
          <SelectToggle
            data={{
              onChange: (v) =>
                setBlock({
                  hiddenFiltersConditionType: v,
                }),
              tabs: [
                {
                  label: "All True",
                  value: "all",
                  active: hiddenFiltersConditionType === "all",
                },
                {
                  label: "Any True",
                  value: "any",
                  active: hiddenFiltersConditionType === "any",
                },
              ],
            }}
          />
        </FormInputWrapper>
      )}
    </>
  );
};

export default HiddenFiltersConfig;
