import React, { useEffect, useState } from "react";
import { endsWith, get } from "lodash";

import { colors } from "app/utils/theme";
import { getPixels } from "app/utils/utils";
import styled from "styled-components";

const SmartString = ({ data, inputRef, setSavedRange, segments, value }) => {
  const [segmentId, setSegmentId] = useState(null);
  const [cursorIndex, setCursorIndex] = useState(0);

  // console.log("CURRENT", segmentId, cursorIndex);

  const setCursorAtPosition = (elementId, position) => {
    const segmentId = `.segment-${elementId}`;
    const element = inputRef.current.querySelector(segmentId);

    if (element) {
      const range = document.createRange();
      const selection = window.getSelection();

      // Ensure the position is within the bounds of the element's text content
      position = Math.max(0, Math.min(position, element.textContent.length));

      if (element.firstChild) {
        // Set the start and end points of the range
        range.setStart(element.firstChild, position);
        range.setEnd(element.firstChild, position);

        // Apply the range to the selection
        selection.removeAllRanges();
        selection.addRange(range);
      }
    } else {
      console.log("INVALID ELEMENT", segmentId);
    }
  };

  useEffect(() => {
    // No segments left, just set the cursor at the beginning of the string.
    if (segments.length === 0) {
      data.onChange(" ");
      setCursorAtPosition(0, 0);
      return;
    }

    // If the last edited index is not null, set the cursor at the last edited index.
    if (segmentId !== null) {
      setCursorAtPosition(segmentId, cursorIndex);
    }
  }, [segmentId, cursorIndex]);

  const handleInput = (e) => {
    e.preventDefault();

    const v = get(e, ["nativeEvent", "data"]);

    if (v) {
      insertInput(v);
    }
  };

  const insertInput = (v) => {
    const { startOffset, startContainerId } = getRange();

    setSegmentId(startContainerId);
    setCursorIndex(startOffset);

    const newSegments = segments.map((segment, index) => {
      if (index == startContainerId) {
        // inject the new character into the segment
        const newContent =
          segment.content.slice(0, startOffset - 1) +
          v +
          segment.content.slice(startOffset - 1);
        return newContent;
      }
      return segment.content;
    });
    const newString = newSegments.join("");

    if (newString.length === 2 && endsWith(newString, " ") && v !== " ") {
      data.onChange(v);
    } else {
      data.onChange(newString);
    }
  };

  function handleUserSelection() {
    const selection = window.getSelection();
    if (!selection.rangeCount) return false; // No selection made

    const { startOffset, endOffset, startContainerId, endContainerId } =
      getRange();

    const singleContainer = startContainerId === endContainerId;

    if (singleContainer && startOffset === endOffset) {
      // Clear the current selection
      return false;
    }

    const newSegments = segments.map((segment, segmentIndex) => {
      const content = segment.content;

      if (startContainerId > segmentIndex || endContainerId < segmentIndex) {
        return content;
      } else {
        if (segmentIndex == startContainerId) {
          if (segment.type === "variable") {
            return "";
          }

          if (singleContainer) {
            if (startOffset === endOffset) {
              return (
                content.slice(0, startOffset - 1) + content.slice(endOffset)
              );
            }

            return content.slice(0, startOffset) + content.slice(endOffset);
          }

          return content.substring(0, startOffset);
        } else if (segmentIndex == endContainerId) {
          if (segment.type === "variable") {
            return "";
          }
          return content.substring(endOffset, content.length);
        } else {
          return "";
        }
      }
    });

    const newString = newSegments.join("");

    data.onChange(newString);

    setSegmentId(startContainerId);
    setCursorIndex(startOffset);

    return true;
  }

  const handleArrows = (e) => {
    const { startOffset, startContainerId } = getRange();
    const matchingSegment = segments.find((s, i) => i == startContainerId);

    if (e.code === "ArrowRight") {
      const nextSegmentId = parseInt(startContainerId) + 1;
      const nextSegment = segments.find((s, i) => i == nextSegmentId);

      if (nextSegment && matchingSegment.content.length == startOffset) {
        const variableModifier = nextSegment.type === "variable" ? 1 : 0;
        setSegmentId(nextSegmentId + variableModifier);
        setCursorIndex(0);
        // console.log("RIGHT YES", startContainerId, startOffset);
        e.preventDefault();
      } else {
        // console.log("RIGHT NO", startContainerId, startOffset);
        setSegmentId(startContainerId);
        setCursorIndex(startOffset);
        // e.preventDefault();
      }
    } else if (e.code === "ArrowLeft") {
      const lastSegmentId = parseInt(startContainerId) - 1;
      const lastSegment = segments.find((s, i) => i == lastSegmentId);

      if (lastSegment && startOffset === 0) {
        const variableModifier = lastSegment.type === "variable" ? 1 : 0;

        const newSegmentId = lastSegmentId - variableModifier;
        const newSegment = segments.find((s, i) => i == newSegmentId);
        // console.log("LEFT YES", startContainerId, startOffset);
        setSegmentId(newSegmentId);
        setCursorIndex(newSegment.content.length + 1);
        e.preventDefault();
      } else {
        // console.log("LEFT NO", startContainerId, startOffset);
        setSegmentId(startContainerId);
        setCursorIndex(startOffset);
      }
    }
  };

  const handleKeyDown = (e) => {
    // ENTER KEY
    if (e.key === "Enter") {
      e.preventDefault();
    }

    saveRange("input");

    // ARROW RIGHT / LEFT
    if (["ArrowRight", "ArrowLeft"].includes(e.code)) {
      handleArrows(e);
      return;
    }

    if (["Backspace", "Delete"].includes(e.key)) {
      e.preventDefault();

      const res = handleUserSelection();
      if (res) {
        return;
      }

      handleDelete();
    }
  };

  const handleDelete = () => {
    // Get the saved range from the state.
    const { startOffset, startContainerId } = getRange();

    // Check if the start container ID is valid (not empty and not null).
    const validElementIndex =
      startContainerId !== "" && startContainerId !== null;

    // If delete is pressed at the start of the string, do nothing and exit the function.
    if (startOffset == 0 && startContainerId == 0 && validElementIndex) {
      return null;
    }

    // Create new segments by iterating over existing ones and applying deletion logic.
    const { segments: newSegments, deletedSegments } = getNewSegments();

    // console.log("TEST", {
    //   newSegments,
    //   deletedSegments,
    //   startOffset,
    //   startContainerId,
    //   new: startOffset - 1,
    // });

    // Find the segment just before the current cursor position.
    const previousSegment = segments.find(
      (s, i) => i == deletedSegments[0] - 1
    );

    const prevSegmentLength = get(previousSegment, ["content", "length"]);

    // Join the segments to form the new string.
    const newString = newSegments.join("");

    // Trigger the onChange event with the new string.
    data.onChange(newString);

    if (deletedSegments.length > 0) {
      // HANDLE DELETING A SEGMENT
      setSegmentId(deletedSegments[0] - 1);
      setCursorIndex(prevSegmentLength);
    } else {
      // HANDLE DELETING A CHARACTER
      // setSegmentId(startContainerId);
      setCursorIndex(startOffset - 1);
    }
  };

  // Create new segments by iterating over existing ones and applying deletion logic.
  const getNewSegments = () => {
    const { startOffset, startContainerId } = getRange();

    // Find the segment just before the current cursor position.
    const previousSegment = segments.find((s, i) => i == startContainerId - 1);

    // Check if the previous segment is a variable.
    const previousSegmentIsVariable =
      get(previousSegment, "type") === "variable";

    const newSegments = segments.map((segment, index) => {
      if (index == startContainerId) {
        // Determine if the action is deleting a variable.
        const isDeletingVariable =
          previousSegmentIsVariable && startOffset === 0;

        // Handle non-variable deletion or mid-segment deletion.
        if (!isDeletingVariable && startContainerId) {
          // Remove the character at the cursor position from the segment.
          const newContent =
            segment.content.slice(0, startOffset - 1) +
            segment.content.slice(startOffset);

          return newContent;
        }

        // Return the unmodified segment content if not deleting a variable.
        return segment.content;
      }

      // Return the unmodified content for segments not at the cursor position.
      return segment.content;
    });

    let finalSegments = [];

    let deletedSegments = [];

    newSegments.forEach((segment, index) => {
      // Filter out the segment if it's a variable that's being deleted.
      if (
        index == startContainerId - 1 &&
        previousSegmentIsVariable &&
        startOffset === 0
      ) {
        deletedSegments.push(index);
        return false;
        // DO NOTHING
      }

      // Handle edge case for deletion at the start of the segments.
      if (
        (!startContainerId || startContainerId === "") &&
        get(segments, "length") - 2 === index
      ) {
        deletedSegments.push(index);
        return false;
        // DO NOTHING
      }

      // Keep the segment if none of the above conditions are met.
      finalSegments.push(segment);
    });

    return { segments: finalSegments, deletedSegments };
  };

  const content = (
    <div>
      {segments.map((segment, index) => {
        const segmentClass =
          segment.type === "variable"
            ? `variable variable-${index} segment-${index}`
            : `text-segment text-segment-${index} segment-${index}`;

        if (segment.type === "variable") {
          return (
            <Variable
              key={index}
              className={segmentClass}
              id={index}
              onMouseDown={(e) => e.preventDefault()}
              contentEditable="false"
            >
              {segment.content.replace("{{", "").replace("}}", "").trim()}
            </Variable>
          );
        } else {
          return (
            <span
              style={{ minWidth: "2px", marginBottom: "5px" }}
              key={index}
              className={segmentClass}
              id={index}
            >
              {convertToNonBreakingSpaces(segment.content)}
            </span>
          );
        }
      })}
    </div>
  );

  const getRange = () => {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return true;
    const range = selection.getRangeAt(0);
    const startContainerId =
      get(range, ["startContainer", "id"]) ||
      get(range, ["startContainer", "parentNode", "id"]);
    const endContainerId =
      get(range, ["endContainer", "id"]) ||
      get(range, ["endContainer", "parentNode", "id"]);
    return {
      endOffset: range.endOffset,
      startOffset: range.startOffset,
      endContainerId: endContainerId,
      startContainerId: startContainerId,
    };
  };

  const saveRange = (tag) => {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return true;
    const range = selection.getRangeAt(0);
    const startContainerId =
      get(range, ["startContainer", "id"]) ||
      get(range, ["startContainer", "parentNode", "id"]);
    const endContainerId =
      get(range, ["endContainer", "id"]) ||
      get(range, ["endContainer", "parentNode", "id"]);

    setCursorIndex(range.startOffset);
    setSegmentId(startContainerId);
    // if (tag === "click") {
    // }

    setSavedRange({
      endOffset: range.endOffset,
      startOffset: range.startOffset,
      endContainerId: endContainerId,
      startContainerId: startContainerId,
      type: tag,
    });
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("Text");
    insertInput(pastedData);
  };

  return (
    <StyledInput
      onClick={() => saveRange("click")}
      contentEditable
      {...data}
      onInput={handleInput}
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      ref={inputRef}
      suppressContentEditableWarning={true}
    >
      {content}
    </StyledInput>
  );
};

function convertToNonBreakingSpaces(str) {
  return str.replace(/ /g, "\u00A0");
}

export default SmartString;

const StyledInput = styled.div`
  padding: 10px 10px 10px 10px;
  border: ${(p) => p.border || `1px solid ${colors.pitchBorder}`};
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  font-size: ${(p) => getPixels(p.fontSize || 14)};
  font-weight: ${(p) => p.fontWeight};
  background: ${(p) => p.background || "white"};
  color: ${(p) => p.color};
  width: ${(p) => p.width};
  text-align: left;
  min-width: 50px;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const Variable = styled.div`
  background: ${colors.primary};
  padding: 2px 3px 3px 3px;
  border-radius: 5px;
  font-size: 12px;
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
  display: inline-block;
  &:hover {
    filter: brightness(90%);
  }
`;
