import { colors } from "app/utils/theme";
import styled from "styled-components";

const CoolContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  background: white;
`;

const ColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.primary};
  flex: 2;
  padding: 20px;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: flex-start;
`;

const Container = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 4;
  @media screen and (max-width: 1200px) {
    padding: 20px;
  }
`;

const HeroText = styled.div`
  font-size: 35px;
  font-weight: 600;
  color: white;
  width: 350px;
  @media screen and (max-width: 1150px) {
    font-size: 35px;
    width: 100%;
  }
  @media (max-width: 800px) {
    font-size: 30px;
    width: 100%;
  }
`;

const Graphic = styled.img`
  height: 260px;
  width: 350px;
  object-fit: contain;
  @media screen and (max-width: 1200px) {
    height: 230px;
  }
  @media screen and (max-width: 980px) {
    height: 200px;
  }
`;

const HeroLogo = styled.img`
  height: 50px;
`;

export const SideContainer = ({ children }) => {
  return (
    <CoolContainer>
      <Container>{children}</Container>
      <ColorContainer>
        <ContentContainer>
          <HeroLogo src="https://res.cloudinary.com/frontly/image/upload/v1687922385/frontly_white_xmcwyt.svg" />
          <HeroText>Your no-code command center</HeroText>
          <Graphic src="https://res.cloudinary.com/frontly/image/upload/v1673419641/frontly-shot-new_wid2t7.png" />
        </ContentContainer>
      </ColorContainer>
    </CoolContainer>
  );
};
