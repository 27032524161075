import { EditOption, Main, NewOptionButton } from "../components";
import { rCurrentContext, rOptionTab } from "../state";

import { EditOptions } from "../styles";
import { Tabs } from "app/components";
import { get } from "lodash";
import { useRecoilState } from "recoil";

// Sheet Columns
const SheetColumns = () => {
  const [currentContext, setCurrentContext] = useRecoilState(rCurrentContext);
  const [optionTab, setOptionTab] = useRecoilState(rOptionTab);

  const sheets = get(currentContext, "sheet_columns", {});

  const sheetNames = Object.keys(sheets);

  const activeTab = optionTab || get(sheetNames, 0);

  const columns = get(sheets, activeTab, []);

  return (
    <Main
      label="Sheet Columns"
      description="We've generated some column headers for each spreadsheet. Feel free to make modifications, then submit to continue."
    >
      <Tabs
        data={{
          margin: "0 0 20px 0",
          fontStyle: "headingLg",
          color: "white",
          tabs: sheetNames.map((s, i) => ({
            label: s,
            active: optionTab === s || (!optionTab && i === 0),
            onClick: () => setOptionTab(s),
          })),
        }}
      />

      <EditOptions>
        {columns.map((s, i) => (
          <EditOption
            key={i}
            value={s}
            disabled={s === "frontly_id"}
            placeholder={"Column Name"}
            onChange={(v) => {
              setCurrentContext((prev) => {
                const newColumns = [...columns];
                newColumns[i] = v;
                let newSheetColumns = { ...sheets, [activeTab]: newColumns };
                return { ...prev, sheet_columns: newSheetColumns };
              });
            }}
            onDelete={() => {
              setCurrentContext((prev) => {
                const newColumns = [...columns];
                newColumns.splice(i, 1);
                let newSheetColumns = { ...sheets, [activeTab]: newColumns };
                return { ...prev, sheet_columns: newSheetColumns };
              });
            }}
          />
        ))}
        <NewOptionButton
          onClick={() =>
            setCurrentContext((prev) => {
              const newColumns = [...columns, "New Column"];
              let newSheetColumns = { ...sheets, [activeTab]: newColumns };
              return { ...prev, sheet_columns: newSheetColumns };
            })
          }
        />
      </EditOptions>
    </Main>
  );
};

export default SheetColumns;
