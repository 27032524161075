import { get, startCase } from "lodash";
import { rReports, rSavedSpreadsheets } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { AdminForm } from "../components";
import { defaultTrue } from "app/utils/utils";

const ReportSettings = ({ reportId }) => {
  const [reports, setReports] = useRecoilState(rReports);

  const report = reports.find((r) => r.id === reportId);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const sheetHeaders = get(
    savedSpreadsheets.find((s) => s.id === report.spreadsheet),
    "headers",
    []
  );

  const update = (field, value) => {
    let otherUpdates = {};

    if (field === "type") {
      if (value === "Pie") {
        otherUpdates["variant"] = "Filled";
      }

      if (value === "Bar") {
        otherUpdates["variant"] = "Horizontal";
      }
    }

    setReports(
      reports.map((r) => {
        if (r.id === reportId) {
          return { ...r, [field]: value, ...otherUpdates };
        }
        return r;
      })
    );
  };

  const type = get(report, "component");

  if (type === "Stat") {
    return (
      <AdminForm
        sectionPadding={"0px"}
        onChange={(k, v) => update(k, v)}
        fields={[
          {
            label: "Format",
            id: "format",
            componentId: "Select",
            value: get(report, "format"),
            options: [
              {
                label: "None",
                value: null,
              },
              {
                label: "Integer",
                value: "integer",
              },
              {
                label: "Decimal",
                value: "decimal",
              },
            ],
          },
          {
            label: "Is Price",
            id: "isPrice",
            componentId: "Switch",
            value: get(report, "isPrice"),
          },
          {
            label: "Decimal Points",
            id: "decimalPoints",
            componentId: "Input",
            value: get(report, "decimalPoints", 1),
            displayCondition: () => get(report, "format") === "decimal",
          },
          {
            label: "Color",
            id: "color",
            componentId: "ColorPicker",
            value: get(report, "color"),
          },
        ].filter(
          (f) =>
            !f.displayCondition || (f.displayCondition && f.displayCondition(f))
        )}
      />
    );
  }

  if (type === "Chart") {
    const chartType = get(report, "type", "Bar");
    return (
      <AdminForm
        sectionPadding={"0px"}
        onChange={(k, v) => update(k, v)}
        fields={[
          {
            label: "Type",
            id: "type",
            componentId: "Select",
            value: chartType,
            options: [
              {
                label: "Bar",
                value: "Bar",
              },
              {
                label: "Pie",
                value: "Pie",
              },
            ],
          },
          {
            label: "Variant",
            id: "variant",
            componentId: "Select",
            value: get(report, "variant", "Horizontal"),
            displayCondition: () => chartType === "Bar",
            options: [
              {
                label: "Horizontal",
                value: "Horizontal",
              },
              {
                label: "Vertical",
                value: "Vertical",
              },
            ],
          },
          {
            label: "Variant",
            id: "variant",
            componentId: "Select",
            value: get(report, "variant", "Filled"),
            displayCondition: () => chartType === "Pie",
            options: [
              {
                label: "Filled",
                value: "Filled",
              },
              {
                label: "Doughnut",
                value: "Doughnut",
              },
            ],
          },
          {
            label: "Value Field",
            id: "field",
            componentId: "Select",
            value: get(report, "field"),
            options: sheetHeaders.map((h) => ({
              label: startCase(h),
              value: h,
            })),
          },
          {
            label: "Group By",
            id: "group_by",
            componentId: "Select",
            value: get(report, "group_by"),
            options: sheetHeaders.map((h) => ({
              label: startCase(h),
              value: h,
            })),
          },
          {
            label: "Grouping Metric",
            id: "calculation_metric",
            componentId: "Select",
            value: get(report, "calculation_metric"),
            options: [
              { label: "Sum", value: "sum" },
              { label: "Average", value: "average" },
              { label: "Count", value: "count" },
              { label: "Percent", value: "percent" },
            ],
            defaultValue: "count",
            displayCondition: () => get(report, "group_by"),
          },
          {
            label: "Show Legend",
            id: "showLegend",
            componentId: "Switch",
            value: defaultTrue(get(report, "showLegend")),
            displayCondition: () => chartType === "Pie",
          },
          {
            label: "Show Labels",
            id: "showLabels",
            componentId: "Switch",
            value: defaultTrue(get(report, "showLabels")),
            displayCondition: () => chartType === "Bar",
          },
          {
            label: "Show Values",
            id: "showValues",
            componentId: "Switch",
            value: defaultTrue(get(report, "showValues")),
            displayCondition: () => chartType === "Bar",
          },
        ].filter(
          (f) =>
            !f.displayCondition || (f.displayCondition && f.displayCondition(f))
        )}
      />
    );
  }
};

export default ReportSettings;
