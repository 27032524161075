import { Button, Column, Modal } from "app/components";
import { fixBlocks, getHighest, safeArray } from "app/utils/utils";

import { AdminForm } from ".";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { getBlockBundle } from "./DuplicateBlock";
import { rCustomBlocks } from "app/utils/recoil";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import usePage from "app/utils/usePage";
import { useRecoilState } from "recoil";
import { useState } from "react";

const CustomBlock = ({ isNew }) => {
  const activeBlock = useActiveBlock();

  const page = usePage();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});

  const [customBlocks, setCustomBlocks] = useRecoilState(rCustomBlocks);

  const saveAsCustomBlock = () => {
    const actions = [...get(page, "actions", [])];
    const blocks = fixBlocks(get(page, "blocks", []));
    const bundle = getBlockBundle({
      block: activeBlock,
      actions,
      blocks,
      blocksToDuplicate: blocks,
    });

    let newData = isNew
      ? {
          blocks: [
            {
              id: 1,
              componentId: "Column",
            },
          ],
        }
      : {
          ...bundle,
        };

    const newBlock = {
      id: getHighest(safeArray(customBlocks), "id") + 1,
      ...newData,
      ...formData,
    };

    const newBlocks = [...safeArray(customBlocks), newBlock];

    setCustomBlocks(newBlocks);

    setFormData({});

    successNotification("Saved as custom block");

    setShowModal(false);

    apiRequest.post("/app_settings/", {
      custom_blocks: newBlocks,
    });
  };

  let fields = [
    {
      id: "name",
      label: "Name",
      description: "Give your custom block a memorable name",
      value: get(formData, "name", ""),
    },
  ];

  if (!isNew) {
    fields.push({
      id: "description",
      label: "Description",
      description: "Describe what this custom block does",
      value: get(formData, "description", ""),
    });
  }

  return (
    <>
      {showModal && (
        <Modal
          minWidth="400px"
          hide={() => setShowModal(false)}
          header={{
            title: isNew ? "New Custom Block" : "Save Custom Block",
          }}
        >
          <Column gap="15px">
            <AdminForm
              sectionPadding={"0px"}
              onChange={(k, v) =>
                setFormData({
                  ...formData,
                  [k]: v,
                })
              }
              fields={fields}
            />
            <Button
              data={{
                text: isNew ? "Create Custom Block" : "Save Custom Block",
                onClick: saveAsCustomBlock,
              }}
            />
          </Column>
        </Modal>
      )}
      <Button
        data={{
          text: isNew ? "New Custom Block" : "Save As Custom Block",
          onClick: () => setShowModal(true),
          icon: isNew ? "FiPlus" : "FiCopy",
          type: "basic",
          size: "small",
        }}
      />
    </>
  );
};

export default CustomBlock;
