import React from 'react';
import * as RadixSlider from '@radix-ui/react-slider';
import './styles.css';

const Slider = () => (
  <form>
    <RadixSlider.Root className="SliderRoot" defaultValue={[50]} max={100} step={1}>
      <RadixSlider.Track className="SliderTrack">
        <RadixSlider.Range className="SliderRange" />
      </RadixSlider.Track>
      <RadixSlider.Thumb className="SliderThumb" aria-label="Volume" />
    </RadixSlider.Root>
  </form>
);

export default Slider;