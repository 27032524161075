import { get, startCase } from "lodash";
import { rCurrentContext, rOptionTab } from "../state";

import EditorGrid from "app/components/EditorGrid";
import { Main } from "../components";
import { Tabs } from "app/components";
import { getHighest } from "app/utils/utils";
import { useRecoilState } from "recoil";

// Sample Records
const SampleRecords = () => {
  const [currentContext, setCurrentContext] = useRecoilState(rCurrentContext);
  const [optionTab, setOptionTab] = useRecoilState(rOptionTab);

  const sheets = get(currentContext, "sample_records", {});

  const sheetNames = Object.keys(sheets);

  const activeTab = optionTab || get(sheetNames, 0);

  const sampleRecords = get(sheets, activeTab, []);

  const sampleHeaders = Object.keys(get(sampleRecords, 0, {}));

  const columns = sampleHeaders.map((h) => ({
    key: h,
    label: startCase(h),
    width: "100%",
    componentId: "Input",
    disabled: h === "frontly_id",
  }));

  return (
    <Main
      label="Sample Records"
      description="We've generated some sample spreadsheet data. Feel free to make modifications, then submit to continue. You can edit this later at any time."
    >
      <Tabs
        data={{
          margin: "0 0 20px 0",
          fontStyle: "headingLg",
          color: "white",
          tabs: sheetNames.map((s, i) => ({
            label: s,
            active: optionTab === s || (!optionTab && i === 0),
            onClick: () => setOptionTab(s),
          })),
        }}
      />

      <div
        style={{
          width: `${sampleHeaders.length * 150}px`,
          marginBottom: "30px",
        }}
      >
        <EditorGrid
          data={{
            newObject: {
              frontly_id: getHighest(sampleRecords, "frontly_id") + 1,
            },
            onChange: (newRecords) => {
              let newSampleData = { ...sheets, [activeTab]: newRecords };
              setCurrentContext((prev) => ({
                ...prev,
                sample_records: newSampleData,
              }));
            },
            value: sampleRecords,
            columns,
          }}
        />
      </div>
    </Main>
  );
};

export default SampleRecords;
