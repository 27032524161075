import "./styles.css";

import * as RadixToast from "@radix-ui/react-toast";

import React from "react";
import { rToast } from "app/utils/recoil";
import { useRecoilState } from "recoil";

const Toast = ({ children }) => {
  const [radixToast, setRadixToast] = useRecoilState(rToast);

  const timerRef = React.useRef(0);

  React.useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <RadixToast.Provider duration={1000} swipeDirection="right">
      {children}
      <RadixToast.Root
        className="ToastRoot"
        open={radixToast.show}
        onOpenChange={(open) => setRadixToast({ ...radixToast, show: open })}
      >
        <RadixToast.Title className="ToastTitle">Message</RadixToast.Title>
        <RadixToast.Description asChild>
          {radixToast.message}
        </RadixToast.Description>
        <RadixToast.Action
          className="ToastAction"
          asChild
          altText="Goto schedule to undo"
        >
          <button className="Button small green">Undo</button>
        </RadixToast.Action>
      </RadixToast.Root>
      <RadixToast.Viewport className="ToastViewport" />
    </RadixToast.Provider>
  );
};

export default Toast;
