// This renders the Grid, Calendar, and Kanban card text content

import { SkeletonLoader, Text } from "app/components";
import { isFrontlyAdmin, safeArray, truncateText } from "app/utils/utils";
import { rActiveEditField, rDarkMode } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Badge from "./Badge";
import { colors } from "app/utils/theme";
import { get, isEmpty } from "lodash";
import styled from "styled-components";
import useUtils from "app/renderingApp/useUtils";

const CardContent = ({ fields, item, blockId, skeleton, customBadgeColors }) => {
  const badges = safeArray(get(item, "badges", []));
  const badgeColors = get(item, "badgeColors", {});

  const setActiveEditField = useSetRecoilState(rActiveEditField);
  const { passesDisplayConditions } = useUtils();

  const darkMode = useRecoilValue(rDarkMode);

  const badgeColorMap = get(item, "badgeColorMap", {});

  let combinedBadgeColorMap = { ...badgeColors, ...badgeColorMap };

  const handleCustomBadgeColor = (v) => {
    const colorMap = customBadgeColors?.find((item) => {
      const conditions = item?.displayConditions?.map((d) => ({ ...d, value1: v }));
      const isPass = passesDisplayConditions({ conditions });
      return isPass;
    })
    if (colorMap) return colorMap?.color;
    return get(badgeColorMap, "grey");
  }

  return (
    <CardContainer>
      {fields.map((field, i) => {
        let value = truncateText(get(item, field.key), field.truncate || 35);

        if (field.valuePrefix) {
          value = `${field.valuePrefix}${value}`;
        }

        if (field.valueSuffix) {
          value = `${value}${field.valueSuffix}`;
        }

        let fieldTextColor = darkMode ? "white" : null;
        if (field.fontColor) {
          fieldTextColor = field.fontColor;
        }

        return (
          <>
            {skeleton ? (
              <SkeletonLoader widthRange={[80, 160]} />
            ) : (
              <TextWrapper
                onClick={(e) => {
                  if (isFrontlyAdmin) {
                    setActiveEditField({
                      id: field.id,
                      target: e.currentTarget,
                      blockId,
                    });
                  }
                }}
                handleFieldClick={isFrontlyAdmin}
              >
                {/* {field.label && (
                  <Text
                    key={i}
                    data={{
                      ...field,
                      overflowWrap: "anywhere",
                      text: field.label,
                      cursor: "pointer",
                      fontStyle: "headingMd",
                      margin: "5px 0 0 0",
                      color:
                        field.color || darkMode
                          ? "rgba(255,255,255,0.7)"
                          : colors.grey3,
                    }}
                  />
                )} */}
                <Text
                  key={i}
                  data={{
                    ...field,
                    overflowWrap: "anywhere",
                    text: value,
                    cursor: "pointer",
                    color: fieldTextColor,
                  }}
                />
              </TextWrapper>
            )}
          </>
        );
      })}
      {badges.length > 0 && (
        <BadgesContainer>
          {badges.map((v) => {
            const color = !isEmpty(customBadgeColors) && customBadgeColors
              ? handleCustomBadgeColor(v)
              : get(combinedBadgeColorMap, v, "grey");
            return <Badge value={v || "No Value"} color={color} />;
          })}
        </BadgesContainer>
      )}
    </CardContainer>
  );
};

export default CardContent;

const TextWrapper = styled.div`
  ${(p) =>
    p.handleFieldClick &&
    `
    &:hover{
      outline: 2px solid ${colors.primary};
      cursor: pointer;
      border-radius: 4px;
    }
  `};
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
`;
// TODO - OVERFLOW SCROLLING??

const BadgesContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 5px;
  flex-wrap: wrap;
`;
