import { badgeColors, listBlockResources, resultsPerPage } from "./shared";

import { get } from "lodash";

const columnKeys = [
  {
    id: "columnType",
    label: "Column Type",
    hint: "Define how the column should be displayed visually.",
    componentId: "Select",
    required: true,
    defaultValue: "text",
    hideEmptyItem: true,
    options: [
      { label: "Text", value: "text" },
      { label: "Badge", value: "badge" },
      { label: "Link", value: "link" },
      { label: "Date", value: "date" },
      { label: "Number", value: "number" },
      { label: "Boolean", value: "boolean" },
      { label: "Image", value: "image" },
      { label: "Alphanumeric", value: "alphanumeric" },
      { label: "Euro Decimal", value: "euroDecimal" },
      // { label: "File Download", value: "fileDownload" },
    ],
  },
  {
    id: "label",
    label: "Label",
    hint: "Override the default label",
    componentId: "Input",
    required: true,
    defaultValue: "",
  },
  {
    displayCondition: (f) => get(f, "columnType") === "date",
    id: "dateFormat",
    label: "Date Format",
    componentId: "DateFormatConfig",
    required: true,
    enabledFields: ["outputDate", "outputTime", "showAmPm"],
  },
  {
    displayCondition: (f) => get(f, "columnType") === "badge",
    ...badgeColors,
  },
  {
    id: "customColors",
    label: "Custom Colors",
    componentId: "MultiForm",
    labelSingular: "Custom Color",
    passIdToChild: true,
    width: "300px",
    displayCondition: (f) => get(f, "columnType") === "badge",
    fields: [
      {
        key: "label",
        label: "Label",
        placeholder: "Label",
        componentId: "Input",
        width: "100%",
      },
      {
        key: "color",
        label: "Color",
        componentId: "ColorPicker",
        width: "100%",
      },
      {
        id: "displayConditions",
        label: "Display Conditions",
        componentId: "DisplayConditions",
        orientation: "vertical",
      },
    ],
  },
  {
    displayCondition: (f) => get(f, "columnType") === "badge",
    id: "splitBadge",
    label: "Split Badges",
    componentId: "Switch",
    orientation: "horizontal",
    defaultValue: false,
    hint: "Should split the badges by `,`?",
  },
  {
    id: "displayConditions",
    label: "Display Conditions",
    componentId: "DisplayConditions",
    orientation: "vertical",
  },
  // {
  //   displayCondition: (f) => f.columnType === "badge",
  //   key: "colorBadgeValue",
  //   label: "Color Badge",
  //   componentId: ComponentId.MULTI_FORM_EDITOR,
  //   required: true,
  //   defaultValue: [],
  //   columns: [
  //     {
  //       key: "color",
  //       label: "Color",
  //       width: "150px",
  //       componentId: "Select",
  //       options: [
  //         { label: "Grey", value: "grey" },
  //         { label: "Green", value: "green" },
  //         { label: "Blue", value: "blue" },
  //         { label: "Red", value: "red" },
  //         { label: "Purple", value: "purple" },
  //         { label: "Yellow", value: "yellow" },
  //       ],
  //     },
  //     {
  //       key: "value",
  //       label: "Value",
  //       width: "150px",
  //       componentId: "Input",
  //     },
  //   ],
  // },
  // {
  //   displayCondition: (f) => ["text", "price"].includes(f.columnType),
  //   key: "fontColor",
  //   label: "Font Color",
  //   componentId: ComponentId.COLOR_PICKER,
  //   required: false,
  //   defaultValue: "",
  // },
  // {
  //   displayCondition: (f) => f.columnType === "number",
  //   key: "decimalPlaces",
  //   label: "Decimal Places",
  //   componentId: ComponentId.TEXT_INPUT,
  //   type: "number",
  //   required: false,
  //   defaultValue: null,
  // },
  {
    id: "bold",
    key: "bold",
    label: "Bold",
    componentId: "Switch",
    defaultValue: false,
    displayCondition: (f) =>
      ["text", "link"].includes(get(f, "columnType", "text")),
  },
  {
    id: "fontColor",
    key: "fontColor",
    label: "Font Color",
    componentId: "ColorPicker",
    displayCondition: (f) =>
      ["text", "link"].includes(get(f, "columnType", "text")),
  },
  {
    id: "truncate",
    displayCondition: (f) =>
      ["text", "link"].includes(get(f, "columnType", "text")),
    key: "truncate",
    label: "Character Limit",
    componentId: "Input",
    required: false,
    type: "number",
    defaultValue: 25,
  },
  {
    id: "linkText",
    displayCondition: (f) => get(f, "columnType", "text") === "link",
    key: "linkText",
    label: "Link Text",
    hint: "Override the default link text",
    componentId: "Input",
    required: false,
  },
  // {
  //   displayCondition: (f) => ["text", "price"].includes(f.columnType),
  //   id: "fieldPrefix",
  //   key: "fieldPrefix",
  //   label: "Field Prefix",
  //   componentId: ComponentId.STRING_BUILDER,
  //   hint: "Add some value to the beginning of your data",
  // },
  // {
  //   displayCondition: (f) => ["text", "price"].includes(f.columnType),
  //   id: "fieldSuffix",
  //   key: "fieldSuffix",
  //   label: "Field Suffix",
  //   componentId: ComponentId.STRING_BUILDER,
  //   hint: "Add some value to the end of your data",
  // },
];

export const Table = {
  componentId: "Table",
  resources: [
    ...listBlockResources,
    {
      id: "columnData",
      componentId: "StaticFields",
      section: "tableColumns",
      keys: columnKeys,
      requiresSheet: true,
      showRelationFields: true,
    },
    {
      id: "defaultSortColumn",
      label: "Column",
      componentId: "SpreadsheetColumnSelect",
      section: "defaultSorting",
      requiresSheet: true,
      orientation: "horizontal",
      width: "150px",
      advanced: true,
    },
    {
      id: "defaultSortDirection",
      label: "Sort Direction",
      section: "defaultSorting",
      requiresSheet: true,
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "asc",
      options: [
        { label: "Ascending", value: "asc" },
        { label: "Descending", value: "desc" },
      ],
      advanced: true,
    },
    {
      id: "rowHeight",
      label: "Row Height",
      section: "tableRows",
      isStyle: true,
      requiresSheet: true,
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "default",
      advanced: true,
      options: [
        { label: "Default", value: "default" },
        { label: "Condensed", value: "condensed" },
      ],
    },
    {
      id: "borderStyle",
      label: "Border Style",
      section: "tableRows",
      isStyle: true,
      requiresSheet: true,
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "solid",
      advanced: true,
      options: [
        { label: "Solid", value: "solid" },
        { label: "Dashed", value: "dashed" },
      ],
    },
    {
      id: "showAlternatingColors",
      label: "Show Alternating Row Colors",
      componentId: "Switch",
      section: "tableRows",
      isStyle: true,
      orientation: "horizontal",
      width: "150px",
      requiresSheet: true,
      defaultValue: false,
      advanced: true,
    },
    {
      id: "rowActions",
      label: "Row Actions",
      componentId: "MultiActions",
      section: "actions",
      isAction: true,
      isMultiAction: true, // This tells the system that it's an array of actions, not a single action ID. The value is actually the action configuration object instead of the ID reference.
      requiresSheet: true,
      dynamicSources: ["record"],
    },
    resultsPerPage,
  ],
};
