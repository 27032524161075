import { Icon, SelectToggle, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, startCase } from "lodash";
import {
  rConfirmationModalData,
  rPageConfigTab,
  rPages,
} from "app/utils/recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

import AdminForm from "app/adminApp/components/AdminForm";
import UserGroupPermissions from "app/adminApp/components/UserGroupPermissions";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import usePage from "app/utils/usePage";
import useSetPage from "app/utils/useSetPage";
import { useState } from "react";

const PageConfig = () => {
  const { setPage } = useSetPage();

  const [pages, setPages] = useRecoilState(rPages);

  // const page = useRecoilValue(rPage);
  const page = usePage();

  const [isDeleting, setIsDeleting] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  const pageUserGroups = get(page, "userGroups");

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const [tab, setTab] = useRecoilState(rPageConfigTab);

  const pageLoadAction = get(page, "pageLoadAction");

  const pageFields = [
    {
      id: "name",
      label: "Name",
      componentId: "Input",
      value: get(page, "name"),
      section: "basics",
      orientation: "vertical",
      tab: "basics",
      hint: "The name of this page (for internal use only)",
    },
    {
      id: "route",
      label: "URL",
      componentId: "Input",
      value: get(page, "route"),
      section: "basics",
      orientation: "vertical",
      hint: "The URL path for this page, like 'about' or 'products'",
      tab: "basics",
    },
    {
      id: "blockHierarchy",
      componentId: "BlockHierarchy",
      sectionHint: "Configure the blocks on this page",
      section: "blocks",
      orientation: "vertical",
      tab: "basics",
    },
    {
      id: "requireLogin",
      label: "Require Login",
      componentId: "Switch",
      value: get(page, "requireLogin"),
      orientation: "horizontal",
      section: "access",
      tab: "more",
    },
    {
      id: "published",
      label: "Published",
      componentId: "Switch",
      value: get(page, "published"),
      orientation: "horizontal",
      section: "access",
      tab: "more",
    },
    {
      id: "displayConditions",
      label: "Display Conditions",
      componentId: "DisplayConditions",
      value: get(page, "displayConditions"),
      orientation: "vertical",
      section: "access",
      tab: "more",
    },
    {
      id: "filters",
      componentId: "PageFiltersConfig",
      sectionHint:
        "Add page-level filters that apply to one or more blocks on the page",
      value: get(page, "filters", []),
      orientation: "vertical",
      section: "pageFilters",
      tab: "more",
    },
    {
      id: "userGroupPermissions",
      label: "User Groups",
      componentId: "FormButton",
      width: "36px",
      hint: "Limit Block access to certain users",
      link: "https://help.frontly.ai/en/articles/7974437-user-groups",
      icon: "FiKey",
      orientation: "horizontal",
      section: "access",
      onClick: (e) => setAnchorElement(e.currentTarget),
      tab: "more",
    },
    {
      id: "deletePage",
      componentId: "FormButton",
      icon: "FiTrash",
      text: "Delete Page",
      width: "fit-content",
      orientation: "horizontal",
      onClick: () =>
        setConfirmationModalData({
          title: "Delete Page",
          text: "Are you sure you want to delete this page? This is irreversible.",
          confirm: deletePage,
        }),
      isFetching: isDeleting,
      section: "deletePage",
      tab: "more",
    },
    {
      id: "pageLoadAction",
      section: "pageLoadAction",
      sectionHint: "This action runs when this page is loaded.",
      isAction: true,
      componentId: "Action",
      tab: "more",
      orientation: "vertical",
      customAction: pageLoadAction,
      customOnChange: (newAction) => {
        setPage({
          pageLoadAction: {
            ...pageLoadAction,
            steps: newAction,
          },
        });
      },
    },
    {
      id: "newLayoutSystem",
      label: "New Layout System",
      componentId: "Switch",
      value: get(page, "newLayoutSystem"),
      orientation: "horizontal",
      section: "access",
      tab: "more",
    },
    {
      id: "pageHistory",
      section: "pageHistory",
      sectionHint:
        "Restore previous versions of your page from the past 3 days.",
      componentId: "PageHistoryBrowser",
      tab: "more",
      orientation: "vertical",
    },
  ];

  const navigate = useNavigate();

  const deletePage = () => {
    setIsDeleting(true);
    apiRequest
      .delete("/page/", {
        data: { id: page.id },
      })
      .then(() => {
        // remove page from pages
        const newPages = pages.filter((p) => p.id !== page.id);
        mixpanel.track("Delete Page");
        navigate("/pages");
        setPages(newPages);
        successNotification("Page Deleted");
        setIsDeleting(false);
      })
      .catch((error) => {
        setIsDeleting(false);
        errorNotification("Could not delete your page");
      });
  };

  function stripNonASCII(inputStr) {
    // Replace all non-ASCII characters with an empty string
    return inputStr.replace(/[^\x00-\x7F]/g, "").replace(" ", "");
  }

  return (
    <>
      <SelectToggle
        data={{
          margin: "15px 15px 0 15px",
          onChange: (v) => setTab(v),
          tabs: [
            {
              label: "Basics",
              value: "basics",
              active: !tab || tab === "basics",
            },
            {
              label: "More",
              value: "more",
              active: tab === "more",
            },
          ],
        }}
      />

      <AdminForm
        fields={pageFields
          .filter((f) => (!tab && f.tab === "basics") || f.tab === tab)
          .filter(
            (f) =>
              !f.displayCondition ||
              (f.displayCondition && f.displayCondition())
          )}
        orientation={"horizontal"}
        borderBottom={true}
        onChange={(k, v) => {
          let finalVal = v;
          if (k === "route") {
            finalVal = v.toLowerCase();
            finalVal = stripNonASCII(finalVal);
          }
          setPage({ [k]: finalVal });
        }}
      />
      {anchorElement && (
        <UserGroupPermissions
          noGroupsText="To determine who can view this page, define User Groups in User Settings"
          anchorElement={anchorElement}
          onClose={() => {
            setAnchorElement(null);
          }}
          values={pageUserGroups}
          onChange={(v) =>
            setPage({
              userGroups: v,
            })
          }
        />
      )}
    </>
  );
};

export default PageConfig;

export const MenuItem = ({ id, icon, setMenu, disabled }) => {
  return (
    <MenuItemContainer
      disabled={disabled}
      onClick={disabled ? null : (e) => setMenu(id, e.currentTarget)}
    >
      <Icon
        data={{
          icon,
          color: "#5D5F62",
          disabled,
          size: 20,
          hover: true,
          disabled,
        }}
      />
      <Text
        data={{
          text: startCase(id),
          color: disabled ? colors.grey3 : "#5D5F62",
          fontStyle: "headingXs",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      />
    </MenuItemContainer>
  );
};

const MenuItemContainer = styled.div`
  background: white;
  width: 100%;
  font-size: 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.divider};
  padding: 12px;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
`;

export const HeaderRow = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${colors.divider};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colors.divider};
`;
