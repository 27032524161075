import { get, isNil } from "lodash";

import { parseBoolean } from "./utils";
import { rTranslations } from "./recoil";
import { useRecoilValue } from "recoil";

export const getValidationObject = (fields) => {
  let obj = {};

  fields
    .filter((f) => f.active !== false)
    .filter((f) => f.componentId !== "Hidden")
    .forEach((field) => {
      const fieldKeyOrId = field.key || field.id;
      const fieldValidation = getFieldValidation(field);
      if (Object.keys(fieldValidation).length > 0) {
        obj[fieldKeyOrId] = fieldValidation;
      }
    });

  return obj;
};

export const getFieldValidation = (field) => {
  let fieldObj = {};

  const validations = [
    "isPrice",
    "isAlphabetical",
    "isNumber",
    "isEmail",
    "isTrue",
    "required",
    "minOffset",
    "maxOffset",
    "customRegex",
    "customRegexError",
  ];

  const isBoolean = ["Switch", "Checkbox"].includes(field.componentId);

  if (!field.disabled) {
    validations.forEach((validation) => {
      if (field[validation]) {
        fieldObj[validation] = field[validation];
      }
    });
    const required = field.required !== false;

    if (required && !isBoolean) {
      fieldObj["required"] = required;
    }
  }

  return fieldObj;
};

const useValidateFields = () => {
  const translations = useRecoilValue(rTranslations);

  const validateFields = (
    validationSchema = {},
    values = {},
    hasSubmitted = true,
    setErrors = null
  ) => {
    const errors = {};

    if (!hasSubmitted) {
      return {};
    }

    for (const [field, rules] of Object.entries(validationSchema)) {
      const value = get(values, field);

      if (!field || !rules) {
        continue;
      }

      function sanitizeRegexInput(input) {
        // Remove the leading and trailing forward slashes, if present
        return input.replace(/^\/|\/$/g, "");
      }

      // Check if the field is alphabetical (only letters and spaces)
      const customRegex = get(rules, "customRegex");
      if (customRegex) {
        const sanitizedInput = sanitizeRegexInput(customRegex);
        const regex = new RegExp(sanitizedInput);

        if (!regex.test(value)) {
          errors[field] = get(rules, "customRegexError", "Custom regex failed");
          continue;
        }
      }

      if (rules.isTrue && !parseBoolean(value)) {
        errors[field] = get(
          translations,
          "validationChecked",
          "This field must be checked."
        );

        continue;
      }

      // Check required field
      if (rules.required && (isNil(value) || value === "")) {
        errors[field] = get(
          translations,
          "validationRequired",
          "This field is required."
        );
        continue;
      }

      // Skip other validations if the field is not required and is empty
      if (!rules.required && (!value || value === "")) {
        continue;
      }

      // Check if the field is a valid number (float or integer)
      if (rules.isNumber && !/^\d*\.?\d+$/.test(value)) {
        errors[field] = get(
          translations,
          "validationInvalidNumber",
          "Invalid number format."
        );
        continue;
      }

      // Check if the field is a valid price
      if (
        rules.isPrice &&
        !/^(?:[₦¥€£$₹]?\d{1,3}(?:,\d{3})*(?:\.\d{1,2})?|\d+(?:\.\d{1,2})?)$/.test(
          value
        )
      ) {
        errors[field] = get(
          translations,
          "fieldInvalidPrice",
          "Invalid price format."
        );

        continue;
      }

      if (rules.isPrice || rules.isNumber) {
        // When the field is supposed to be a number or price

        const numericValue = parseFloat(value);

        if (rules.minOffset && numericValue < rules.minOffset) {
          errors[field] = `${get(
            translations,
            "validationMinOffsetNumber",
            "Minimum value should be"
          )} ${rules.minOffset}.`;
          continue;
        }

        if (rules.maxOffset && numericValue > rules.maxOffset) {
          errors[field] = `${get(
            translations,
            "validationMaxOffsetNumber",
            "Maximum value should be"
          )} ${rules.maxOffset}.`;
          continue;
        }
      } else {
        // When the field is treated as text

        if (rules.minOffset && value.length < rules.minOffset) {
          errors[field] = `${get(
            translations,
            "validationMinOffsetText",
            "Minimum characters required"
          )} ${rules.minOffset}.`;
          continue;
        }

        if (rules.maxOffset && value.length > rules.maxOffset) {
          errors[field] = `${get(
            translations,
            "validationMaxOffsetText",
            "Maximum characters required"
          )} ${rules.maxOffset}.`;
          continue;
        }
      }

      // Check if the field is alphabetical (only letters and spaces)
      if (rules.isAlphabetical && !/^[a-zA-Z\s]+$/.test(value)) {
        errors[field] = get(
          translations,
          "valiationInvalidAlphabetical",
          "Only letters and spaces are allowed."
        );
        continue;
      }

      // Check if the field is a valid email
      if (rules.isEmail && !/^[\w\.-]+@[\w\.-]+\.\w+$/.test(value)) {
        errors[field] = get(
          translations,
          "validationInvalidEmail",
          "Invalid email format."
        );
        continue;
      }
    }

    if (setErrors) {
      setErrors(errors);
    }

    return errors;
  };

  return {
    validateFields,
  };
};

export default useValidateFields;
