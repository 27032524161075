import { get } from "lodash";
import { passesCondition } from "app/utils/utils";

export const passesFilters = (r, filters, search = "") => {
  let pass = true;

  // Visible Filters
  filters.forEach((f) => {
    const v = get(r, f.field);
    const b = f.value;

    if (
      b &&
      !passesCondition({
        value1: v,
        value2: b,
        operator: get(f, "operator", "equals"),
      })
    ) {
      pass = false;
    }
  });

  const searchFilter = search.toLowerCase();

  if (searchFilter && searchFilter !== "") {
    let hasSearchValue = false;
    Object.keys(r).forEach((k) => {
      const v = get(r, k, "").toString().toLowerCase();
      if (v.includes(searchFilter)) {
        hasSearchValue = true;
      }
    });
    if (!hasSearchValue) {
      pass = false;
    }
  }

  return pass;
};
