import { Button, Icon, Text } from "app/components";

import ApexChart from "app/components/charts/ApexChart";
import Card from "./Card";
import Chart from "app/components/charts/Chart";
import { TableComponent } from "app/renderingApp/blocks/Table";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Report = ({ data, spreadsheetId }) => {
  const navigate = useNavigate();

  return (
    <Card
      reportId={data.id}
      label={get(data, "label", "New Report")}
      type={data.component}
      // onClick={() => navigate(`/report/${data.id}`)}
    >
      <ReportContent data={data} spreadsheetId={spreadsheetId} />
    </Card>
  );
};

export default Report;

export const ReportContent = ({ data, reset }) => {
  const component = get(data, "component");

  if (!get(data, "data")) {
    return (
      <div>
        <Text
          data={{
            fontStyle: "bodyLg",
            text: "This query returned no results. Please ensure you're using valid spreadsheet and field names in your request.",
          }}
        />
        <Button
          data={{ text: "Try Again", onClick: reset, margin: "10px 0 0 0" }}
        />
      </div>
    );
  }

  // TABLE
  if (component === "Table") {
    const reportData = get(data, "data", []);
    console.log("reportData table", reportData);
    const firstRow = get(reportData, 0, {});
    const columns = Object.keys(firstRow);

    if (reportData.length === 0) {
      return (
        <Text
          data={{ text: "No data matches this search", fontStyle: "body2xl" }}
        />
      );
    }

    return (
      <div style={{ width: "100%" }}>
        <TableComponent
          data={{
            preventDarkMode: true,
            resultsPerPage: 5,
            rows: reportData,
            columns: columns
              .filter((c) => c !== "frontly_data")
              .map((c) => ({ key: c })),
          }}
        />
      </div>
    );
  }

  // CHART
  else if (component === "Chart") {
    // Ranges
    const reportData = get(data, "data", []);
    console.log("reportData chart", reportData);

    const isMultiRange = get(data, "multi_range");
    const valueField = get(data, "value_field");

    let ranges = [];
    let labels = [];
    let values = [];

    Object.keys(reportData).forEach((key) => {
      labels.push(key);
      if (!isMultiRange) {
        values.push(reportData[key]);
      }
    });

    if (!isMultiRange) {
      // SINGLE RANGE
      ranges = [
        {
          name: "Series 1",
          data: values,
        },
      ];
    } else {
      // MULTI RANGE
      Object.keys(reportData).forEach((key) => {
        const items = get(reportData, key, []);
        ranges.push({
          name: key,
          data: items.map((i) => get(i, valueField)),
        });
      });
    }

    return (
      <div style={{ width: "800px" }}>
        <ApexChart
          data={{
            labels,
            showLabels: false,
            showLegend: true,
            series: ranges,
            type: "ColumnApex",
          }}
        />
      </div>
    );
  }

  // STAT
  else if (component === "Stat") {
    const reportData = get(data, "data");
    const calculation = get(data, "calculation");

    console.log("reportData Stat", reportData);

    let valueString = reportData && reportData.toString();
    let fontSize = 140;
    if (valueString.length > 7) {
      fontSize = 75;
    } else if (valueString.length > 5) {
      fontSize = 85;
    }

    const statColor = "black";

    const isGrowthDisplay = [
      "percent_difference",
      "absolute_difference",
    ].includes(calculation);

    // direction = if reportData is positive, up, if negative, down, if 0, null
    let direction = null;
    if (reportData > 0) {
      direction = "up";
    } else if (reportData < 0) {
      direction = "down";
    }

    const directionMap = {
      up: "#50cd89",
      down: "#f1416c",
    };

    return (
      <StatValue fontSize={fontSize} color={statColor}>
        {reportData.toLocaleString()}{" "}
        {calculation === "percent_difference" && "%"}
        {isGrowthDisplay && direction && (
          <GrowthDisplay direction={direction}>
            <Icon
              data={{
                icon: direction === "up" ? "FiArrowUp" : "FiArrowDown",
                color: get(directionMap, direction, colors.grey3),
              }}
            />
          </GrowthDisplay>
        )}
      </StatValue>
    );
  }
};

const StatValue = styled.div`
  font-size: ${(p) => p.fontSize}px;
  font-weight: 600;
  color: ${(p) => p.color || colors.default};
`;

const GrowthDisplay = styled.div`
  color: #78829d;
  background: #f9f9f9;
  ${(p) => p.direction === "up" && "color: #50cd89; background: #eefbec;"}
  ${(p) => p.direction === "down" && "color: #f1416c; background: #fff2f1;"}
  font-size: 12px;
  font-weight: 700;
  padding: 7px;
  border-radius: 10px;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
`;
