import React from 'react'
import { Accordion, HoverCard, NavigationMenu, Popover, RadioGroup, Slider, Toast, ToggleGroup, Tooltip } from './components/radix'

const Radix = () => {
  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', rowGap: 40 }}>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 12 }}>
        <p>Toast</p>
        <Toast />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 12 }}>
        <p>Radio Group</p>
        <RadioGroup />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 12 }}>
        <p>Popover</p>
        <Popover />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 12 }}>
        <p style={{ textAlign: 'center' }}>Navigation Menu</p>
        <NavigationMenu />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 12 }}>
        <p>Accordion</p>
        <Accordion />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 12 }}>
        <p>Slider</p>
        <Slider />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 12 }}>
        <p>Toggle Group</p>
        <ToggleGroup />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 12 }}>
        <p>Tooltip</p>
        <Tooltip />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 12 }}>
        <p>Hover Card</p>
        <HoverCard />
      </div>
    </div>
  )
}

export default Radix