import { Modal, SearchBar, Text } from "app/components";
import { get, startCase } from "lodash";
import { rApp, rTranslations } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import AdminForm from "../components/AdminForm";
import { CardSection } from "./SettingsSection";
import { TableComponent } from "app/renderingApp/blocks/Table";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { operators } from "app/utils/utils";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const Translations = () => {
  const translations = useRecoilValue(rTranslations);

  const setApp = useSetRecoilState(rApp);

  const [changes, setChanges] = useState(false);
  const [editId, setEditId] = useState(null);

  const [search, setSearch] = useState("");

  const save = () => {
    setChanges(false);
    successNotification("Saved");
    apiRequest.post("/app_settings/", {
      translations,
    });
  };

  const filters = [
    {
      id: "min",
      text: "Min",
    },
    {
      id: "max",
      text: "Max",
    },
    {
      id: "between",
      text: "Between",
    },
    {
      id: "and",
      text: "And",
    },
    {
      id: "selection",
      text: "Selection",
    },
    {
      id: "selections",
      text: "Selections",
    },
    {
      id: "clear",
      text: "Clear",
    },
    {
      id: "selectText",
      text: "Select",
    },
    {
      id: "true",
      text: "True",
    },
    {
      id: "false",
      text: "False",
    },
    {
      id: "searchText",
      text: "Search",
    },
    {
      id: "filtersText",
      text: "Filters",
    },
    { id: "filterAny", text: "Any" },
    { id: "filterIs", text: "In" },
  ].map((f) => ({
    ...f,
    category: "Filters",
  }));

  const uploadWidget = [
    {
      id: "uploadBrowse",
      text: "Browse",
    },
    {
      id: "uploadTitleSingle",
      text: "Drag and Drop an asset here",
    },
    {
      id: "uploadTitleMulti",
      text: "Drag and Drop an asset here",
    },
    {
      id: "uploadDropTitleSingle",
      text: "Drop a file to upload",
    },
    {
      id: "uploadDropTitleMulti",
      text: "Drop files to upload",
    },
    {
      id: "uploadUrlTitle",
      text: "Public URL of file to upload:",
    },
    {
      id: "uploadFiles",
      text: "My Files",
      category: "Upload Widget",
    },
    {
      id: "uploadWebAddress",
      text: "Web Address",
      category: "Upload Widget",
    },
    {
      id: "uploadCamera",
      text: "Camera",
      category: "Upload Widget",
    },
    {
      id: "uploadCameraCapture",
      text: "Capture",
      category: "Upload Widget",
    },
    {
      id: "uploadCameraCancel",
      text: "Cancel",
      category: "Upload Widget",
    },
    {
      id: "uploadCameraTakePicture",
      text: "Take a picture and upload it",
      category: "Upload Widget",
    },
    {
      id: "uploadCameraExplanation",
      text: "Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture.",
      category: "Upload Widget",
    },
    {
      id: "uploadCameraError",
      text: "Failed to access camera",
      category: "Upload Widget",
    },
    {
      id: "uploadCameraRetry",
      text: "Retry Camera",
      category: "Upload Widget",
    },
  ].map((f) => ({
    ...f,
    category: "Upload Widget",
  }));

  // OTHER
  const forms = [
    {
      id: "recordCreated",
      text: "Record Created",
    },
    {
      id: "noFile",
      text: "No File",
    },
    {
      id: "selectFile",
      text: "Select File",
    },

    {
      id: "confirmPassword",
      text: "Confirm Password",
    },
  ].map((f) => ({
    ...f,
    category: "Form",
  }));

  const other = [
    {
      id: "showAll",
      text: "Show All",
      category: "Detail View",
    },
    {
      id: "resetYourPassword",
      text: "Reset Your Password",
      category: "Password Reset",
    },
    {
      id: "updatePassword",
      text: "Update Password",
      category: "Password Reset",
    },
    {
      id: "password",
      text: "Password",
      category: "Password Reset",
    },
    {
      id: "noResultsMatch",
      text: "No results match this search",
    },
    {
      id: "logout",
      text: "Logout",
      hint: "The 'Logout' text in the dropdown menu",
    },
    {
      id: "loggedOut",
      text: "Logged Out",
    },
    {
      id: "editProfile",
      text: "Edit Profile",
      category: "User Profile",
    },
    {
      id: "saveProfile",
      text: "Save Profile",
      category: "User Profile",
    },
    {
      id: "actions",
      text: "Actions",
    },
    {
      id: "unsavedChangesWarningHeader",
      text: "Close With Unsaved Changes?",
    },
    {
      id: "unsavedChangesWarning",
      text: "There are unsaved changes. Are you sure you want to close?",
    },
    {
      id: "confirmationCancel",
      text: "Cancel",
      category: "Confirmation Modal",
    },
    {
      id: "confirmationConfirm",
      text: "Confirm",
      category: "Confirmation Modal",
    },
    {
      id: "detailViewSave",
      text: "Save",
      category: "Detail View",
    },
    {
      id: "detailViewDelete",
      text: "Delete",
      category: "Detail View",
    },
    {
      id: "detailViewDeleteConfirmationTitle",
      text: "Delete Record",
      category: "Detail View",
    },
    {
      id: "detailViewDeleteConfirmationText",
      text: "Are you sure you want to delete this record?",
      category: "Detail View",
    },
    {
      id: "savedNotification",
      text: "Saved",
    },
    {
      id: "deletedNotification",
      text: "Deleted",
    },
  ];

  // LOGIN SIGNUP FIELDS
  const loginSignup = [
    {
      id: "invalidEmail",
      text: "Please enter a valid email",
    },
    {
      id: "invalidPassword",
      text: "Please enter a more secure password",
    },
  ].map((f) => ({
    ...f,
    category: "Login & Signup",
  }));

  const validationFields = [
    {
      id: "validationChecked",
      text: "This field must be checked.",
    },
    {
      id: "validationRequired",
      text: "This field is required.",
    },
    {
      id: "validationInvalidNumber",
      text: "Invalid number format.",
    },
    {
      id: "validationInvalidEmail",
      text: "Invalid email format.",
    },
    {
      id: "valiationInvalidAlphabetical",
      text: "Only letters and spaces are allowed.",
    },
    {
      id: "validationMinOffsetNumber",
      text: "Minimum value should be",
    },
    {
      id: "validationMaxOffsetNumber",
      text: "Maximum value should be",
    },
    {
      id: "validationMinOffsetText",
      text: "Minimum value should be",
    },
    {
      id: "validationMaxOffsetText",
      text: "Maximum value should be",
    },
  ].map((f) => ({
    ...f,
    category: "Validation",
  }));

  // CALENDAR DAYS OF WEEK
  const calendarDays = [
    {
      id: "calendarSundayLetter",
      text: "S",
    },
    { id: "calendarMondayLetter", text: "M" },
    { id: "calendarTuesdayLetter", text: "T" },
    { id: "calendarWednesdayLetter", text: "W" },
    { id: "calendarThursdayLetter", text: "T" },
    { id: "calendarFridayLetter", text: "F" },
    { id: "calendarSaturdayLetter", text: "S" },
  ];

  // CALENDAR MONTHS
  const calendarMonths = [
    ...[
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((m) => ({
      id: `calendarMonth${m}`,
      text: m,
      category: "Calendar Months",
    })),
  ];

  const calendarFields = [
    {
      id: "noEventsText",
      text: "No events on this date",
      category: "Calendar Text",
    },
    ...calendarDays.map((f) => ({
      ...f,
      category: "Calendar Days",
      label: startCase(f.id.replace("calendar", "").replace("Letter", "")),
    })),
    ...calendarMonths.map((f) => ({
      ...f,
      category: "Calendar Months",
      label: startCase(f.id.replace("calendarMonth", "")),
    })),
  ];

  const allTranslations = [
    ...filters,
    ...forms,
    ...loginSignup,
    ...validationFields,
    ...calendarFields,
    ...other,
    ...uploadWidget,
    ...operators.map((o) => ({
      id: o,
      category: "Operators",
      text: startCase(o),
    })),
  ];

  const filteredTranslations = allTranslations.filter((t) => {
    const searchMatch = t.id.toLowerCase().includes(search.toLowerCase());
    const translationMatch = get(translations, t.id, "")
      .toLowerCase()
      .includes(search.toLowerCase());

    return searchMatch || translationMatch;
  });

  const activeField = allTranslations.find((f) => f.id === editId);

  return (
    <div>
      <Text
        data={{
          text: "Translations",
          fontStyle: "headingLg",
          margin: "0 0 10px 0",
        }}
      />
      <Text
        data={{
          text: "Translate text across the app",
          fontStyle: "bodyMd",
          color: colors.grey3,
          margin: "0 0 20px 0",
        }}
      />

      <CardSection>
        <SearchBar
          data={{
            value: search,
            placeholder: "Search translations",
            onChange: (v) => setSearch(v),
          }}
        />
        <TableComponent
          data={{
            preventDarkMode: true,
            rows: filteredTranslations.map((p) => ({
              ...p,
              key: startCase(p.id),
              label: p.label || p.id,
              category: p.category || "Other",
              translation: get(translations, p.id, ""),
              onClick: () => setEditId(p.id),
            })),
            columns: [
              {
                label: "Key",
                key: "key",
              },
              {
                label: "Original Text",
                key: "text",
              },
              {
                key: "translation",
              },
              {
                key: "category",
                columnType: "badge",
              },
            ],
          }}
        />
      </CardSection>

      {editId && (
        <Modal
          header={{
            title: startCase(editId),
          }}
          hide={() => setEditId(null)}
        >
          <Text
            data={{
              text: "Original:",
              fontStyle: "headingSm",
              width: "80px",
            }}
          />
          <Text
            data={{
              text: get(activeField, "text", ""),
              fontStyle: "bodyMd",
              margin: "5px 0 0 0",
            }}
          />
          <Text
            data={{
              text: "Translation:",
              fontStyle: "headingSm",
              width: "80px",
              margin: "20px 0 5px 0",
            }}
          />
          <AdminForm
            borderBottom={true}
            labelStyle="headingSm"
            sectionPadding="0px"
            fields={[
              {
                id: editId,
                value: get(translations, editId, ""),
              },
            ]}
            submitText={"Save Changes"}
            submit={changes && save}
            width="100%"
            orientation={"horizontal"}
            onChange={(k, v) => {
              setChanges(true);
              setApp({
                translations: {
                  ...translations,
                  [editId]: v,
                },
              });
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default Translations;
