import {
  Button,
  Drawer,
  PaginationWrapper,
  Row,
  SearchBar,
  SimpleDropdown,
  Spinner,
  Tabs,
} from "app/components";
import { downloadAsCsv, safeArray, safeLower } from "app/utils/utils";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty, startCase } from "lodash";
import {
  rApp,
  rConfirmationModalData,
  rSubscription,
  rUser,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { AdminForm } from "../components";
import { EmptyStateDashboard } from "app/renderPage/EmptyStates";
import UserGroupPermissions from "../components/UserGroupPermissions";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";

const UsersList = () => {
  const user = useRecoilValue(rUser);
  const app = useRecoilValue(rApp);

  const userSchema = get(app, "user_schema", []) || [];

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);
  const [users, setUsers] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/users/").then((response) => {
      const users = get(response, "data", []);
      setUsers(users);
      setIsFetching(false);
    });
  }, []);

  const adminUserCount = users.filter((u) =>
    ["owner", "admin"].includes(get(u, "role"))
  ).length;

  const [isCreatingUser, setIsCreatingUser] = useState(null);

  // USER GROUPS

  const [activeUser, setActiveUser] = useState(null);

  const [permissionsAnchor, setPermissionsAnchor] = useState(null);

  const [search, setSearch] = useState("");

  const userId = get(activeUser, "id");

  const isValid = () => {
    const err = {};
    return isEmpty(err);
  };

  const subscription = useRecoilValue(rSubscription);
  const adminLimit = get(subscription, "admin_user_limit", 0) || 0;
  const userLimit = get(subscription, "user_limit", 0) || 0;

  const ownerLimitReached = adminLimit !== 0 && adminUserCount > adminLimit;
  const userLimitReached = userLimit !== 0 && users.length > userLimit;

  // UPDATE PASSWORD

  const [passwordForm, setPasswordForm] = useState(null);

  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

  const updatePassword = () => {
    setIsUpdatingPassword(true);
    apiRequest.post("/admin_reset_password/", passwordForm).then((response) => {
      const error = get(response, ["data", "error"]);
      if (error) {
        errorNotification(error);
      } else {
        successNotification("User password updated");
        mixpanel.track("User password updated");
        setPasswordForm(null);
      }
      setIsUpdatingPassword(false);
    });
  };

  const createUser = () => {
    if (!isValid()) return;

    if (get(activeUser, "role") === "user" && userLimitReached) {
      errorNotification(
        "You have reached your plan's User limit. Upgrade to create more users."
      );
      return;
    }

    if (
      ["owner", "admin"].includes(get(activeUser, "role")) &&
      ownerLimitReached
    ) {
      errorNotification(
        "You have reached your plan's Admin limit. Upgrade to create more admin users / owners."
      );
      return;
    }

    setIsCreatingUser(true);

    const subdomain = get(app, "subdomain");

    if (!userId) {
      activeUser["domain"] = `${subdomain}.frontly.ai`;
    }

    apiRequest.post("/users/", activeUser).then((response) => {
      const newUser = get(response, ["data", "new_user"]);

      const error = get(response, ["data", "error"]);

      if (error) {
        errorNotification(error);
      } else {
        if (newUser) {
          successNotification("User Created");
          mixpanel.track("User Created");
          setUsers([...users, newUser]);
        } else {
          setUsers(users.map((u) => (u.id === userId ? activeUser : u)));
          successNotification("User Saved");
        }
        setActiveUser(null);
      }

      setIsCreatingUser(false);
    });
  };

  const loggedInUserIsOwner = get(user, "role") === "owner";

  const disableRole = userId === user.id;

  let roleOptions = [
    { label: "Admin", value: "admin" },
    { label: "User", value: "user" },
  ];

  if (loggedInUserIsOwner) {
    roleOptions = [{ label: "Owner", value: "owner" }, ...roleOptions];
  }

  let fields = [
    {
      id: "first_name",
    },
    {
      id: "last_name",
    },
    {
      id: "email",
      disabled: userId,
    },
    {
      id: "role",
      componentId: disableRole ? "Input" : "Select",
      options: roleOptions,
      hint: "Owners have full, unfiltered access to all settings, billing and apps. Admins can have app editing, deleting and creation permissions. Users are end users of your app, and can not login to the admin.",
      required: true,
      defaultValue: false,
      hideEmptyItem: true,
      disabled: disableRole,
    },
    {
      id: "user_groups",
      label: "User Groups",
      componentId: "FormButton",
      hint: "Set granular access permissions for users in certain groups",
      link: "https://help.frontly.ai/en/articles/7974437-user-groups",
      icon: "FiKey",
      orientation: "horizontal",
      width: "50px",
      onClick: (e) => setPermissionsAnchor(e.currentTarget),
    },
    ...userSchema.map((s) => ({
      id: s.name,
      label: s.name,
      options: get(s, "options", []),
      componentId: get(s, "type"),
      orientation: get(s, "type") === "Switch" ? "horizontal" : null,
    })),
  ];

  if (!userId) {
    fields.push({
      id: "password",
      type: "password",
    });
  }

  const showAppEditingSettings =
    loggedInUserIsOwner && get(activeUser, "role") === "admin";

  if (showAppEditingSettings) {
    fields.push({
      id: "can_create_apps",
      componentId: "Switch",
      orientation: "horizontal",
      hint: "If enabled, this user can create new apps.",
      disabled: userId === user.id,
    });

    fields.push({
      id: "enabled_apps",
      hint: "The specific apps this user can see and edit.",
      componentId: "AdminAppsEditor",
    });

    // Enabled features
    // if (userId !== user.id) {
    //   fields.push({
    //     id: "enabled_features",
    //     hint: "Determine which parts of the Frontly admin portal this user can access.",
    //     componentId: "EnabledFeaturesEditor",
    //   });
    // }
  }

  if (userId) {
    fields.push({
      id: "id",
      hint: "A non-editable, unique user ID which can be used in filters if connected to your spreadsheet data.",
      disabled: true,
    });
  }

  const updatePassFields = [
    {
      id: "password",
      type: "password",
    },
  ];

  const activeUserIsNotOwnerAndViewingOwner =
    get(activeUser, "role") === "owner" && get(user, "role") !== "owner";

  if (activeUserIsNotOwnerAndViewingOwner) {
    fields = fields.map((f) => ({ ...f, disabled: true }));
  }

  const deleteUser = (uid) => {
    successNotification("User Deleted");

    setUsers(users.filter((u) => u.id !== uid));

    apiRequest.delete("/users/", {
      data: { id: uid },
    });
  };

  const getUserGroupsString = (groups) => {
    const appGroups = get(groups, app.id, []);

    return appGroups
      .map((userGroupId) => {
        const activeAppGroups = safeArray(app, "user_groups");
        const matchingGroup = activeAppGroups.find((g) => g.id === userGroupId);

        if (matchingGroup) {
          return get(matchingGroup, "name");
        }

        return userGroupId;
      })
      .join(", ");
  };

  const formattedUsers = users.map((u) => ({
    ...u,
    user_groups: getUserGroupsString(get(u, "user_groups")),
  }));

  const [role, setRole] = useState("all");

  const matchesRole = (u) => {
    if (role === "all") return true;
    return u.role === role;
  };

  const filteredUsers = users
    .filter(
      (u) =>
        search === "" ||
        safeLower(get(u, "first_name")).includes(search.toLowerCase()) ||
        safeLower(get(u, "last_name")).includes(search.toLowerCase()) ||
        safeLower(get(u, "email")).includes(search.toLowerCase())
    )
    .filter(matchesRole);

  const activeUserGroups = get(activeUser, ["user_groups", app.id], []);

  const downloadUserCSV = () => {
    const userSchema = get(app, "user_schema", []) || [];
    let finalHeaders = [
      "id",
      "email",
      "first_name",
      "last_name",
      "user_groups",
      "role",
      ...userSchema.map((s) => s.name),
    ];

    downloadAsCsv(formattedUsers, finalHeaders, "frontly_users.csv");
  };

  const [isFetchingMagicLink, setIsFetchingMagicLink] = useState(false);

  const getMagicLink = () => {
    setIsFetchingMagicLink(true);

    apiRequest
      .post("/client_user_magic_link/", {
        id: userId,
      })
      .then((response) => {
        const link = get(response, ["data", "link"]);
        setIsFetchingMagicLink(false);
        navigator.clipboard.writeText(link);
        successNotification("Link copied to clipboard");
      })
      .catch(() => {
        errorNotification("Error generating link");
      });
  };

  return (
    <div>
      <Row
        justifyContent="space-between"
        alignItems="center"
        margin="0 0 30px 0"
      >
        <Label>Users</Label>
        <Row gap="15px">
          <Button
            data={{
              text: "Download As CSV",
              icon: "FiDownloadCloud",
              type: "basic",
              onClick: downloadUserCSV,
            }}
          />
          <Button
            data={{
              text: "Add User",
              icon: "FiPlus",
              onClick: () => setActiveUser({ role: "user" }),
            }}
          />
        </Row>
      </Row>
      {permissionsAnchor && (
        <UserGroupPermissions
          isUserPage
          noGroupsText="To determine which pages this user can access, define User Groups in Settings"
          anchorElement={permissionsAnchor}
          onClose={() => {
            setPermissionsAnchor(null);
          }}
          values={activeUserGroups}
          onChange={(v) => {
            setActiveUser((u) => ({
              ...u,
              user_groups: { ...get(u, "user_groups", {}), [app.id]: v },
            }));
          }}
        />
      )}

      {activeUser && (
        <Drawer
          data={{
            open: true,
            width: "300px",
            hide: () => setActiveUser(null),
            label: userId ? "Edit User" : "New User",
            background: colors.grey1,
          }}
        >
          <AdminForm
            labelStyle={"headingXs"}
            onChange={(k, v) => setActiveUser((s) => ({ ...s, [k]: v }))}
            submit={!activeUserIsNotOwnerAndViewingOwner && createUser}
            submitText={userId ? "Save Changes" : "Create User"}
            isFetching={isCreatingUser}
            sectionPadding="0px"
            fields={fields.map((f) => ({
              label: startCase(f.id),
              componentId: "Input",
              value: get(activeUser, f.id),
              ...f,
            }))}
          />
          {userId && !activeUserIsNotOwnerAndViewingOwner && (
            <Button
              data={{
                margin: "15px 0 0 0",
                text: "Get Login Link",
                type: "basic",
                icon: "FiLink",
                isFetching: isFetchingMagicLink,
                onClick: () => getMagicLink(userId),
              }}
            />
          )}
          {/* {userId && (
            <Button
              data={{
                margin: "15px 0 0 0",
                text: "Update Password",
                type: "basic",
                onClick: () => {
                  setPasswordForm({
                    user_id: userId,
                    password: ''
                  })
                  setActiveUser(null);
                },
              }}
            />
          )} */}
        </Drawer>
      )}

      {passwordForm && (
        <Drawer
          data={{
            open: true,
            width: "300px",
            hide: () => setPasswordForm(null),
            label: "Update Password",
            background: colors.grey1,
          }}
        >
          <AdminForm
            labelStyle={"headingXs"}
            onChange={(k, v) => setPasswordForm((s) => ({ ...s, [k]: v }))}
            submit={!activeUserIsNotOwnerAndViewingOwner && updatePassword}
            submitText="Update Password"
            isFetching={isUpdatingPassword}
            sectionPadding="0px"
            fields={updatePassFields.map((f) => ({
              label: startCase(f.id),
              componentId: "Input",
              value: get(activeUser, f.id),
              ...f,
            }))}
          />
        </Drawer>
      )}

      <Tabs
        data={{
          margin: "0 0 20px 0",
          tabs: [
            {
              label: "All",
              active: role === "all",
              onClick: () => setRole("all"),
            },
            {
              label: "Owner",
              active: role === "owner",
              onClick: () => setRole("owner"),
            },
            {
              label: "Admin",
              active: role === "admin",
              onClick: () => setRole("admin"),
            },
            {
              label: "User",
              active: role === "user",
              onClick: () => setRole("user"),
            },
          ],
        }}
      />

      <Card>
        <SearchBar
          data={{
            margin: "15px",
            value: search,
            placeholder: "Search users",
            onChange: (v) => setSearch(v),
          }}
        />

        {isFetching ? (
          <Spinner padding="10px 15px 15px 15px" />
        ) : (
          <PaginationWrapper
            itemsPerPage={10}
            items={filteredUsers}
            isFetching={false}
            noResultsName="users"
            padding="16px"
            noResultsPadding="0 0 16px 16px"
          >
            {(paginatedUsers) => {
              return paginatedUsers.map((u) => {
                let badgeColorMap = {
                  owner: "#cccccc",
                  admin: "#b8e7d2",
                  user: "#AFE6F1",
                };

                // Users can't delete themselves, and only owners can delete other owners
                const showDeleteOption =
                  u.id !== user.id &&
                  (get(u, "role") !== "owner" || loggedInUserIsOwner);

                return (
                  <CardBase onClick={() => setActiveUser(u)}>
                    <CardRow>
                      <CardLabel style={{ width: "200px" }}>
                        {u.first_name || u.last_name
                          ? `${u.first_name || ""} ${u.last_name || ""}`
                          : "No Name"}
                      </CardLabel>
                      <CardText>{u.email}</CardText>
                      <Badge color={get(badgeColorMap, get(u, "role"))}>
                        {startCase(get(u, "role"))}
                      </Badge>
                      {/* <CardText>{u.user_groups}</CardText> */}
                    </CardRow>
                    {showDeleteOption && (
                      <SimpleDropdown
                        data={{
                          options: showDeleteOption
                            ? [
                                {
                                  label: "Delete",
                                  icon: "FiTrash",
                                  onClick: () =>
                                    setConfirmationModalData({
                                      title: "Delete User",
                                      text: "Are you sure you want to delete this user? This is irreversible.",
                                      confirm: () => deleteUser(u.id),
                                    }),
                                },
                              ]
                            : [],
                          icon: {
                            icon: "FiMoreHorizontal",
                            size: 25,
                            hover: true,
                            color: colors.grey3,
                          },
                        }}
                      />
                    )}
                  </CardBase>
                );
              });
            }}
          </PaginationWrapper>
        )}
      </Card>

      {!isFetching && users.length === 1 && (
        <EmptyStateDashboard
          margin="30px 0 0 0"
          title="You haven't added any users"
          subtitle="Right now, you're the only one who can login to your app. Add more users to collaborate, or to login to the published app."
          graphic={
            "https://res.cloudinary.com/frontly/image/upload/v1716245681/Chat_zcwzbi.svg"
          }
          buttonText="Add User"
          onClick={() => setActiveUser({ role: "user" })}
        />
      )}
    </div>
  );
};

export default UsersList;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid ${colors.divider};
  border-radius: 12px;
  overflow: hidden;
`;

export const Label = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const Badge = styled.div`
  background: ${(p) => p.color};
  padding: 5px 7px 5px 7px;
  border-radius: 16px;
  font-size: 11px;
  font-weight: 400;
  color: #202223;
  width: fit-content;
`;

export const CardLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  width: 250px;
`;

export const CardText = styled.div`
  font-size: 15px;
  font-weight: 300;
  width: 250px;
`;

export const CardBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 15px;
  cursor: pointer;
  gap: 20px;
  border-top: 1px solid ${colors.divider};
  &:hover {
    background: ${colors.grey1};
  }
`;
