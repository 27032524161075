import { Icon as BaseIcon, Spinner } from "app/components";
import React, { memo } from "react";
import { isFrontlyAdmin, isObjectEqualFromKeys } from "app/utils/utils";

import { get } from "lodash";
import { rFetchingBlockIds } from "app/utils/recoil";
import { useRecoilValue } from "recoil";

const Icon = ({ block, processDynamicText, actionResolver }) => {
  const repeatingRecord = get(block, "repeatingRecord");
  const repeatingRecordId = get(repeatingRecord, "frontly_id");

  const buttonText = processDynamicText({
    text: get(block, "text"),
    reusableBlockId: block.reusableBlockId,
    context: { repeatingRecord },
  });

  let blockId = get(block, "id");
  if (repeatingRecordId) {
    blockId = `${blockId}-${repeatingRecordId}`;
  }

  const onClick = (e) => {
    if (!isFrontlyAdmin) {
      // For some reason having this broke the admin for button clicking
      e.stopPropagation();
    }

    actionResolver({
      rawAction: get(block, ["actionMap", "clickAction"]),
      actionId: get(block, "clickAction"),
      reusableBlockId: block.reusableBlockId,
      blockId: blockId,
      context: { repeatingRecord },
    });
  };

  const fetchingBlockIds = useRecoilValue(rFetchingBlockIds);

  const isFetching = fetchingBlockIds.includes(blockId);

  const icon = get(block, "icon");
  const color = get(block, "color");

  const size = parseInt(get(block, "size", 20));

  return (
    <>
      {isFetching && <Spinner color={color} size={size} />}
      {!isFetching && buttonText}
      {icon && !isFetching && (
        <BaseIcon
          data={{
            onClick,
            color,
            icon,
            hover: true,
            size,
          }}
        />
      )}
    </>
  );
};

// // Custom comparison function
// const areEqual = (prevProps, nextProps) => {
//   const arePropsEqual = isObjectEqualFromKeys(prevProps, nextProps, ["block"]);

//   return arePropsEqual;
// };

// // Memoize the Icon component with custom comparison function
// const MemoizedIcon = memo(Icon, areEqual);

// export default MemoizedIcon;

export default Icon;
