// OLD VERSION - NO INPUT ISSUE, BUT STILL HAS STALE STATE ISSUE
import {
  rActiveBlockId,
  rActiveVersionMap,
  rCustomBlockChanges,
  rCustomBlocks,
  rPageChanges,
  rPages,
} from "./recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { get } from "lodash";
import { safeArray } from "./utils";
import useIsCustomBlock from "./useIsCustomBlock";
import usePage from "./usePage";
import { useParams } from "react-router-dom";

const useSetPage = () => {
  const activeVersionMap = useRecoilValue(rActiveVersionMap);
  const setCustomBlockChanges = useSetRecoilState(rCustomBlockChanges);
  const setPageChanges = useSetRecoilState(rPageChanges);
  const isCustomBlock = useIsCustomBlock();

  const page = usePage();

  const [pages, setPages] = useRecoilState(rPages);

  const blocks = get(page, "blocks", []);

  const params = useParams();
  const pageId = parseInt(params.id);

  const [customBlocks, setCustomBlocks] = useRecoilState(rCustomBlocks);

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const setPage = (obj) => {
    if (isCustomBlock) {
      // EDIT CUSTOM BLOCK
      setCustomBlocks(
        safeArray(customBlocks).map((bl) => {
          if (bl.id == pageId) {
            return {
              ...bl,
              ...obj,
            };
          }
          return bl;
        })
      );

      setCustomBlockChanges(true);
    } else {
      // EDIT PAGE
      const newPages = pages.map((p) => {
        if (p.id === pageId) {
          return {
            ...p,
            ...obj,
          };
        }
        return p;
      });
      setPages(newPages);
      setPageChanges(true);
    }
  };

  const setBlock = (
    obj,
    otherPageData = {},
    customBlockId = null,
    forceRoot = false
  ) => {
    const newBlocks = blocks.map((b) => {
      const bId = activeBlockId || customBlockId;

      if (b.id === bId) {
        const activeVersionMatch = get(activeVersionMap, bId);

        if (!forceRoot && activeVersionMatch) {
          // SET ACTIVE VERSION
          const newVersions = get(b, "versions", []).map((v) => {
            if (get(v, "id") === activeVersionMatch) {
              return {
                ...v,
                data: {
                  ...get(v, "data", {}),
                  ...obj,
                },
              };
            }
            return v;
          });

          return {
            ...b,
            versions: newVersions,
          };
        }

        // SET ROOT BLOCK DATA
        return {
          ...b,
          ...obj,
        };
      }
      return b;
    });
    setPage({ blocks: newBlocks, ...otherPageData });

    if (isCustomBlock) {
      setCustomBlockChanges(true);
    } else {
      setPageChanges(true);
    }
  };

  return { setBlock, setPage };
};

export default useSetPage;
