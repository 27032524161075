import { CustomBlock, Tile } from "../components";

import { Column } from "app/components";
import SettingsSection from "./SettingsSection";
import { rCustomBlocks } from "app/utils/recoil";
import { safeArray } from "app/utils/utils";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const CustomBlocks = () => {
  const navigate = useNavigate();

  const customBlocks = useRecoilValue(rCustomBlocks);

  return (
    <SettingsSection
      title="Custom Blocks"
      description="Manage the custom blocks you've saved in your app."
    >
      <Column gap="10px" margin="0 0 15px 0">
        {safeArray(customBlocks).map((b) => (
          <Tile
            label={b.name}
            onClick={() => navigate(`/custom-block/${b.id}`)}
            icon={"FiBox"}
          />
        ))}
      </Column>
      <CustomBlock isNew />
    </SettingsSection>
  );
};

export default CustomBlocks;
